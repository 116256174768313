package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentCategory
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class ContentCategoryDao : BaseDao<ContentCategory> {
  public actual abstract fun publicContentCategories(): List<ContentCategory>

  public actual abstract fun findCategoryBySchemaIdAndName(schemaId: Long, name: String):
      ContentCategory?
}
