package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import kotlin.Unit

public actual fun ClazzLogAttendanceRecord.shallowCopy(block: ClazzLogAttendanceRecord.() -> Unit):
    ClazzLogAttendanceRecord = ClazzLogAttendanceRecord().also {
  it.clazzLogAttendanceRecordUid = this.clazzLogAttendanceRecordUid
  it.clazzLogAttendanceRecordClazzLogUid = this.clazzLogAttendanceRecordClazzLogUid
  it.clazzLogAttendanceRecordPersonUid = this.clazzLogAttendanceRecordPersonUid
  it.attendanceStatus = this.attendanceStatus
  it.clazzLogAttendanceRecordMasterChangeSeqNum = this.clazzLogAttendanceRecordMasterChangeSeqNum
  it.clazzLogAttendanceRecordLocalChangeSeqNum = this.clazzLogAttendanceRecordLocalChangeSeqNum
  it.clazzLogAttendanceRecordLastChangedBy = this.clazzLogAttendanceRecordLastChangedBy
  it.clazzLogAttendanceRecordLastChangedTime = this.clazzLogAttendanceRecordLastChangedTime
  block(it)
}
