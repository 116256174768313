package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.OfflineItemAndState
import com.ustadmobile.lib.db.entities.OfflineItem
import kotlin.Boolean
import kotlin.Long
import kotlinx.coroutines.flow.Flow

public class OfflineItemDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: OfflineItemDao,
) : OfflineItemDao() {
  override suspend fun insertAsync(item: OfflineItem): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(item.oiUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(971)
      item.oiUid = _newPk
    }
    item.oiLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(item)
    return item.oiUid
  }

  override fun findByContentEntryUid(contentEntryUid: Long, nodeId: Long):
      Flow<OfflineItemAndState?> = _dao.findByContentEntryUid(contentEntryUid, nodeId)

  override suspend fun updateActiveByOfflineItemUid(oiUid: Long, active: Boolean) {
    _dao.updateActiveByOfflineItemUid(oiUid, active)
  }
}
