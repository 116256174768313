package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Language
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class LanguageDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: LanguageDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : LanguageDao() {
  override suspend fun insertListAsync(languageList: List<Language>) {
    _repo.withRepoChangeMonitorAsync("Language") {
      _dao.insertListAsync(languageList)
    }
  }

  override fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      PagingSource<Int, Language> {
    val _result = _dao.findLanguagesAsSource(sortOrder, searchText)
    return _result
  }

  override fun findLanguagesList(): List<Language> {
    val _result = _dao.findLanguagesList()
    return _result
  }

  override fun findByName(name: String): Language? {
    val _result = _dao.findByName(name)
    return _result
  }

  override fun findByTwoCode(langCode: String): Language? {
    val _result = _dao.findByTwoCode(langCode)
    return _result
  }

  override suspend fun findByTwoCodeAsync(langCode: String): Language? {
    val _result = _dao.findByTwoCodeAsync(langCode)
    return _result
  }

  override fun findByThreeCode(langCode: String): Language? {
    val _result = _dao.findByThreeCode(langCode)
    return _result
  }

  override fun totalLanguageCount(): Int {
    val _result = _dao.totalLanguageCount()
    return _result
  }

  override fun update(entity: Language) {
    _repo.withRepoChangeMonitor("Language") {
      _dao.update(entity)
    }
  }

  override fun findByUid(primaryLanguageUid: Long): Language? {
    val _result = _dao.findByUid(primaryLanguageUid)
    return _result
  }

  override suspend fun findByUidAsync(primaryLanguageUid: Long): Language? {
    val _result = _dao.findByUidAsync(primaryLanguageUid)
    return _result
  }

  override suspend fun updateAsync(entity: Language): Int {
    val _result = _repo.withRepoChangeMonitorAsync("Language") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override fun findAllLanguageLive(): Flow<List<Language>> {
    val _result = _dao.findAllLanguageLive()
    return _result
  }

  override fun findByUidList(uidList: List<Long>): List<Long> {
    val _result = _dao.findByUidList(uidList)
    return _result
  }

  override suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("Language") {
      _dao.toggleVisibilityLanguage(toggleVisibility, selectedItem, updateTime)
    }
  }

  override fun replaceList(entityList: List<Language>) {
    _repo.withRepoChangeMonitor("Language") {
      _dao.replaceList(entityList)
    }
  }

  public override fun insert(entity: Language): Long {
    val _result = _repo.withRepoChangeMonitor("Language") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: Language): Long {
    val _result = _repo.withRepoChangeMonitorAsync("Language") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<Language>) {
    _repo.withRepoChangeMonitor("Language") {
      _dao.insertList(entityList)
    }
  }
}
