package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class LeavingReasonDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: LeavingReasonDao,
) : LeavingReasonDao() {
  override fun findAllReasons(): PagingSource<Int, LeavingReason> = _dao.findAllReasons()

  override fun findAllReasonsLive(): List<LeavingReason> = throw
      IllegalStateException("findAllReasonsLive: synchronous db access is NOT possible on Javascript!")

  override suspend fun findAllReasonsAsync(): List<LeavingReason> = _dao.findAllReasonsAsync()

  override suspend fun findByUidAsync(uid: Long): LeavingReason? = _dao.findByUidAsync(uid)

  override suspend fun findByUidList(uidList: List<Long>): List<Long> = _dao.findByUidList(uidList)

  override fun findByUidLive(uid: Long): Flow<LeavingReason?> = _dao.findByUidLive(uid)

  override suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel> =
      _dao.getReasonsFromUids(uidList)

  override suspend fun replaceList(entityList: List<LeavingReason>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.leavingReasonUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(410)
        it.leavingReasonUid = _newPk
        _generatedPks += _newPk
      }
      it.leavingReasonLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceList(entityList)
  }

  override suspend fun updateAsync(entity: LeavingReason): Int {
    entity.leavingReasonLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun insert(entity: LeavingReason): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: LeavingReason): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.leavingReasonUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(410)
      entity.leavingReasonUid = _newPk
    }
    entity.leavingReasonLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.leavingReasonUid
  }

  public override fun insertList(entityList: List<LeavingReason>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: LeavingReason): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
