package com.ustadmobile.lib.db.entities

class ContentEntryButtonModel {

    var showDownloadButton = false

    var showOpenButton = false

    var showUpdateButton = false

    var showDeleteButton = false

    var showManageDownloadButton = false

}