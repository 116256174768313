package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.setLongNullable
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.StateDeleteCommand
import kotlin.Boolean
import kotlin.String

public class StateDeleteCommandDao_JdbcImpl(
  public val _db: RoomDatabase,
) : StateDeleteCommandDao() {
  public val _insertAdapterStateDeleteCommand_upsert: EntityInsertionAdapter<StateDeleteCommand> =
      object : EntityInsertionAdapter<StateDeleteCommand>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO StateDeleteCommand (sdcActorUid, sdcHash, sdcActivityUid, sdcStateId, sdcLastMod, sdcRegistrationHi, sdcRegistrationLo) VALUES(?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: StateDeleteCommand) {
      stmt.setLong(1, entity.sdcActorUid)
      stmt.setLong(2, entity.sdcHash)
      stmt.setLong(3, entity.sdcActivityUid)
      stmt.setString(4, entity.sdcStateId)
      stmt.setLong(5, entity.sdcLastMod)
      stmt.setLongNullable(6, entity.sdcRegistrationHi)
      stmt.setLongNullable(7, entity.sdcRegistrationLo)
    }
  }

  override suspend fun insertAsync(deleteCommand: StateDeleteCommand) {
    _insertAdapterStateDeleteCommand_upsert.insertAsync(deleteCommand)
  }
}
