package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.StudentResultAndCourseBlockSourcedId
import com.ustadmobile.lib.db.entities.StudentResult
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StudentResultDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StudentResultDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StudentResultDao() {
  override suspend fun insertListAsync(list: List<StudentResult>) {
    _repo.withRepoChangeMonitorAsync("StudentResult") {
      _dao.insertListAsync(list)
    }
  }

  override suspend fun upsertAsync(studentResult: StudentResult) {
    _repo.withRepoChangeMonitorAsync("StudentResult") {
      _dao.upsertAsync(studentResult)
    }
  }

  override suspend fun findByClazzAndStudent(
    clazzUid: Long,
    studentPersonUid: Long,
    accountPersonUid: Long,
  ): List<StudentResultAndCourseBlockSourcedId> {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "StudentResultDao/findByClazzAndStudent") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "StudentResultDao/findByClazzAndStudent")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("studentPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            studentPersonUid))
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByClazzAndStudent(clazzUid, studentPersonUid, accountPersonUid)
    return _result
  }

  override suspend fun findUidBySourcedId(sourcedId: String): Long {
    val _result = _dao.findUidBySourcedId(sourcedId)
    return _result
  }

  override suspend fun existsByUid(srUid: Long): Boolean {
    val _result = _dao.existsByUid(srUid)
    return _result
  }
}
