package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.ActivityExtensionEntity
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ActivityExtensionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ActivityExtensionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ActivityExtensionDao() {
  override suspend fun upsertListAsync(list: List<ActivityExtensionEntity>) {
    _repo.withRepoChangeMonitorAsync("ActivityExtensionEntity") {
      _dao.upsertListAsync(list)
    }
  }

  override suspend fun findAllByActivityUid(activityUid: Long): List<ActivityExtensionEntity> {
    val _result = _dao.findAllByActivityUid(activityUid)
    return _result
  }
}
