package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.AssignmentPermissionAndActiveUserSubmitterUid
import com.ustadmobile.lib.db.composites.CoursePermissionAndEnrolment
import com.ustadmobile.lib.db.composites.CoursePermissionAndListDetail
import com.ustadmobile.lib.db.composites.PermissionPair
import com.ustadmobile.lib.db.composites.PermissionTriple
import com.ustadmobile.lib.db.entities.CoursePermission
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CoursePermissionDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CoursePermissionDao,
) : CoursePermissionDao() {
  override fun findByClazzUidAsPagingSource(clazzUid: Long, includeDeleted: Boolean):
      PagingSource<Int, CoursePermissionAndListDetail> = _dao.findByClazzUidAsPagingSource(clazzUid,
      includeDeleted)

  override suspend fun findByUidAndClazzUid(uid: Long, clazzUid: Long): CoursePermission? =
      _dao.findByUidAndClazzUid(uid, clazzUid)

  override fun findByUidAndClazzUidAsFlow(uid: Long, clazzUid: Long): Flow<CoursePermission?> =
      _dao.findByUidAndClazzUidAsFlow(uid, clazzUid)

  override suspend fun upsertAsync(coursePermission: CoursePermission) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(coursePermission.cpUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(10012)
      coursePermission.cpUid = _newPk
    }
    coursePermission.cpLastModified = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsertAsync(coursePermission)
  }

  override suspend fun setDeleted(
    cpUid: Long,
    isDeleted: Boolean,
    updateTime: Long,
  ) {
    _dao.setDeleted(cpUid, isDeleted, updateTime)
  }

  override suspend fun personHasPermissionWithClazzByEnrolmentUidEntities2(accountPersonUid: Long,
      clazzEnrolmentUid: Long): List<CoursePermissionAndEnrolment> =
      _dao.personHasPermissionWithClazzByEnrolmentUidEntities2(accountPersonUid, clazzEnrolmentUid)

  override suspend fun userHasEnrolmentEditPermission(accountPersonUid: Long,
      clazzEnrolmentUid: Long): Boolean = _dao.userHasEnrolmentEditPermission(accountPersonUid,
      clazzEnrolmentUid)

  override suspend fun personHasPermissionWithClazzEntities2(accountPersonUid: Long,
      clazzUid: Long): List<CoursePermissionAndEnrolment> =
      _dao.personHasPermissionWithClazzEntities2(accountPersonUid, clazzUid)

  override fun personHasPermissionWithClazzAsFlow2(
    accountPersonUid: Long,
    clazzUid: Long,
    permission: Long,
  ): Flow<Boolean> = _dao.personHasPermissionWithClazzAsFlow2(accountPersonUid, clazzUid,
      permission)

  override fun personHasPermissionWithClazzPairAsFlow(
    accountPersonUid: Long,
    clazzUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): Flow<PermissionPair> = _dao.personHasPermissionWithClazzPairAsFlow(accountPersonUid, clazzUid,
      firstPermission, secondPermission)

  override suspend fun personHasPermissionWithClazzPairAsync(
    accountPersonUid: Long,
    clazzUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): PermissionPair = _dao.personHasPermissionWithClazzPairAsync(accountPersonUid, clazzUid,
      firstPermission, secondPermission)

  override fun personHasPermissionWithClazzTripleAsFlow(
    accountPersonUid: Long,
    clazzUid: Long,
    firstPermission: Long,
    secondPermission: Long,
    thirdPermission: Long,
  ): Flow<PermissionTriple> = _dao.personHasPermissionWithClazzTripleAsFlow(accountPersonUid,
      clazzUid, firstPermission, secondPermission, thirdPermission)

  override suspend fun personHasPermissionWithClazzAsync2(
    accountPersonUid: Long,
    clazzUid: Long,
    permission: Long,
  ): Boolean = _dao.personHasPermissionWithClazzAsync2(accountPersonUid, clazzUid, permission)

  override suspend
      fun findApplicableCoursePermissionEntitiesForAccountPerson(accountPersonUid: Long):
      List<CoursePermission> =
      _dao.findApplicableCoursePermissionEntitiesForAccountPerson(accountPersonUid)

  override fun userPermissionsForAssignmentSubmitterUid(
    accountPersonUid: Long,
    assignmentUid: Long,
    clazzUid: Long,
    submitterUid: Long,
  ): Flow<AssignmentPermissionAndActiveUserSubmitterUid> =
      _dao.userPermissionsForAssignmentSubmitterUid(accountPersonUid, assignmentUid, clazzUid,
      submitterUid)
}
