package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.StatementContextActivityJoin
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StatementContextActivityJoinDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StatementContextActivityJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StatementContextActivityJoinDao() {
  override suspend fun insertOrIgnoreListAsync(entities: List<StatementContextActivityJoin>) {
    _repo.withRepoChangeMonitorAsync("StatementContextActivityJoin") {
      _dao.insertOrIgnoreListAsync(entities)
    }
  }

  override suspend fun findAllByStatementId(
    statementIdHi: Long,
    statementIdLo: Long,
    scajContextType: Int,
  ): List<StatementContextActivityJoin> {
    val _result = _dao.findAllByStatementId(statementIdHi, statementIdLo, scajContextType)
    return _result
  }
}
