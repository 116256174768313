package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Report
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ReportDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ReportDao,
) : ReportDao() {
  override fun getResults(query: DoorQuery): List<Report> = throw
      IllegalStateException("getResults: synchronous db access is NOT possible on Javascript!")

  override fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): PagingSource<Int, Report> = _dao.findAllActiveReport(searchBit, personUid, sortOrder,
      isTemplate)

  override suspend fun findByUid(entityUid: Long): Report? = _dao.findByUid(entityUid)

  override suspend fun updateAsync(entity: Report) {
    entity.reportLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(entity)
  }

  override fun findByUidLive(uid: Long): Flow<Report?> = _dao.findByUidLive(uid)

  override fun findAllActiveReportLive(isTemplate: Boolean): Flow<List<Report>> =
      _dao.findAllActiveReportLive(isTemplate)

  override fun findAllActiveReportList(isTemplate: Boolean): List<Report> = throw
      IllegalStateException("findAllActiveReportList: synchronous db access is NOT possible on Javascript!")

  override fun findByUidList(uidList: List<Long>): List<Long> = throw
      IllegalStateException("findByUidList: synchronous db access is NOT possible on Javascript!")

  override suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ) {
    _dao.toggleVisibilityReportItems(toggleVisibility, selectedItem, updateTime)
  }

  override fun replaceList(entityList: List<Report>): Unit = throw
      IllegalStateException("replaceList: synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: Report): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Report): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.reportUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(101)
      entity.reportUid = _newPk
    }
    entity.reportLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.reportUid
  }

  public override fun insertList(entityList: List<Report>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Report): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
