package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.xapi.StateIdAndLastModified
import com.ustadmobile.lib.db.entities.xapi.StateEntity
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StateEntityDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StateEntityDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StateEntityDao() {
  override suspend fun upsertAsync(stateEntities: List<StateEntity>) {
    _repo.withRepoChangeMonitorAsync("StateEntity") {
      _dao.upsertAsync(stateEntities)
    }
  }

  override suspend fun findByActorAndHash(
    accountPersonUid: Long,
    actorUid: Long,
    seHash: Long,
    includeDeleted: Boolean,
  ): StateEntity? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "StateEntityDao/findByActorAndHash") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "StateEntityDao/findByActorAndHash")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("actorUid", _repo.config.json.encodeToString(Long.serializer(), actorUid))
        parameter("seHash", _repo.config.json.encodeToString(Long.serializer(), seHash))
        parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
            includeDeleted))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByActorAndHash(accountPersonUid, actorUid, seHash, includeDeleted)
    return _result
  }

  override suspend fun findByAgentAndActivity(
    accountPersonUid: Long,
    actorUid: Long,
    seActivityUid: Long,
    registrationUuidHi: Long?,
    registrationUuidLo: Long?,
    modifiedSince: Long,
  ): List<StateEntity> {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "StateEntityDao/findByAgentAndActivity") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "StateEntityDao/findByAgentAndActivity")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("actorUid", _repo.config.json.encodeToString(Long.serializer(), actorUid))
        parameter("seActivityUid", _repo.config.json.encodeToString(Long.serializer(),
            seActivityUid))
        parameter("registrationUuidHi", _repo.config.json.encodeToString(Long.serializer().nullable,
            registrationUuidHi))
        parameter("registrationUuidLo", _repo.config.json.encodeToString(Long.serializer().nullable,
            registrationUuidLo))
        parameter("modifiedSince", _repo.config.json.encodeToString(Long.serializer(),
            modifiedSince))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByAgentAndActivity(accountPersonUid, actorUid, seActivityUid,
        registrationUuidHi, registrationUuidLo, modifiedSince)
    return _result
  }

  override suspend fun getStateIds(
    accountPersonUid: Long,
    actorUid: Long,
    seActivityUid: Long,
    registrationUuidHi: Long?,
    registrationUuidLo: Long?,
    modifiedSince: Long,
  ): List<StateIdAndLastModified> {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "StateEntityDao/getStateIds") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "StateEntityDao/getStateIds")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("actorUid", _repo.config.json.encodeToString(Long.serializer(), actorUid))
        parameter("seActivityUid", _repo.config.json.encodeToString(Long.serializer(),
            seActivityUid))
        parameter("registrationUuidHi", _repo.config.json.encodeToString(Long.serializer().nullable,
            registrationUuidHi))
        parameter("registrationUuidLo", _repo.config.json.encodeToString(Long.serializer().nullable,
            registrationUuidLo))
        parameter("modifiedSince", _repo.config.json.encodeToString(Long.serializer(),
            modifiedSince))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getStateIds(accountPersonUid, actorUid, seActivityUid, registrationUuidHi,
        registrationUuidLo, modifiedSince)
    return _result
  }

  override suspend fun getH5PPreload(
    accountPersonUid: Long,
    actorUid: Long,
    seActivityUid: Long,
    registrationUuidHi: Long?,
    registrationUuidLo: Long?,
  ): List<StateEntity> {
    val _result = _dao.getH5PPreload(accountPersonUid, actorUid, seActivityUid, registrationUuidHi,
        registrationUuidLo)
    return _result
  }
}
