package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.DeletedItem
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class DeletedItemDao {
  public actual abstract suspend fun insertDeletedItemForContentEntryParentChildJoin(
    cepcjUid: Long,
    time: Long,
    deletedByPersonUid: Long,
  )

  public actual abstract suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long):
      List<DeletedItem>

  public actual abstract fun findDeletedItemsForUser(personUid: Long,
      includeActionedItems: Boolean): PagingSource<Int, DeletedItem>

  public actual abstract suspend fun updateStatusByUids(
    uidList: List<Long>,
    newStatus: Int,
    updateTime: Long,
  )
}
