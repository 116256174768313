package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.DiscussionPostAndPosterNames
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class DiscussionPostDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: DiscussionPostDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : DiscussionPostDao() {
  override fun getTopLevelPostsByCourseBlockUid(courseBlockUid: Long, includeDeleted: Boolean):
      PagingSource<Int, DiscussionPostWithDetails> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "DiscussionPostDao/getTopLevelPostsByCourseBlockUid",
    dbPagingSource = _dao.getTopLevelPostsByCourseBlockUid(courseBlockUid, includeDeleted),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "DiscussionPostDao/getTopLevelPostsByCourseBlockUid") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "DiscussionPostDao/getTopLevelPostsByCourseBlockUid")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("courseBlockUid", _repo.config.json.encodeToString(Long.serializer(),
              courseBlockUid))
          parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
              includeDeleted))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun getTopLevelPostsByCourseBlockUidPersons(courseBlockUid: Long):
      List<PersonAndPicture> {
    val _result = _dao.getTopLevelPostsByCourseBlockUidPersons(courseBlockUid)
    return _result
  }

  override suspend fun getTopLevelPostsByCourseBlockUidLatestMessage(courseBlockUid: Long):
      List<DiscussionPost> {
    val _result = _dao.getTopLevelPostsByCourseBlockUidLatestMessage(courseBlockUid)
    return _result
  }

  override suspend fun getPostTitle(postUid: Long): String? {
    val _result = _dao.getPostTitle(postUid)
    return _result
  }

  override suspend fun findByUid(uid: Long): DiscussionPost? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  override fun getTitleByUidAsFlow(uid: Long): Flow<String?> {
    val _result = _dao.getTitleByUidAsFlow(uid)
    return _result
  }

  override suspend fun updateAsync(entity: DiscussionPost): Int {
    val _result = _repo.withRepoChangeMonitorAsync("DiscussionPost") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override fun findByPostIdWithAllReplies(postUid: Long, includeDeleted: Boolean):
      PagingSource<Int, DiscussionPostAndPosterNames> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "DiscussionPostDao/findByPostIdWithAllReplies",
    dbPagingSource = _dao.findByPostIdWithAllReplies(postUid, includeDeleted),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath = "DiscussionPostDao/findByPostIdWithAllReplies") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "DiscussionPostDao/findByPostIdWithAllReplies")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("postUid", _repo.config.json.encodeToString(Long.serializer(), postUid))
          parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
              includeDeleted))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun findByPostIdWithAllRepliesPersons(postUid: Long): List<PersonAndPicture> {
    val _result = _dao.findByPostIdWithAllRepliesPersons(postUid)
    return _result
  }

  override suspend fun upsertAsync(entity: DiscussionPost) {
    _repo.withRepoChangeMonitorAsync("DiscussionPost") {
      _dao.upsertAsync(entity)
    }
  }

  override suspend fun setDeletedAsync(
    uid: Long,
    deleted: Boolean,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("DiscussionPost") {
      _dao.setDeletedAsync(uid, deleted, updateTime)
    }
  }

  public override fun insert(entity: DiscussionPost): Long {
    val _result = _repo.withRepoChangeMonitor("DiscussionPost") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: DiscussionPost): Long {
    val _result = _repo.withRepoChangeMonitorAsync("DiscussionPost") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<DiscussionPost>) {
    _repo.withRepoChangeMonitor("DiscussionPost") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: DiscussionPost) {
    _repo.withRepoChangeMonitor("DiscussionPost") {
      _dao.update(entity)
    }
  }
}
