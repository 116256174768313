package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.CacheLockJoin
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class CacheLockJoinDao {
  public actual abstract suspend fun findPendingLocks(): List<CacheLockJoin>

  public actual abstract suspend fun updateLockIdAndStatus(
    uid: Int,
    lockId: Long,
    status: Int,
  )

  public actual abstract suspend fun insertListAsync(list: List<CacheLockJoin>)

  public actual abstract suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long):
      List<CacheLockJoin>

  public actual abstract suspend fun deleteListAsync(list: List<CacheLockJoin>)
}
