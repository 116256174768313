package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.CourseAssignmentSubmissionFileAndTransferJob
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionFile
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CourseAssignmentSubmissionFileDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseAssignmentSubmissionFileDao,
) : CourseAssignmentSubmissionFileDao() {
  override suspend fun insertListAsync(entityList: List<CourseAssignmentSubmissionFile>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.casaUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(90)
        it.casaUid = _newPk
        _generatedPks += _newPk
      }
      it.casaTimestamp = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  override fun getBySubmissionUid(submissionUid: Long):
      Flow<List<CourseAssignmentSubmissionFileAndTransferJob>> =
      _dao.getBySubmissionUid(submissionUid)

  override fun getByAssignmentUidAndPersonUid(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmissionFileAndTransferJob>> =
      _dao.getByAssignmentUidAndPersonUid(accountPersonUid, assignmentUid)

  override fun getAllSubmissionFilesFromSubmitterAsFlow(submitterUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmissionFileAndTransferJob>> =
      _dao.getAllSubmissionFilesFromSubmitterAsFlow(submitterUid, assignmentUid)

  override suspend fun updateUri(
    casaUid: Long,
    uri: String,
    updateTime: Long,
  ) {
    _dao.updateUri(casaUid, uri, updateTime)
  }

  override suspend fun setDeleted(
    casaUid: Long,
    deleted: Boolean,
    updateTime: Long,
  ) {
    _dao.setDeleted(casaUid, deleted, updateTime)
  }

  public override fun insert(entity: CourseAssignmentSubmissionFile): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseAssignmentSubmissionFile): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.casaUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(90)
      entity.casaUid = _newPk
    }
    entity.casaTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.casaUid
  }

  public override fun insertList(entityList: List<CourseAssignmentSubmissionFile>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseAssignmentSubmissionFile): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
