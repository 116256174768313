package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.ActivityLangMapEntry
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ActivityLangMapEntryDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ActivityLangMapEntryDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ActivityLangMapEntryDao() {
  override suspend fun upsertList(entities: List<ActivityLangMapEntry>) {
    _repo.withRepoChangeMonitorAsync("ActivityLangMapEntry") {
      _dao.upsertList(entities)
    }
  }

  override suspend fun upsertIfInteractionEntityExists(
    almeActivityUid: Long,
    almeHash: Long,
    almeLangCode: String?,
    almeValue: String?,
    almeAieHash: Long,
    almeLastMod: Long,
  ) {
    _dao.upsertIfInteractionEntityExists(almeActivityUid, almeHash, almeLangCode, almeValue,
        almeAieHash, almeLastMod)
  }

  override suspend fun updateIfChanged(
    almeActivityUid: Long,
    almeHash: Long,
    almeValue: String?,
    almeLastMod: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ActivityLangMapEntry") {
      _dao.updateIfChanged(almeActivityUid, almeHash, almeValue, almeLastMod)
    }
  }

  override suspend fun findAllByActivityUid(activityUid: Long): List<ActivityLangMapEntry> {
    val _result = _dao.findAllByActivityUid(activityUid)
    return _result
  }
}
