package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.ClazzEnrolmentAndPerson
import com.ustadmobile.lib.db.composites.ClazzEnrolmentAndPersonDetailDetails
import com.ustadmobile.lib.db.composites.CourseNameAndPersonName
import com.ustadmobile.lib.db.composites.PersonAndClazzMemberListDetails
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson
import com.ustadmobile.lib.db.entities.xapi.ActorEntity
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ClazzEnrolmentDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ClazzEnrolmentDao,
) : ClazzEnrolmentDao() {
  override fun insertListAsync(entityList: List<ClazzEnrolment>): Unit = throw
      IllegalStateException("insertListAsync: synchronous db access is NOT possible on Javascript!")

  override fun findAllEnrolmentsByPersonAndClazzUid(personUid: Long, clazzUid: Long):
      Flow<List<ClazzEnrolmentWithLeavingReason>> =
      _dao.findAllEnrolmentsByPersonAndClazzUid(personUid, clazzUid)

  override suspend fun findEnrolmentWithLeavingReason(enrolmentUid: Long):
      ClazzEnrolmentWithLeavingReason? = _dao.findEnrolmentWithLeavingReason(enrolmentUid)

  override suspend fun updateDateLeftByUid(
    clazzEnrolmentUid: Long,
    endDate: Long,
    updateTime: Long,
  ) {
    _dao.updateDateLeftByUid(clazzEnrolmentUid, endDate, updateTime)
  }

  override suspend fun updateAsync(entity: ClazzEnrolment): Int {
    entity.clazzEnrolmentLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override fun findAllClazzesByPersonWithClazz(accountPersonUid: Long, otherPersonUid: Long):
      Flow<List<ClazzEnrolmentAndPersonDetailDetails>> =
      _dao.findAllClazzesByPersonWithClazz(accountPersonUid, otherPersonUid)

  override fun findAllByPersonUid(personUid: Long): Flow<List<ClazzEnrolment>> =
      _dao.findAllByPersonUid(personUid)

  override suspend fun findAllClazzesByPersonWithClazzAsListAsync(personUid: Long):
      List<ClazzEnrolmentWithClazz> = _dao.findAllClazzesByPersonWithClazzAsListAsync(personUid)

  override suspend fun getAllClazzEnrolledAtTimeAsync(
    clazzUid: Long,
    date: Long,
    roleFilter: Int,
    personUidFilter: Long,
  ): List<ClazzEnrolmentWithPerson> = _dao.getAllClazzEnrolledAtTimeAsync(clazzUid, date,
      roleFilter, personUidFilter)

  override suspend fun getAllEnrolmentsAtTimeByClazzAndPerson(
    clazzUid: Long,
    accountPersonUid: Long,
    time: Long,
  ): List<ClazzEnrolment> = _dao.getAllEnrolmentsAtTimeByClazzAndPerson(clazzUid, accountPersonUid,
      time)

  override suspend fun findByUid(uid: Long): ClazzEnrolment? = _dao.findByUid(uid)

  override fun findByUidLive(uid: Long): Flow<ClazzEnrolment?> = _dao.findByUidLive(uid)

  override fun findByClazzUidAndRole(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
    permission: Long,
  ): PagingSource<Int, PersonAndClazzMemberListDetails> = _dao.findByClazzUidAndRole(clazzUid,
      roleId, sortOrder, searchText, filter, accountPersonUid, currentTime, permission)

  override fun findByClazzUidAndRoleForGradebook(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
  ): PagingSource<Int, PersonAndClazzMemberListDetails> =
      _dao.findByClazzUidAndRoleForGradebook(clazzUid, roleId, sortOrder, searchText, filter,
      accountPersonUid, currentTime)

  override suspend fun findActorEntitiesForGradebook(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
    studentsLimit: Int,
    studentsOffset: Int,
  ): List<ActorEntity> = _dao.findActorEntitiesForGradebook(clazzUid, roleId, sortOrder, searchText,
      filter, accountPersonUid, currentTime, studentsLimit, studentsOffset)

  override suspend fun findEnrolmentsAndPersonByClazzUidWithPermissionCheck(clazzUid: Long,
      accountPersonUid: Long): List<ClazzEnrolmentAndPerson> =
      _dao.findEnrolmentsAndPersonByClazzUidWithPermissionCheck(clazzUid, accountPersonUid)

  override suspend fun findByAccountPersonUidAndClazzUid(accountPersonUid: Long, clazzUid: Long):
      List<ClazzEnrolment> = _dao.findByAccountPersonUidAndClazzUid(accountPersonUid, clazzUid)

  override suspend fun findEnrolmentsByClazzUidAndRole(
    clazzUid: Long,
    accountPersonUid: Long,
    roleId: Int,
    permission: Long,
  ): List<ClazzEnrolment> = _dao.findEnrolmentsByClazzUidAndRole(clazzUid, accountPersonUid, roleId,
      permission)

  override suspend fun findEnrolmentsByClazzUidAndRolePaged(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
    permission: Long,
    studentsLimit: Int,
    studentsOffset: Int,
  ): List<ClazzEnrolment> = _dao.findEnrolmentsByClazzUidAndRolePaged(clazzUid, roleId, sortOrder,
      searchText, filter, accountPersonUid, currentTime, permission, studentsLimit, studentsOffset)

  override suspend fun findAllEnrolmentsByClazzUidAndRole(clazzUid: Long, roleId: Int):
      List<ClazzEnrolment> = _dao.findAllEnrolmentsByClazzUidAndRole(clazzUid, roleId)

  override fun updateClazzEnrolmentActiveForClazzEnrolment(
    clazzEnrolmentUid: Long,
    enrolled: Boolean,
    timeChanged: Long,
  ): Int = throw
      IllegalStateException("updateClazzEnrolmentActiveForClazzEnrolment: synchronous db access is NOT possible on Javascript!")

  override suspend fun updateClazzEnrolmentRole(
    personUid: Long,
    clazzUid: Long,
    newRole: Int,
    oldRole: Int,
    updateTime: Long,
  ): Int = _dao.updateClazzEnrolmentRole(personUid, clazzUid, newRole, oldRole, updateTime)

  override suspend fun getClazzNameAndPersonName(personUid: Long, clazzUid: Long):
      CourseNameAndPersonName? = _dao.getClazzNameAndPersonName(personUid, clazzUid)

  override suspend
      fun findClazzEnrolmentEntitiesForPersonViewPermissionCheck(accountPersonUid: Long,
      otherPersonUid: Long): List<ClazzEnrolment> =
      _dao.findClazzEnrolmentEntitiesForPersonViewPermissionCheck(accountPersonUid, otherPersonUid)

  public override fun insert(entity: ClazzEnrolment): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzEnrolment): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.clazzEnrolmentUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(65)
      entity.clazzEnrolmentUid = _newPk
    }
    entity.clazzEnrolmentLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.clazzEnrolmentUid
  }

  public override fun insertList(entityList: List<ClazzEnrolment>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzEnrolment): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
