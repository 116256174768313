package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.PersonAndClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzLogAttendanceRecordDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ClazzLogAttendanceRecordDao,
) : ClazzLogAttendanceRecordDao() {
  override suspend fun insertListAsync(entities: List<ClazzLogAttendanceRecord>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      if(it.clazzLogAttendanceRecordUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(15)
        it.clazzLogAttendanceRecordUid = _newPk
        _generatedPks += _newPk
      }
      it.clazzLogAttendanceRecordLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entities)
  }

  override fun findByUid(uid: Long): ClazzLogAttendanceRecord? = throw
      IllegalStateException("findByUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun updateListAsync(entities: List<ClazzLogAttendanceRecord>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.clazzLogAttendanceRecordLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entities)
  }

  override suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson> = _dao.findByClazzLogUid(clazzLogUid)

  override fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long,
  ): Unit = throw
      IllegalStateException("updateRescheduledClazzLogUids: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByClazzAndTime(
    clazzUid: Long,
    clazzLogUid: Long,
    time: Long,
  ): List<PersonAndClazzLogAttendanceRecord> = _dao.findByClazzAndTime(clazzUid, clazzLogUid, time)

  override suspend fun upsertListAsync(entityList: List<ClazzLogAttendanceRecord>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.clazzLogAttendanceRecordUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(15)
        it.clazzLogAttendanceRecordUid = _newPk
        _generatedPks += _newPk
      }
      it.clazzLogAttendanceRecordLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entityList)
  }

  public override fun insert(entity: ClazzLogAttendanceRecord): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzLogAttendanceRecord): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.clazzLogAttendanceRecordUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(15)
      entity.clazzLogAttendanceRecordUid = _newPk
    }
    entity.clazzLogAttendanceRecordLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.clazzLogAttendanceRecordUid
  }

  public override fun insertList(entityList: List<ClazzLogAttendanceRecord>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzLogAttendanceRecord): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
