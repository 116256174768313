package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.CommentsAndName
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public class CommentsDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CommentsDao,
) : CommentsDao() {
  override suspend fun insertAsync(comments: Comments): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(comments.commentsUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(208)
      comments.commentsUid = _newPk
    }
    comments.commentsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(comments)
    return comments.commentsUid
  }

  override suspend fun updateDeletedByCommentUid(
    uid: Long,
    deleted: Boolean,
    changeTime: Long,
  ) {
    _dao.updateDeletedByCommentUid(uid, deleted, changeTime)
  }

  override fun findPrivateCommentsForUserByAssignmentUid(
    accountPersonUid: Long,
    assignmentUid: Long,
    includeDeleted: Boolean,
  ): PagingSource<Int, CommentsAndName> =
      _dao.findPrivateCommentsForUserByAssignmentUid(accountPersonUid, assignmentUid,
      includeDeleted)

  override suspend fun findPrivateCommentsForUserByAssignmentUidPersons(accountPersonUid: Long,
      assignmentUid: Long): List<PersonAndPicture> =
      _dao.findPrivateCommentsForUserByAssignmentUidPersons(accountPersonUid, assignmentUid)

  override fun findPrivateCommentsForSubmitterByAssignmentUid(
    submitterUid: Long,
    assignmentUid: Long,
    includeDeleted: Boolean,
  ): PagingSource<Int, CommentsAndName> =
      _dao.findPrivateCommentsForSubmitterByAssignmentUid(submitterUid, assignmentUid,
      includeDeleted)

  override fun findPrivateCommentsForSubmitterByAssignmentUidPersons(submitterUid: Long,
      assignmentUid: Long): List<PersonAndPicture> = throw
      IllegalStateException("findPrivateCommentsForSubmitterByAssignmentUidPersons: synchronous db access is NOT possible on Javascript!")

  override fun findCourseCommentsByAssignmentUid(assignmentUid: Long, includeDeleted: Boolean):
      PagingSource<Int, CommentsAndName> = _dao.findCourseCommentsByAssignmentUid(assignmentUid,
      includeDeleted)

  override suspend fun findCourseCommentsByAssignmentUidPersons(assignmentUid: Long): List<Person> =
      _dao.findCourseCommentsByAssignmentUidPersons(assignmentUid)
}
