package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentCategorySchema
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentCategorySchemaDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentCategorySchemaDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentCategorySchemaDao() {
  override fun publicContentCategorySchemas(): List<ContentCategorySchema> {
    val _result = _dao.publicContentCategorySchemas()
    return _result
  }

  override fun findBySchemaUrl(schemaUrl: String): ContentCategorySchema? {
    val _result = _dao.findBySchemaUrl(schemaUrl)
    return _result
  }

  public override fun insert(entity: ContentCategorySchema): Long {
    val _result = _repo.withRepoChangeMonitor("ContentCategorySchema") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ContentCategorySchema): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ContentCategorySchema") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ContentCategorySchema>) {
    _repo.withRepoChangeMonitor("ContentCategorySchema") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: ContentCategorySchema) {
    _repo.withRepoChangeMonitor("ContentCategorySchema") {
      _dao.update(entity)
    }
  }
}
