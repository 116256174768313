package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.Site
import kotlin.Unit

public actual fun Site.shallowCopy(block: Site.() -> Unit): Site = Site().also {
  it.siteUid = this.siteUid
  it.sitePcsn = this.sitePcsn
  it.siteLcsn = this.siteLcsn
  it.siteLcb = this.siteLcb
  it.siteLct = this.siteLct
  it.siteName = this.siteName
  it.guestLogin = this.guestLogin
  it.registrationAllowed = this.registrationAllowed
  it.authSalt = this.authSalt
  block(it)
}
