package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryVersion
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ContentEntryVersionDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentEntryVersionDao,
) : ContentEntryVersionDao() {
  override suspend fun findByUidAsync(cevUid: Long): ContentEntryVersion? =
      _dao.findByUidAsync(cevUid)

  override suspend fun insertAsync(contentEntryVersion: ContentEntryVersion): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(contentEntryVersion.cevUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(738)
      contentEntryVersion.cevUid = _newPk
    }
    contentEntryVersion.cevLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(contentEntryVersion)
    return contentEntryVersion.cevUid
  }

  override suspend fun findLatestVersionUidByContentEntryUidEntity(contentEntryUid: Long):
      ContentEntryVersion? = _dao.findLatestVersionUidByContentEntryUidEntity(contentEntryUid)

  override fun findLatestByContentEntryUidAsFlow(contentEntryUid: Long): Flow<ContentEntryVersion?>
      = _dao.findLatestByContentEntryUidAsFlow(contentEntryUid)

  override suspend fun updateTransferJobItemEtag(entityUid: Long, transferJobItemUid: Int) {
    _dao.updateTransferJobItemEtag(entityUid, transferJobItemUid)
  }

  override suspend fun findContentEntryVersionsWithoutCacheLock(): List<ContentEntryVersion> =
      _dao.findContentEntryVersionsWithoutCacheLock()
}
