package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.ActivityEntity
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ActivityEntityDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ActivityEntityDao,
) : ActivityEntityDao() {
  override suspend fun insertOrIgnoreAsync(entities: List<ActivityEntity>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.actLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreAsync(entities)
  }

  override suspend fun updateIfMoreInfoChanged(
    activityUid: Long,
    actMoreInfo: String?,
    actLct: Long,
  ) {
    _dao.updateIfMoreInfoChanged(activityUid, actMoreInfo, actLct)
  }

  override suspend fun updateIfNotYetDefined(
    actUid: Long,
    actType: String?,
    actMoreInfo: String?,
    actInteractionType: Int,
    actCorrectResponsePatterns: String?,
  ) {
    _dao.updateIfNotYetDefined(actUid, actType, actMoreInfo, actInteractionType,
        actCorrectResponsePatterns)
  }

  override suspend fun findByUidAsync(activityUid: Long): ActivityEntity? =
      _dao.findByUidAsync(activityUid)
}
