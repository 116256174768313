package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SiteTermsDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SiteTermsDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : SiteTermsDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun findSiteTerms(langCode: String): SiteTerms? {
    val _result = _dao.findSiteTerms(langCode)
    return _result
  }

  override suspend fun insertAsync(siteTerms: SiteTerms): Long {
    val _result = _repo.withRepoChangeMonitorAsync("SiteTerms") {
      _dao.insertAsync(siteTerms)
    }
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): SiteTerms? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override fun findAllTermsAsListFlow(activeOnly: Int): Flow<List<SiteTerms>> =
      repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findAllTermsAsListFlow(activeOnly),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath = "SiteTermsDao/findAllTermsAsListFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "SiteTermsDao/findAllTermsAsListFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("activeOnly", _repo.config.json.encodeToString(Int.serializer(), activeOnly))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun findAllTerms(activeOnly: Int): List<SiteTerms> {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "SiteTermsDao/findAllTerms") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "SiteTermsDao/findAllTerms")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("activeOnly", _repo.config.json.encodeToString(Int.serializer(), activeOnly))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findAllTerms(activeOnly)
    return _result
  }

  override suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage> {
    val _result = _dao.findAllWithLanguageAsList()
    return _result
  }

  override suspend fun upsertList(termList: List<SiteTerms>) {
    _repo.withRepoChangeMonitorAsync("SiteTerms") {
      _dao.upsertList(termList)
    }
  }

  override suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("SiteTerms") {
      _dao.updateActiveByUid(sTermsUid, active, changeTime)
    }
  }

  override suspend fun findAvailableSiteTermLanguages(active: Int): List<String?> {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "SiteTermsDao/findAvailableSiteTermLanguages") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "SiteTermsDao/findAvailableSiteTermLanguages")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("active", _repo.config.json.encodeToString(Int.serializer(), active))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findAvailableSiteTermLanguages(active)
    return _result
  }

  override suspend fun findLatestByLanguage(lang: String): SiteTerms? {
    val _result = _dao.findLatestByLanguage(lang)
    return _result
  }

  public override suspend fun insertListAsync(entityList: List<SiteTerms>) {
    _repo.withRepoChangeMonitorAsync("SiteTerms") {
      _dao.insertListAsync(entityList)
    }
  }

  public override suspend fun updateListAsync(entityList: List<SiteTerms>) {
    _repo.withRepoChangeMonitorAsync("SiteTerms") {
      _dao.updateListAsync(entityList)
    }
  }
}
