package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.ActivityExtensionEntity
import kotlin.Long
import kotlin.collections.List

public class ActivityExtensionDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ActivityExtensionDao,
) : ActivityExtensionDao() {
  override suspend fun upsertListAsync(list: List<ActivityExtensionEntity>) {
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      it.aeeLastMod = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(list)
  }

  override suspend fun findAllByActivityUid(activityUid: Long): List<ActivityExtensionEntity> =
      _dao.findAllByActivityUid(activityUid)
}
