package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoin
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLangName
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLanguage
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryRelatedEntryJoinDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryRelatedEntryJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentEntryRelatedEntryJoinDao() {
  override fun publicContentEntryRelatedEntryJoins(): List<ContentEntryRelatedEntryJoin> {
    val _result = _dao.publicContentEntryRelatedEntryJoins()
    return _result
  }

  override fun findPrimaryByTranslation(contentEntryUid: Long): ContentEntryRelatedEntryJoin? {
    val _result = _dao.findPrimaryByTranslation(contentEntryUid)
    return _result
  }

  override suspend fun findAllTranslationsForContentEntryAsync(contentEntryUid: Long):
      List<ContentEntryRelatedEntryJoinWithLangName> {
    val _result = _dao.findAllTranslationsForContentEntryAsync(contentEntryUid)
    return _result
  }

  override fun findAllTranslationsWithContentEntryUid(contentEntryUid: Long):
      PagingSource<Int, ContentEntryRelatedEntryJoinWithLanguage> {
    val _result = _dao.findAllTranslationsWithContentEntryUid(contentEntryUid)
    return _result
  }

  override fun update(entity: ContentEntryRelatedEntryJoin) {
    _repo.withRepoChangeMonitor("ContentEntryRelatedEntryJoin") {
      _dao.update(entity)
    }
  }

  public override fun insert(entity: ContentEntryRelatedEntryJoin): Long {
    val _result = _repo.withRepoChangeMonitor("ContentEntryRelatedEntryJoin") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ContentEntryRelatedEntryJoin): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ContentEntryRelatedEntryJoin") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ContentEntryRelatedEntryJoin>) {
    _repo.withRepoChangeMonitor("ContentEntryRelatedEntryJoin") {
      _dao.insertList(entityList)
    }
  }
}
