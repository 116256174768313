package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.Language
import kotlin.Unit

public actual fun Language.shallowCopy(block: Language.() -> Unit): Language = Language().also {
  it.langUid = this.langUid
  it.name = this.name
  it.iso_639_1_standard = this.iso_639_1_standard
  it.iso_639_2_standard = this.iso_639_2_standard
  it.iso_639_3_standard = this.iso_639_3_standard
  it.Language_Type = this.Language_Type
  it.languageActive = this.languageActive
  it.langLocalChangeSeqNum = this.langLocalChangeSeqNum
  it.langMasterChangeSeqNum = this.langMasterChangeSeqNum
  it.langLastChangedBy = this.langLastChangedBy
  it.langLct = this.langLct
  block(it)
}
