package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PeerReviewerAllocation
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PeerReviewerAllocationDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: PeerReviewerAllocationDao,
) : PeerReviewerAllocationDao() {
  override suspend fun replaceListAsync(entries: List<PeerReviewerAllocation>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entries.iterator().forEach  {
      if(it.praUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(140)
        it.praUid = _newPk
        _generatedPks += _newPk
      }
      it.praLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceListAsync(entries)
  }

  override suspend fun getAllPeerReviewerAllocations(assignmentUid: List<Long>):
      List<PeerReviewerAllocation> = _dao.getAllPeerReviewerAllocations(assignmentUid)

  override suspend fun getAllPeerReviewerAllocationsByClazzUid(clazzUid: Long,
      includeInactive: Boolean): List<PeerReviewerAllocation> =
      _dao.getAllPeerReviewerAllocationsByClazzUid(clazzUid, includeInactive)

  override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  override suspend fun upsertList(entityList: List<PeerReviewerAllocation>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.praUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(140)
        it.praUid = _newPk
        _generatedPks += _newPk
      }
      it.praLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertList(entityList)
  }

  public override fun insert(entity: PeerReviewerAllocation): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: PeerReviewerAllocation): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.praUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(140)
      entity.praUid = _newPk
    }
    entity.praLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.praUid
  }

  public override fun insertList(entityList: List<PeerReviewerAllocation>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: PeerReviewerAllocation): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
