package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.DeletedItem
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class DeletedItemDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: DeletedItemDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : DeletedItemDao() {
  override suspend fun insertDeletedItemForContentEntryParentChildJoin(
    cepcjUid: Long,
    time: Long,
    deletedByPersonUid: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("DeletedItem") {
      _dao.insertDeletedItemForContentEntryParentChildJoin(cepcjUid, time, deletedByPersonUid)
    }
  }

  override suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long): List<DeletedItem> {
    val _result = _dao.findByTableIdAndEntityUid(tableId, entityUid)
    return _result
  }

  override fun findDeletedItemsForUser(personUid: Long, includeActionedItems: Boolean):
      PagingSource<Int, DeletedItem> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "DeletedItemDao/findDeletedItemsForUser",
    dbPagingSource = _dao.findDeletedItemsForUser(personUid, includeActionedItems),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath = "DeletedItemDao/findDeletedItemsForUser") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "DeletedItemDao/findDeletedItemsForUser")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("personUid", _repo.config.json.encodeToString(Long.serializer(), personUid))
          parameter("includeActionedItems", _repo.config.json.encodeToString(Boolean.serializer(),
              includeActionedItems))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun updateStatusByUids(
    uidList: List<Long>,
    newStatus: Int,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("DeletedItem") {
      _dao.updateStatusByUids(uidList, newStatus, updateTime)
    }
  }
}
