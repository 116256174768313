package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.PersonGroup
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public actual abstract class PersonGroupDao : BaseDao<PersonGroup> {
  public actual abstract fun findByUid(uid: Long): PersonGroup?

  public actual abstract suspend fun findByUidAsync(uid: Long): PersonGroup?

  public actual abstract fun findByUidLive(uid: Long): Flow<PersonGroup?>

  public actual abstract suspend fun updateAsync(entity: PersonGroup): Int

  public actual abstract suspend fun findNameByGroupUid(groupUid: Long): String?
}
