package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.composites.ContentEntryImportJobProgress
import com.ustadmobile.lib.db.entities.ContentEntryImportJob
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ContentEntryImportJobDao {
  public actual abstract suspend fun insertJobItem(jobItem: ContentEntryImportJob): Long

  public actual abstract suspend fun updateItemStatus(cjiUid: Long, status: Int)

  public actual abstract suspend fun updateItemStatusAndError(
    cjiUid: Long,
    status: Int,
    error: String?,
  )

  public actual abstract suspend fun updateErrorDismissed(cjiUid: Long, dismissed: Boolean)

  public actual abstract suspend fun updateItemProgress(
    cjiUid: Long,
    cjiProgress: Long,
    cjiTotal: Long,
  )

  public actual abstract suspend fun findByUidAsync(cjiUid: Long): ContentEntryImportJob?

  public actual abstract suspend fun findOwnerByUidAsync(cjiUid: Long): Long

  public actual abstract fun findInProgressJobsByContentEntryUid(contentEntryUid: Long):
      Flow<List<ContentEntryImportJobProgress>>

  public actual abstract suspend
      fun findInProgressJobsByContentEntryUidAsync(contentEntryUid: Long):
      List<ContentEntryImportJobProgress>
}
