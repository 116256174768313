package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.SiteTerms
import kotlin.Unit

public actual fun SiteTerms.shallowCopy(block: SiteTerms.() -> Unit): SiteTerms = SiteTerms().also {
  it.sTermsUid = this.sTermsUid
  it.termsHtml = this.termsHtml
  it.sTermsLang = this.sTermsLang
  it.sTermsLangUid = this.sTermsLangUid
  it.sTermsActive = this.sTermsActive
  it.sTermsLastChangedBy = this.sTermsLastChangedBy
  it.sTermsPrimaryCsn = this.sTermsPrimaryCsn
  it.sTermsLocalCsn = this.sTermsLocalCsn
  it.sTermsLct = this.sTermsLct
  block(it)
}
