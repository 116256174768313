package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonGroup
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class PersonGroupDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonGroupDao,
) : PersonGroupDao() {
  override fun findByUid(uid: Long): PersonGroup? = throw
      IllegalStateException("findByUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUidAsync(uid: Long): PersonGroup? = _dao.findByUidAsync(uid)

  override fun findByUidLive(uid: Long): Flow<PersonGroup?> = _dao.findByUidLive(uid)

  override suspend fun updateAsync(entity: PersonGroup): Int {
    entity.groupLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override suspend fun findNameByGroupUid(groupUid: Long): String? =
      _dao.findNameByGroupUid(groupUid)

  public override fun insert(entity: PersonGroup): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: PersonGroup): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.groupUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(43)
      entity.groupUid = _newPk
    }
    entity.groupLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.groupUid
  }

  public override fun insertList(entityList: List<PersonGroup>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: PersonGroup): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
