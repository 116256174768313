package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ScopedGrant
import kotlin.Long
import kotlin.collections.List

public actual abstract class ScopedGrantDao {
  public actual abstract suspend fun insertAsync(scopedGrant: ScopedGrant): Long

  public actual abstract suspend fun insertListAsync(scopedGrantList: List<ScopedGrant>)
}
