package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.PeerReviewerAllocation
import kotlin.Unit

public actual fun PeerReviewerAllocation.shallowCopy(block: PeerReviewerAllocation.() -> Unit):
    PeerReviewerAllocation = PeerReviewerAllocation().also {
  it.praUid = this.praUid
  it.praMarkerSubmitterUid = this.praMarkerSubmitterUid
  it.praToMarkerSubmitterUid = this.praToMarkerSubmitterUid
  it.praAssignmentUid = this.praAssignmentUid
  it.praActive = this.praActive
  it.praLct = this.praLct
  block(it)
}
