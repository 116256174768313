package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.VerbLangMapEntry
import kotlin.Long
import kotlin.collections.List

public actual abstract class VerbLangMapEntryDao {
  public actual abstract suspend fun upsertList(list: List<VerbLangMapEntry>)

  public actual abstract suspend fun findByVerbUidAsync(verbUid: Long): List<VerbLangMapEntry>
}
