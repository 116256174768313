package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.ClazzLog
import kotlin.Unit

public actual fun ClazzLog.shallowCopy(block: ClazzLog.() -> Unit): ClazzLog = ClazzLog().also {
  it.clazzLogUid = this.clazzLogUid
  it.clazzLogClazzUid = this.clazzLogClazzUid
  it.logDate = this.logDate
  it.timeRecorded = this.timeRecorded
  it.clazzLogDone = this.clazzLogDone
  it.cancellationNote = this.cancellationNote
  it.clazzLogCancelled = this.clazzLogCancelled
  it.clazzLogNumPresent = this.clazzLogNumPresent
  it.clazzLogNumAbsent = this.clazzLogNumAbsent
  it.clazzLogNumPartial = this.clazzLogNumPartial
  it.clazzLogScheduleUid = this.clazzLogScheduleUid
  it.clazzLogStatusFlag = this.clazzLogStatusFlag
  it.clazzLogMSQN = this.clazzLogMSQN
  it.clazzLogLCSN = this.clazzLogLCSN
  it.clazzLogLCB = this.clazzLogLCB
  it.clazzLogLastChangedTime = this.clazzLogLastChangedTime
  block(it)
}
