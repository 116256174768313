package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.composites.EditAndViewPermission
import com.ustadmobile.lib.db.composites.PermissionPair
import com.ustadmobile.lib.db.entities.SystemPermission
import kotlin.Boolean
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class SystemPermissionDao {
  public actual abstract suspend fun findAllByPersonUid(accountPersonUid: Long,
      includeDeleted: Boolean): List<SystemPermission>

  public actual abstract suspend fun findAllByPersonUidEntities(accountPersonUid: Long):
      List<SystemPermission>

  public actual abstract fun findByPersonUidAsFlow(accountPersonUid: Long): Flow<SystemPermission?>

  public actual abstract suspend fun findByPersonUid(accountPersonUid: Long): SystemPermission

  public actual abstract suspend fun personHasSystemPermission(accountPersonUid: Long,
      permission: Long): Boolean

  public actual abstract fun personHasSystemPermissionAsFlow(accountPersonUid: Long,
      permission: Long): Flow<Boolean>

  public actual abstract fun personHasSystemPermissionPairAsFlow(
    accountPersonUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): Flow<PermissionPair>

  public actual abstract suspend fun personHasSystemPermissionPair(
    accountPersonUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): PermissionPair

  public actual abstract fun personHasEditAndViewPermissionForPersonAsFlow(accountPersonUid: Long,
      otherPersonUid: Long): Flow<EditAndViewPermission>

  public actual abstract suspend fun upsertAsync(systemPermissions: SystemPermission)
}
