package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.composites.DiscussionPostAndPosterNames
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class DiscussionPostDao : BaseDao<DiscussionPost> {
  public actual abstract fun getTopLevelPostsByCourseBlockUid(courseBlockUid: Long,
      includeDeleted: Boolean): PagingSource<Int, DiscussionPostWithDetails>

  public actual abstract suspend fun getTopLevelPostsByCourseBlockUidPersons(courseBlockUid: Long):
      List<PersonAndPicture>

  public actual abstract suspend
      fun getTopLevelPostsByCourseBlockUidLatestMessage(courseBlockUid: Long): List<DiscussionPost>

  public actual abstract suspend fun getPostTitle(postUid: Long): String?

  public actual abstract suspend fun findByUid(uid: Long): DiscussionPost?

  public actual abstract fun getTitleByUidAsFlow(uid: Long): Flow<String?>

  public actual abstract suspend fun updateAsync(entity: DiscussionPost): Int

  public actual abstract fun findByPostIdWithAllReplies(postUid: Long, includeDeleted: Boolean):
      PagingSource<Int, DiscussionPostAndPosterNames>

  public actual abstract suspend fun findByPostIdWithAllRepliesPersons(postUid: Long):
      List<PersonAndPicture>

  public actual abstract suspend fun upsertAsync(entity: DiscussionPost)

  public actual abstract suspend fun setDeletedAsync(
    uid: Long,
    deleted: Boolean,
    updateTime: Long,
  )
}
