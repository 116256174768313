package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.GroupMemberActorJoin
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class GroupMemberActorJoinDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: GroupMemberActorJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : GroupMemberActorJoinDao() {
  override suspend fun insertOrIgnoreListAsync(entities: List<GroupMemberActorJoin>) {
    _repo.withRepoChangeMonitorAsync("GroupMemberActorJoin") {
      _dao.insertOrIgnoreListAsync(entities)
    }
  }

  override suspend fun upsertListAsync(entities: List<GroupMemberActorJoin>) {
    _repo.withRepoChangeMonitorAsync("GroupMemberActorJoin") {
      _dao.upsertListAsync(entities)
    }
  }

  override suspend fun updateLastModifiedTimeIfNeededAsync(
    gmajGroupActorUid: Long,
    gmajMemberActorUid: Long,
    lastModTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("GroupMemberActorJoin") {
      _dao.updateLastModifiedTimeIfNeededAsync(gmajGroupActorUid, gmajMemberActorUid, lastModTime)
    }
  }
}
