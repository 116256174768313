package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.TransferJobItem
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class TransferJobItemDao {
  public actual abstract suspend fun insertList(items: List<TransferJobItem>)

  public actual abstract suspend fun insert(item: TransferJobItem): Long

  public actual abstract suspend fun findByJobUid(jobUid: Int): List<TransferJobItem>

  public actual abstract suspend fun findPendingByJobUid(jobUid: Int): List<TransferJobItem>

  public actual abstract suspend fun updateTransferredProgress(jobItemUid: Int, transferred: Long)

  public actual abstract suspend fun updateStatus(jobItemUid: Int, status: Int)

  public actual abstract suspend
      fun insertOutgoingReplicationForTransferJobItemIfDone(destNodeId: Long,
      transferJobItemUid: Int)

  public actual abstract suspend fun updateStatusIfNotCompleteForAllInJob(jobUid: Int, status: Int)

  public actual abstract suspend fun findNumberJobItemsNotComplete(jobUid: Int): Int

  public actual abstract fun findByEntityUidList(entityUidList: List<Long>, tableId: Int):
      Flow<List<TransferJobItem>>
}
