package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.EditAndViewPermission
import com.ustadmobile.lib.db.composites.PermissionPair
import com.ustadmobile.lib.db.entities.SystemPermission
import kotlin.Boolean
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class SystemPermissionDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: SystemPermissionDao,
) : SystemPermissionDao() {
  override suspend fun findAllByPersonUid(accountPersonUid: Long, includeDeleted: Boolean):
      List<SystemPermission> = _dao.findAllByPersonUid(accountPersonUid, includeDeleted)

  override suspend fun findAllByPersonUidEntities(accountPersonUid: Long): List<SystemPermission> =
      _dao.findAllByPersonUidEntities(accountPersonUid)

  override fun findByPersonUidAsFlow(accountPersonUid: Long): Flow<SystemPermission?> =
      _dao.findByPersonUidAsFlow(accountPersonUid)

  override suspend fun findByPersonUid(accountPersonUid: Long): SystemPermission =
      _dao.findByPersonUid(accountPersonUid)

  override suspend fun personHasSystemPermission(accountPersonUid: Long, permission: Long): Boolean
      = _dao.personHasSystemPermission(accountPersonUid, permission)

  override fun personHasSystemPermissionAsFlow(accountPersonUid: Long, permission: Long):
      Flow<Boolean> = _dao.personHasSystemPermissionAsFlow(accountPersonUid, permission)

  override fun personHasSystemPermissionPairAsFlow(
    accountPersonUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): Flow<PermissionPair> = _dao.personHasSystemPermissionPairAsFlow(accountPersonUid,
      firstPermission, secondPermission)

  override suspend fun personHasSystemPermissionPair(
    accountPersonUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): PermissionPair = _dao.personHasSystemPermissionPair(accountPersonUid, firstPermission,
      secondPermission)

  override fun personHasEditAndViewPermissionForPersonAsFlow(accountPersonUid: Long,
      otherPersonUid: Long): Flow<EditAndViewPermission> =
      _dao.personHasEditAndViewPermissionForPersonAsFlow(accountPersonUid, otherPersonUid)

  override suspend fun upsertAsync(systemPermissions: SystemPermission) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(systemPermissions.spUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(10011)
      systemPermissions.spUid = _newPk
    }
    systemPermissions.spLastModified = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsertAsync(systemPermissions)
  }
}
