package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class LeavingReasonDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: LeavingReasonDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : LeavingReasonDao() {
  override fun findAllReasons(): PagingSource<Int, LeavingReason> {
    val _result = _dao.findAllReasons()
    return _result
  }

  override fun findAllReasonsLive(): List<LeavingReason> {
    val _result = _dao.findAllReasonsLive()
    return _result
  }

  override suspend fun findAllReasonsAsync(): List<LeavingReason> {
    val _result = _dao.findAllReasonsAsync()
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): LeavingReason? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override suspend fun findByUidList(uidList: List<Long>): List<Long> {
    val _result = _dao.findByUidList(uidList)
    return _result
  }

  override fun findByUidLive(uid: Long): Flow<LeavingReason?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  override suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel> {
    val _result = _dao.getReasonsFromUids(uidList)
    return _result
  }

  override suspend fun replaceList(entityList: List<LeavingReason>) {
    _repo.withRepoChangeMonitorAsync("LeavingReason") {
      _dao.replaceList(entityList)
    }
  }

  override suspend fun updateAsync(entity: LeavingReason): Int {
    val _result = _repo.withRepoChangeMonitorAsync("LeavingReason") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  public override fun insert(entity: LeavingReason): Long {
    val _result = _repo.withRepoChangeMonitor("LeavingReason") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: LeavingReason): Long {
    val _result = _repo.withRepoChangeMonitorAsync("LeavingReason") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<LeavingReason>) {
    _repo.withRepoChangeMonitor("LeavingReason") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: LeavingReason) {
    _repo.withRepoChangeMonitor("LeavingReason") {
      _dao.update(entity)
    }
  }
}
