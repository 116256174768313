package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoin
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLangName
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLanguage
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class ContentEntryRelatedEntryJoinDao : BaseDao<ContentEntryRelatedEntryJoin>
    {
  public actual abstract fun publicContentEntryRelatedEntryJoins():
      List<ContentEntryRelatedEntryJoin>

  public actual abstract fun findPrimaryByTranslation(contentEntryUid: Long):
      ContentEntryRelatedEntryJoin?

  public actual abstract suspend fun findAllTranslationsForContentEntryAsync(contentEntryUid: Long):
      List<ContentEntryRelatedEntryJoinWithLangName>

  public actual abstract fun findAllTranslationsWithContentEntryUid(contentEntryUid: Long):
      PagingSource<Int, ContentEntryRelatedEntryJoinWithLanguage>

  actual abstract override fun update(entity: ContentEntryRelatedEntryJoin)
}
