package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ClazzLog
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzLogDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzLogDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzLogDao() {
  override fun replace(entity: ClazzLog): Long {
    val _result = _repo.withRepoChangeMonitor("ClazzLog") {
      _dao.replace(entity)
    }
    return _result
  }

  override fun findByUid(uid: Long): ClazzLog? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): ClazzLog? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override fun findByUidLive(uid: Long): Flow<ClazzLog?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  override fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      PagingSource<Int, ClazzLog> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "ClazzLogDao/findByClazzUidAsFactory",
    dbPagingSource = _dao.findByClazzUidAsFactory(clazzUid, excludeStatus),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath = "ClazzLogDao/findByClazzUidAsFactory") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "ClazzLogDao/findByClazzUidAsFactory")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("excludeStatus", _repo.config.json.encodeToString(Int.serializer(),
              excludeStatus))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int): List<ClazzLog> {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "ClazzLogDao/findByClazzUidAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzLogDao/findByClazzUidAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("excludeStatus", _repo.config.json.encodeToString(Int.serializer(),
            excludeStatus))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByClazzUidAsync(clazzUid, excludeStatus)
    return _result
  }

  override suspend fun findAllForClazzByClazzLogUid(clazzLogUid: Long, excludeStatus: Int):
      List<ClazzLog> {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "ClazzLogDao/findAllForClazzByClazzLogUid") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzLogDao/findAllForClazzByClazzLogUid")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzLogUid", _repo.config.json.encodeToString(Long.serializer(), clazzLogUid))
        parameter("excludeStatus", _repo.config.json.encodeToString(Int.serializer(),
            excludeStatus))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findAllForClazzByClazzLogUid(clazzLogUid, excludeStatus)
    return _result
  }

  override suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> {
    val _result = _dao.findByClazzUidWithinTimeRangeAsync(clazzUid, fromTime, toTime,
        excludeStatusFilter, limit)
    return _result
  }

  override fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> {
    val _result = _dao.findByClazzUidWithinTimeRange(clazzUid, fromTime, toTime,
        excludeStatusFilter, limit)
    return _result
  }

  override fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int,
  ): Flow<List<ClazzLog>> {
    val _result = _dao.findByClazzUidWithinTimeRangeLive(clazzUid, fromTime, toTime, statusFilter)
    return _result
  }

  override fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int): Flow<Boolean> {
    val _result = _dao.clazzHasScheduleLive(clazzUid, excludeStatusFilter)
    return _result
  }

  override fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long,
  ) {
    _repo.withRepoChangeMonitor("ClazzLog") {
      _dao.updateStatusByClazzLogUid(clazzLogUid, newStatus, timeChanged)
    }
  }

  override suspend fun updateAsync(clazzLog: ClazzLog) {
    _repo.withRepoChangeMonitorAsync("ClazzLog") {
      _dao.updateAsync(clazzLog)
    }
  }

  override suspend fun findMostRecentClazzLogToEditUid(clazzUid: Long): Long {
    val _result = _dao.findMostRecentClazzLogToEditUid(clazzUid)
    return _result
  }

  override suspend fun upsertListAsync(entityList: List<ClazzLog>) {
    _repo.withRepoChangeMonitorAsync("ClazzLog") {
      _dao.upsertListAsync(entityList)
    }
  }

  public override fun insert(entity: ClazzLog): Long {
    val _result = _repo.withRepoChangeMonitor("ClazzLog") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzLog): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ClazzLog") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ClazzLog>) {
    _repo.withRepoChangeMonitor("ClazzLog") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: ClazzLog) {
    _repo.withRepoChangeMonitor("ClazzLog") {
      _dao.update(entity)
    }
  }
}
