package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.xapi.StateIdAndLastModified
import com.ustadmobile.lib.db.entities.xapi.StateEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.collections.List

public class StateEntityDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StateEntityDao,
) : StateEntityDao() {
  override suspend fun upsertAsync(stateEntities: List<StateEntity>) {
    val _generatedPks = mutableListOf<Long>()
    stateEntities.iterator().forEach  {
      it.seLastMod = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertAsync(stateEntities)
  }

  override suspend fun findByActorAndHash(
    accountPersonUid: Long,
    actorUid: Long,
    seHash: Long,
    includeDeleted: Boolean,
  ): StateEntity? = _dao.findByActorAndHash(accountPersonUid, actorUid, seHash, includeDeleted)

  override suspend fun findByAgentAndActivity(
    accountPersonUid: Long,
    actorUid: Long,
    seActivityUid: Long,
    registrationUuidHi: Long?,
    registrationUuidLo: Long?,
    modifiedSince: Long,
  ): List<StateEntity> = _dao.findByAgentAndActivity(accountPersonUid, actorUid, seActivityUid,
      registrationUuidHi, registrationUuidLo, modifiedSince)

  override suspend fun getStateIds(
    accountPersonUid: Long,
    actorUid: Long,
    seActivityUid: Long,
    registrationUuidHi: Long?,
    registrationUuidLo: Long?,
    modifiedSince: Long,
  ): List<StateIdAndLastModified> = _dao.getStateIds(accountPersonUid, actorUid, seActivityUid,
      registrationUuidHi, registrationUuidLo, modifiedSince)

  override suspend fun getH5PPreload(
    accountPersonUid: Long,
    actorUid: Long,
    seActivityUid: Long,
    registrationUuidHi: Long?,
    registrationUuidLo: Long?,
  ): List<StateEntity> = _dao.getH5PPreload(accountPersonUid, actorUid, seActivityUid,
      registrationUuidHi, registrationUuidLo)
}
