package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ParentEnrolmentRequired
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonParentJoinAndMinorPerson
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonParentJoinDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonParentJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : PersonParentJoinDao() {
  override suspend fun upsertAsync(entity: PersonParentJoin): Long {
    val _result = _repo.withRepoChangeMonitorAsync("PersonParentJoin") {
      _dao.upsertAsync(entity)
    }
    return _result
  }

  override suspend fun findByUidWithMinorAsync(uid: Long): PersonParentJoinAndMinorPerson? {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "PersonParentJoinDao/findByUidWithMinorAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "PersonParentJoinDao/findByUidWithMinorAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByUidWithMinorAsync(uid)
    return _result
  }

  override suspend fun findByUidWithMinorAsyncFromWeb(uid: Long): PersonParentJoinAndMinorPerson? {
    val _result = _dao.findByUidWithMinorAsyncFromWeb(uid)
    return _result
  }

  override suspend fun findByMinorPersonUid(minorPersonUid: Long): List<PersonParentJoin> {
    val _result = _dao.findByMinorPersonUid(minorPersonUid)
    return _result
  }

  override suspend fun findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid: Long,
      clazzUidFilter: Long): List<ParentEnrolmentRequired> {
    val _result = _dao.findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid,
        clazzUidFilter)
    return _result
  }

  override suspend fun isParentOf(userPersonUid: Long, minorPersonUid: Long): Boolean {
    val _result = _dao.isParentOf(userPersonUid, minorPersonUid)
    return _result
  }

  override suspend fun updateAsync(personParentJoin: PersonParentJoin) {
    _repo.withRepoChangeMonitorAsync("PersonParentJoin") {
      _dao.updateAsync(personParentJoin)
    }
  }

  override suspend fun isMinorApproved(minorPersonUid: Long): Boolean {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "PersonParentJoinDao/isMinorApproved") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "PersonParentJoinDao/isMinorApproved")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("minorPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            minorPersonUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.isMinorApproved(minorPersonUid)
    return _result
  }
}
