package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CourseAssignmentSubmissionDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseAssignmentSubmissionDao,
) : CourseAssignmentSubmissionDao() {
  override suspend fun insertListAsync(entityList: List<CourseAssignmentSubmission>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.casUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(522)
        it.casUid = _newPk
        _generatedPks += _newPk
      }
      it.casTimestamp = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  override fun getAllSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long):
      PagingSource<Int, CourseAssignmentSubmission> =
      _dao.getAllSubmissionsFromSubmitter(assignmentUid, submitterUid)

  override fun getAllSubmissionsForUser(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmission>> = _dao.getAllSubmissionsForUser(accountPersonUid,
      assignmentUid)

  override fun getAllSubmissionsFromSubmitterAsFlow(submitterUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmission>> =
      _dao.getAllSubmissionsFromSubmitterAsFlow(submitterUid, assignmentUid)

  override suspend fun getLatestSubmissionForUserAsync(accountPersonUid: Long, assignmentUid: Long):
      CourseAssignmentSubmission? = _dao.getLatestSubmissionForUserAsync(accountPersonUid,
      assignmentUid)

  override suspend fun doesUserHaveSubmissions(accountPersonUid: Long, assignmentUid: Long): Boolean
      = _dao.doesUserHaveSubmissions(accountPersonUid, assignmentUid)

  override suspend fun countFileSubmissionFromStudent(assignmentUid: Long, submitterUid: Long): Int
      = _dao.countFileSubmissionFromStudent(assignmentUid, submitterUid)

  override suspend fun countSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long): Int =
      _dao.countSubmissionsFromSubmitter(assignmentUid, submitterUid)

  override fun getStatusOfAssignmentForSubmitter(assignmentUid: Long, submitterUid: Long): Flow<Int>
      = _dao.getStatusOfAssignmentForSubmitter(assignmentUid, submitterUid)

  override suspend fun findLastSubmissionFromStudent(submitterUid: Long, assignmentUid: Long):
      CourseAssignmentSubmission? = _dao.findLastSubmissionFromStudent(submitterUid, assignmentUid)

  override fun checkNoSubmissionsMade(assignmentUid: Long): Flow<Boolean> =
      _dao.checkNoSubmissionsMade(assignmentUid)

  override suspend fun checkNoSubmissionsMadeAsync(assignmentUid: Long): Boolean =
      _dao.checkNoSubmissionsMadeAsync(assignmentUid)

  override fun checkNoSubmissionsMadeFlow(assignmentUid: Long): Flow<Boolean> =
      _dao.checkNoSubmissionsMadeFlow(assignmentUid)

  override fun findByUidAsFlow(submissionUid: Long): Flow<CourseAssignmentSubmission?> =
      _dao.findByUidAsFlow(submissionUid)

  override fun findByAssignmentUidAndAccountPersonUid(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmission>> =
      _dao.findByAssignmentUidAndAccountPersonUid(accountPersonUid, assignmentUid)

  public override fun insert(entity: CourseAssignmentSubmission): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseAssignmentSubmission): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.casUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(522)
      entity.casUid = _newPk
    }
    entity.casTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.casUid
  }

  public override fun insertList(entityList: List<CourseAssignmentSubmission>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseAssignmentSubmission): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
