package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonGroup
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonGroupDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonGroupDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : PersonGroupDao() {
  override fun findByUid(uid: Long): PersonGroup? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): PersonGroup? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override fun findByUidLive(uid: Long): Flow<PersonGroup?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  override suspend fun updateAsync(entity: PersonGroup): Int {
    val _result = _repo.withRepoChangeMonitorAsync("PersonGroup") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override suspend fun findNameByGroupUid(groupUid: Long): String? {
    val _result = _dao.findNameByGroupUid(groupUid)
    return _result
  }

  public override fun insert(entity: PersonGroup): Long {
    val _result = _repo.withRepoChangeMonitor("PersonGroup") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: PersonGroup): Long {
    val _result = _repo.withRepoChangeMonitorAsync("PersonGroup") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<PersonGroup>) {
    _repo.withRepoChangeMonitor("PersonGroup") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: PersonGroup) {
    _repo.withRepoChangeMonitor("PersonGroup") {
      _dao.update(entity)
    }
  }
}
