package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.ActivityInteractionEntity
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ActivityInteractionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ActivityInteractionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ActivityInteractionDao() {
  override suspend fun insertOrIgnoreAsync(entities: List<ActivityInteractionEntity>) {
    _repo.withRepoChangeMonitorAsync("ActivityInteractionEntity") {
      _dao.insertOrIgnoreAsync(entities)
    }
  }

  override suspend fun findActivityUidsWithInteractionEntitiesAsync(activityUids: List<Long>):
      List<Long> {
    val _result = _dao.findActivityUidsWithInteractionEntitiesAsync(activityUids)
    return _result
  }

  override suspend fun findAllByActivityUidAsync(activityUid: Long):
      List<ActivityInteractionEntity> {
    val _result = _dao.findAllByActivityUidAsync(activityUid)
    return _result
  }
}
