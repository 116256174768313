package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.EnrolmentRequestAndCoursePic
import com.ustadmobile.lib.db.composites.EnrolmentRequestAndPersonDetails
import com.ustadmobile.lib.db.entities.EnrolmentRequest
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class EnrolmentRequestDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: EnrolmentRequestDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : EnrolmentRequestDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun insert(enrolmentRequest: EnrolmentRequest) {
    _repo.withRepoChangeMonitorAsync("EnrolmentRequest") {
      _dao.insert(enrolmentRequest)
    }
  }

  override suspend fun findByClazzAndPerson(
    personUid: Long,
    clazzUid: Long,
    statusFilter: Int,
  ): List<EnrolmentRequest> {
    val _result = _dao.findByClazzAndPerson(personUid, clazzUid, statusFilter)
    return _result
  }

  override suspend fun hasPendingRequests(personUid: Long, clazzUid: Long): Boolean {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "EnrolmentRequestDao/hasPendingRequests") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "EnrolmentRequestDao/hasPendingRequests")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("personUid", _repo.config.json.encodeToString(Long.serializer(), personUid))
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.hasPendingRequests(personUid, clazzUid)
    return _result
  }

  override fun findRequestsForUserAsFlow(accountPersonUid: Long, statusFilter: Int):
      Flow<List<EnrolmentRequestAndCoursePic>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findRequestsForUserAsFlow(accountPersonUid, statusFilter),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "EnrolmentRequestDao/findRequestsForUserAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "EnrolmentRequestDao/findRequestsForUserAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("statusFilter", _repo.config.json.encodeToString(Int.serializer(),
              statusFilter))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun updateStatus(
    uid: Long,
    status: Int,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("EnrolmentRequest") {
      _dao.updateStatus(uid, status, updateTime)
    }
  }

  override fun findPendingEnrolmentsForCourse(
    clazzUid: Long,
    includeDeleted: Boolean,
    statusFilter: Int,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, EnrolmentRequestAndPersonDetails> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "EnrolmentRequestDao/findPendingEnrolmentsForCourse",
    dbPagingSource = _dao.findPendingEnrolmentsForCourse(clazzUid, includeDeleted, statusFilter,
        searchText, sortOrder),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "EnrolmentRequestDao/findPendingEnrolmentsForCourse") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "EnrolmentRequestDao/findPendingEnrolmentsForCourse")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
              includeDeleted))
          parameter("statusFilter", _repo.config.json.encodeToString(Int.serializer(),
              statusFilter))
          parameter("searchText", _repo.config.json.encodeToString(String.serializer(), searchText))
          parameter("sortOrder", _repo.config.json.encodeToString(Int.serializer(), sortOrder))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )
}
