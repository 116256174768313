package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Site
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SiteDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SiteDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : SiteDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override fun getSite(): Site? {
    val _result = _dao.getSite()
    return _result
  }

  override suspend fun getSiteAsync(): Site? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "SiteDao/getSiteAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "SiteDao/getSiteAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getSiteAsync()
    return _result
  }

  override suspend fun getSiteAuthSaltAsync(): String? {
    val _result = _dao.getSiteAuthSaltAsync()
    return _result
  }

  override fun getSiteAsFlow(): Flow<Site?> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.getSiteAsFlow(),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath = "SiteDao/getSiteAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "SiteDao/getSiteAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun replaceAsync(site: Site): Long {
    val _result = _repo.withRepoChangeMonitorAsync("Site") {
      _dao.replaceAsync(site)
    }
    return _result
  }

  override fun insert(site: Site): Long {
    val _result = _repo.withRepoChangeMonitor("Site") {
      _dao.insert(site)
    }
    return _result
  }

  override suspend fun updateAsync(workspace: Site) {
    _repo.withRepoChangeMonitorAsync("Site") {
      _dao.updateAsync(workspace)
    }
  }
}
