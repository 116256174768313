package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.MessageAndOtherPerson
import com.ustadmobile.lib.db.entities.Message
import kotlin.Int
import kotlin.Long
import kotlin.String

public class MessageDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: MessageDao,
) : MessageDao() {
  override fun messagesFromOtherUserAsPagingSource(accountPersonUid: Long, otherPersonUid: Long):
      PagingSource<Int, Message> = _dao.messagesFromOtherUserAsPagingSource(accountPersonUid,
      otherPersonUid)

  override fun conversationsForUserAsPagingSource(searchQuery: String, accountPersonUid: Long):
      PagingSource<Int, MessageAndOtherPerson> =
      _dao.conversationsForUserAsPagingSource(searchQuery, accountPersonUid)

  override suspend fun insert(message: Message) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(message.messageUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(126)
      message.messageUid = _newPk
    }
    message.messageLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insert(message)
  }
}
