package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ClazzLog
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ClazzLogDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ClazzLogDao,
) : ClazzLogDao() {
  override fun replace(entity: ClazzLog): Long = throw
      IllegalStateException("replace: synchronous db access is NOT possible on Javascript!")

  override fun findByUid(uid: Long): ClazzLog? = throw
      IllegalStateException("findByUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUidAsync(uid: Long): ClazzLog? = _dao.findByUidAsync(uid)

  override fun findByUidLive(uid: Long): Flow<ClazzLog?> = _dao.findByUidLive(uid)

  override fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      PagingSource<Int, ClazzLog> = _dao.findByClazzUidAsFactory(clazzUid, excludeStatus)

  override suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int): List<ClazzLog> =
      _dao.findByClazzUidAsync(clazzUid, excludeStatus)

  override suspend fun findAllForClazzByClazzLogUid(clazzLogUid: Long, excludeStatus: Int):
      List<ClazzLog> = _dao.findAllForClazzByClazzLogUid(clazzLogUid, excludeStatus)

  override suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> = _dao.findByClazzUidWithinTimeRangeAsync(clazzUid, fromTime, toTime,
      excludeStatusFilter, limit)

  override fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog> = throw
      IllegalStateException("findByClazzUidWithinTimeRange: synchronous db access is NOT possible on Javascript!")

  override fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int,
  ): Flow<List<ClazzLog>> = _dao.findByClazzUidWithinTimeRangeLive(clazzUid, fromTime, toTime,
      statusFilter)

  override fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int): Flow<Boolean> =
      _dao.clazzHasScheduleLive(clazzUid, excludeStatusFilter)

  override fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long,
  ): Unit = throw
      IllegalStateException("updateStatusByClazzLogUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun updateAsync(clazzLog: ClazzLog) {
    clazzLog.clazzLogLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(clazzLog)
  }

  override suspend fun findMostRecentClazzLogToEditUid(clazzUid: Long): Long =
      _dao.findMostRecentClazzLogToEditUid(clazzUid)

  override suspend fun upsertListAsync(entityList: List<ClazzLog>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.clazzLogUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(14)
        it.clazzLogUid = _newPk
        _generatedPks += _newPk
      }
      it.clazzLogLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entityList)
  }

  public override fun insert(entity: ClazzLog): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzLog): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.clazzLogUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(14)
      entity.clazzLogUid = _newPk
    }
    entity.clazzLogLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.clazzLogUid
  }

  public override fun insertList(entityList: List<ClazzLog>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzLog): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
