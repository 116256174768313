package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.EditAndViewPermission
import com.ustadmobile.lib.db.composites.PermissionPair
import com.ustadmobile.lib.db.entities.SystemPermission
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SystemPermissionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SystemPermissionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : SystemPermissionDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun findAllByPersonUid(accountPersonUid: Long, includeDeleted: Boolean):
      List<SystemPermission> {
    val _result = _dao.findAllByPersonUid(accountPersonUid, includeDeleted)
    return _result
  }

  override suspend fun findAllByPersonUidEntities(accountPersonUid: Long): List<SystemPermission> {
    val _result = _dao.findAllByPersonUidEntities(accountPersonUid)
    return _result
  }

  override fun findByPersonUidAsFlow(accountPersonUid: Long): Flow<SystemPermission?> =
      repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByPersonUidAsFlow(accountPersonUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "SystemPermissionDao/findByPersonUidAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "SystemPermissionDao/findByPersonUidAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun findByPersonUid(accountPersonUid: Long): SystemPermission {
    val _result = _dao.findByPersonUid(accountPersonUid)
    return _result
  }

  override suspend fun personHasSystemPermission(accountPersonUid: Long, permission: Long):
      Boolean {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "SystemPermissionDao/personHasSystemPermission") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "SystemPermissionDao/personHasSystemPermission")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("permission", _repo.config.json.encodeToString(Long.serializer(), permission))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.personHasSystemPermission(accountPersonUid, permission)
    return _result
  }

  override fun personHasSystemPermissionAsFlow(accountPersonUid: Long, permission: Long):
      Flow<Boolean> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.personHasSystemPermissionAsFlow(accountPersonUid, permission),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "SystemPermissionDao/personHasSystemPermissionAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "SystemPermissionDao/personHasSystemPermissionAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("permission", _repo.config.json.encodeToString(Long.serializer(), permission))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override fun personHasSystemPermissionPairAsFlow(
    accountPersonUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): Flow<PermissionPair> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.personHasSystemPermissionPairAsFlow(accountPersonUid, firstPermission,
        secondPermission),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "SystemPermissionDao/personHasSystemPermissionPairAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "SystemPermissionDao/personHasSystemPermissionPairAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("firstPermission", _repo.config.json.encodeToString(Long.serializer(),
              firstPermission))
          parameter("secondPermission", _repo.config.json.encodeToString(Long.serializer(),
              secondPermission))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun personHasSystemPermissionPair(
    accountPersonUid: Long,
    firstPermission: Long,
    secondPermission: Long,
  ): PermissionPair {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "SystemPermissionDao/personHasSystemPermissionPair") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "SystemPermissionDao/personHasSystemPermissionPair")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("firstPermission", _repo.config.json.encodeToString(Long.serializer(),
            firstPermission))
        parameter("secondPermission", _repo.config.json.encodeToString(Long.serializer(),
            secondPermission))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.personHasSystemPermissionPair(accountPersonUid, firstPermission,
        secondPermission)
    return _result
  }

  override fun personHasEditAndViewPermissionForPersonAsFlow(accountPersonUid: Long,
      otherPersonUid: Long): Flow<EditAndViewPermission> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.personHasEditAndViewPermissionForPersonAsFlow(accountPersonUid, otherPersonUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "SystemPermissionDao/personHasEditAndViewPermissionForPersonAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "SystemPermissionDao/personHasEditAndViewPermissionForPersonAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("otherPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              otherPersonUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun upsertAsync(systemPermissions: SystemPermission) {
    _repo.withRepoChangeMonitorAsync("SystemPermission") {
      _dao.upsertAsync(systemPermissions)
    }
  }
}
