package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.composites.EnrolmentRequestAndCoursePic
import com.ustadmobile.lib.db.composites.EnrolmentRequestAndPersonDetails
import com.ustadmobile.lib.db.entities.EnrolmentRequest
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class EnrolmentRequestDao {
  public actual abstract suspend fun insert(enrolmentRequest: EnrolmentRequest)

  public actual abstract suspend fun findByClazzAndPerson(
    personUid: Long,
    clazzUid: Long,
    statusFilter: Int,
  ): List<EnrolmentRequest>

  public actual abstract suspend fun hasPendingRequests(personUid: Long, clazzUid: Long): Boolean

  public actual abstract fun findRequestsForUserAsFlow(accountPersonUid: Long, statusFilter: Int):
      Flow<List<EnrolmentRequestAndCoursePic>>

  public actual abstract suspend fun updateStatus(
    uid: Long,
    status: Int,
    updateTime: Long,
  )

  public actual abstract fun findPendingEnrolmentsForCourse(
    clazzUid: Long,
    includeDeleted: Boolean,
    statusFilter: Int,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, EnrolmentRequestAndPersonDetails>
}
