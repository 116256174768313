package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.CourseBlockAndAssignment
import com.ustadmobile.lib.db.composites.CourseBlockAndDbEntities
import com.ustadmobile.lib.db.composites.CourseBlockAndDisplayDetails
import com.ustadmobile.lib.db.composites.CourseBlockAndGradebookDisplayDetails
import com.ustadmobile.lib.db.composites.CourseBlockAndPicture
import com.ustadmobile.lib.db.composites.CourseBlockUidAndClazzUid
import com.ustadmobile.lib.db.entities.CourseBlock
import kotlin.Boolean
import kotlin.Float
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CourseBlockDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseBlockDao,
) : CourseBlockDao() {
  override suspend fun findByUidAsync(uid: Long): CourseBlock? = _dao.findByUidAsync(uid)

  override suspend fun existsByUid(cbUid: Long): Boolean = _dao.existsByUid(cbUid)

  override suspend fun updateAsync(entity: CourseBlock): Int {
    entity.cbLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override suspend fun replaceListAsync(list: List<CourseBlock>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      if(it.cbUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(124)
        it.cbUid = _newPk
        _generatedPks += _newPk
      }
      it.cbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceListAsync(list)
  }

  override fun findByUidAsyncAsFlow(uid: Long): Flow<CourseBlock?> = _dao.findByUidAsyncAsFlow(uid)

  override fun findByUidWithPictureAsFlow(uid: Long): Flow<CourseBlockAndPicture?> =
      _dao.findByUidWithPictureAsFlow(uid)

  override suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long, includeInactive: Boolean):
      List<CourseBlockAndDbEntities> = _dao.findAllCourseBlockByClazzUidAsync(clazzUid,
      includeInactive)

  override fun findAllCourseBlockByClazzUidAsFlow(
    clazzUid: Long,
    includeInactive: Boolean,
    includeHidden: Boolean,
    hideUntilFilterTime: Long,
    accountPersonUid: Long,
  ): Flow<List<CourseBlockAndDisplayDetails>> = _dao.findAllCourseBlockByClazzUidAsFlow(clazzUid,
      includeInactive, includeHidden, hideUntilFilterTime, accountPersonUid)

  override fun findAllCourseBlockByClazzUidAsPagingSource(
    clazzUid: Long,
    collapseList: List<Long>,
    includeInactive: Boolean,
    includeHidden: Boolean,
    hideUntilFilterTime: Long,
    accountPersonUid: Long,
  ): PagingSource<Int, CourseBlockAndDisplayDetails> =
      _dao.findAllCourseBlockByClazzUidAsPagingSource(clazzUid, collapseList, includeInactive,
      includeHidden, hideUntilFilterTime, accountPersonUid)

  override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  override suspend fun upsertListAsync(entities: List<CourseBlock>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      if(it.cbUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(124)
        it.cbUid = _newPk
        _generatedPks += _newPk
      }
      it.cbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entities)
  }

  override fun getTitleByAssignmentUid(assignmentUid: Long): Flow<String?> =
      _dao.getTitleByAssignmentUid(assignmentUid)

  override fun findByUidAsFlow(courseBlockUid: Long): Flow<CourseBlock?> =
      _dao.findByUidAsFlow(courseBlockUid)

  override suspend fun findCourseBlockAndClazzUidByDiscussionPostUid(postUid: Long):
      CourseBlockUidAndClazzUid? = _dao.findCourseBlockAndClazzUidByDiscussionPostUid(postUid)

  override suspend fun findCourseBlockByDiscussionPostUid(postUid: Long): CourseBlock? =
      _dao.findCourseBlockByDiscussionPostUid(postUid)

  override suspend fun findClazzUidByCourseBlockUid(courseBlockUid: Long): Long =
      _dao.findClazzUidByCourseBlockUid(courseBlockUid)

  override fun findCourseBlockByAssignmentUid(assignmentUid: Long): Flow<CourseBlockAndAssignment?>
      = _dao.findCourseBlockByAssignmentUid(assignmentUid)

  override suspend fun findBySourcedId(sourcedId: String, accountPersonUid: Long): CourseBlock? =
      _dao.findBySourcedId(sourcedId, accountPersonUid)

  override suspend fun findByClazzUid(clazzUid: Long): List<CourseBlock> =
      _dao.findByClazzUid(clazzUid)

  override fun findByClazzUidAsFlow(clazzUid: Long):
      Flow<List<CourseBlockAndGradebookDisplayDetails>> = _dao.findByClazzUidAsFlow(clazzUid)

  override suspend fun updateFromLineItem(
    cbUid: Long,
    active: Boolean,
    dateLastModified: Long,
    title: String,
    description: String,
    assignDate: Long,
    dueDate: Long,
    resultValueMin: Float,
    resultValueMax: Float,
  ) {
    _dao.updateFromLineItem(cbUid, active, dateLastModified, title, description, assignDate,
        dueDate, resultValueMin, resultValueMax)
  }

  override suspend fun findCourseBlockAndClazzUidByCbUid(cbUid: Long, accountPersonUid: Long):
      CourseBlockUidAndClazzUid? = _dao.findCourseBlockAndClazzUidByCbUid(cbUid, accountPersonUid)

  public override fun insert(entity: CourseBlock): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseBlock): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cbUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(124)
      entity.cbUid = _newPk
    }
    entity.cbLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cbUid
  }

  public override fun insertList(entityList: List<CourseBlock>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseBlock): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertListAsync(entityList: List<CourseBlock>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.cbUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(124)
        it.cbUid = _newPk
        _generatedPks += _newPk
      }
      it.cbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CourseBlock>) {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.cbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
