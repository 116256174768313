package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseBlockPicture
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class CourseBlockPictureDao_JdbcImpl(
  public val _db: RoomDatabase,
) : CourseBlockPictureDao() {
  public val _insertAdapterCourseBlockPicture_upsert: EntityInsertionAdapter<CourseBlockPicture> =
      object : EntityInsertionAdapter<CourseBlockPicture>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO CourseBlockPicture (cbpUid, cbpLct, cbpPictureUri, cbpThumbnailUri) VALUES(?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseBlockPicture) {
      stmt.setLong(1, entity.cbpUid)
      stmt.setLong(2, entity.cbpLct)
      stmt.setString(3, entity.cbpPictureUri)
      stmt.setString(4, entity.cbpThumbnailUri)
    }
  }

  override suspend fun upsertListAsync(entities: List<CourseBlockPicture>) {
    _insertAdapterCourseBlockPicture_upsert.insertListAsync(entities)
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE CourseBlockPicture
      |           SET cbpPictureUri = ?,
      |               cbpThumbnailUri = ?,
      |               cbpLct = CAST(? AS BIGINT)
      |         WHERE cbpUid = CAST(? AS BIGINT)  
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE CourseBlockPicture
      |           SET cbpPictureUri = ?,
      |               cbpThumbnailUri = ?,
      |               cbpLct = ?
      |         WHERE cbpUid = ?  
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setString(1,uri)
      _stmt.setString(2,thumbnailUri)
      _stmt.setLong(3,time)
      _stmt.setLong(4,uid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
