package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndAndTerminology
import kotlin.Unit

public actual
    fun ClazzWithHolidayCalendarAndAndTerminology.shallowCopy(block: ClazzWithHolidayCalendarAndAndTerminology.() -> Unit):
    ClazzWithHolidayCalendarAndAndTerminology = ClazzWithHolidayCalendarAndAndTerminology().also {
  it.holidayCalendar = this.holidayCalendar
  it.terminology = this.terminology
  it.coursePicture = this.coursePicture
  it.clazzUid = this.clazzUid
  it.clazzName = this.clazzName
  it.clazzDesc = this.clazzDesc
  it.attendanceAverage = this.attendanceAverage
  it.clazzHolidayUMCalendarUid = this.clazzHolidayUMCalendarUid
  it.clazzScheuleUMCalendarUid = this.clazzScheuleUMCalendarUid
  it.isClazzActive = this.isClazzActive
  it.clazzLocationUid = this.clazzLocationUid
  it.clazzStartTime = this.clazzStartTime
  it.clazzEndTime = this.clazzEndTime
  it.clazzFeatures = this.clazzFeatures
  it.clazzSchoolUid = this.clazzSchoolUid
  it.clazzEnrolmentPolicy = this.clazzEnrolmentPolicy
  it.clazzTerminologyUid = this.clazzTerminologyUid
  it.clazzMasterChangeSeqNum = this.clazzMasterChangeSeqNum
  it.clazzLocalChangeSeqNum = this.clazzLocalChangeSeqNum
  it.clazzLastChangedBy = this.clazzLastChangedBy
  it.clazzLct = this.clazzLct
  it.clazzTimeZone = this.clazzTimeZone
  it.clazzStudentsPersonGroupUid = this.clazzStudentsPersonGroupUid
  it.clazzTeachersPersonGroupUid = this.clazzTeachersPersonGroupUid
  it.clazzPendingStudentsPersonGroupUid = this.clazzPendingStudentsPersonGroupUid
  it.clazzParentsPersonGroupUid = this.clazzParentsPersonGroupUid
  it.clazzCode = this.clazzCode
  it.clazzOwnerPersonUid = this.clazzOwnerPersonUid
  block(it)
}
