package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryVersion
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryVersionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryVersionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentEntryVersionDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun findByUidAsync(cevUid: Long): ContentEntryVersion? {
    val _result = _dao.findByUidAsync(cevUid)
    return _result
  }

  override suspend fun insertAsync(contentEntryVersion: ContentEntryVersion): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ContentEntryVersion") {
      _dao.insertAsync(contentEntryVersion)
    }
    return _result
  }

  override suspend fun findLatestVersionUidByContentEntryUidEntity(contentEntryUid: Long):
      ContentEntryVersion? {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "ContentEntryVersionDao/findLatestVersionUidByContentEntryUidEntity") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config,
            "ContentEntryVersionDao/findLatestVersionUidByContentEntryUidEntity")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("contentEntryUid", _repo.config.json.encodeToString(Long.serializer(),
            contentEntryUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findLatestVersionUidByContentEntryUidEntity(contentEntryUid)
    return _result
  }

  override fun findLatestByContentEntryUidAsFlow(contentEntryUid: Long): Flow<ContentEntryVersion?>
      = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findLatestByContentEntryUidAsFlow(contentEntryUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "ContentEntryVersionDao/findLatestByContentEntryUidAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "ContentEntryVersionDao/findLatestByContentEntryUidAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("contentEntryUid", _repo.config.json.encodeToString(Long.serializer(),
              contentEntryUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun updateTransferJobItemEtag(entityUid: Long, transferJobItemUid: Int) {
    _repo.withRepoChangeMonitorAsync("TransferJobItem") {
      _dao.updateTransferJobItemEtag(entityUid, transferJobItemUid)
    }
  }

  override suspend fun findContentEntryVersionsWithoutCacheLock(): List<ContentEntryVersion> {
    val _result = _dao.findContentEntryVersionsWithoutCacheLock()
    return _result
  }
}
