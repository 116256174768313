package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.CourseGroupMember
import kotlin.Unit

public actual fun CourseGroupMember.shallowCopy(block: CourseGroupMember.() -> Unit):
    CourseGroupMember = CourseGroupMember().also {
  it.cgmUid = this.cgmUid
  it.cgmSetUid = this.cgmSetUid
  it.cgmGroupNumber = this.cgmGroupNumber
  it.cgmPersonUid = this.cgmPersonUid
  it.cgmLct = this.cgmLct
  block(it)
}
