package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.PeerReviewerAllocation
import kotlin.Boolean
import kotlin.Long
import kotlin.collections.List

public actual abstract class PeerReviewerAllocationDao : BaseDao<PeerReviewerAllocation> {
  public actual abstract suspend fun replaceListAsync(entries: List<PeerReviewerAllocation>)

  public actual abstract suspend fun getAllPeerReviewerAllocations(assignmentUid: List<Long>):
      List<PeerReviewerAllocation>

  public actual abstract suspend fun getAllPeerReviewerAllocationsByClazzUid(clazzUid: Long,
      includeInactive: Boolean): List<PeerReviewerAllocation>

  public actual abstract suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  )

  public actual abstract suspend fun upsertList(entityList: List<PeerReviewerAllocation>)
}
