package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.CourseAssignmentSubmissionFileAndTransferJob
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionFile
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseAssignmentSubmissionFileDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseAssignmentSubmissionFileDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseAssignmentSubmissionFileDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun insertListAsync(entityList: List<CourseAssignmentSubmissionFile>) {
    _repo.withRepoChangeMonitorAsync("CourseAssignmentSubmissionFile") {
      _dao.insertListAsync(entityList)
    }
  }

  override fun getBySubmissionUid(submissionUid: Long):
      Flow<List<CourseAssignmentSubmissionFileAndTransferJob>> {
    val _result = _dao.getBySubmissionUid(submissionUid)
    return _result
  }

  override fun getByAssignmentUidAndPersonUid(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmissionFileAndTransferJob>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.getByAssignmentUidAndPersonUid(accountPersonUid, assignmentUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseAssignmentSubmissionFileDao/getByAssignmentUidAndPersonUid") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "CourseAssignmentSubmissionFileDao/getByAssignmentUidAndPersonUid")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override fun getAllSubmissionFilesFromSubmitterAsFlow(submitterUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmissionFileAndTransferJob>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.getAllSubmissionFilesFromSubmitterAsFlow(submitterUid, assignmentUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseAssignmentSubmissionFileDao/getAllSubmissionFilesFromSubmitterAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "CourseAssignmentSubmissionFileDao/getAllSubmissionFilesFromSubmitterAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("submitterUid", _repo.config.json.encodeToString(Long.serializer(),
              submitterUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun updateUri(
    casaUid: Long,
    uri: String,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("CourseAssignmentSubmissionFile") {
      _dao.updateUri(casaUid, uri, updateTime)
    }
  }

  override suspend fun setDeleted(
    casaUid: Long,
    deleted: Boolean,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("CourseAssignmentSubmissionFile") {
      _dao.setDeleted(casaUid, deleted, updateTime)
    }
  }

  public override fun insert(entity: CourseAssignmentSubmissionFile): Long {
    val _result = _repo.withRepoChangeMonitor("CourseAssignmentSubmissionFile") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: CourseAssignmentSubmissionFile): Long {
    val _result = _repo.withRepoChangeMonitorAsync("CourseAssignmentSubmissionFile") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<CourseAssignmentSubmissionFile>) {
    _repo.withRepoChangeMonitor("CourseAssignmentSubmissionFile") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: CourseAssignmentSubmissionFile) {
    _repo.withRepoChangeMonitor("CourseAssignmentSubmissionFile") {
      _dao.update(entity)
    }
  }
}
