package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ScheduleDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ScheduleDao,
) : ScheduleDao() {
  override fun insert(entity: Schedule): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  override suspend fun updateAsync(entity: Schedule): Int {
    entity.scheduleLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override suspend fun upsertListAsync(entities: List<Schedule>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      if(it.scheduleUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(21)
        it.scheduleUid = _newPk
        _generatedPks += _newPk
      }
      it.scheduleLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entities)
  }

  override suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _dao.updateScheduleActivated(scheduleUid, active, changeTime)
  }

  override fun findByUid(uid: Long): Schedule? = throw
      IllegalStateException("findByUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUidAsync(uid: Long): Schedule? = _dao.findByUidAsync(uid)

  override fun findAllSchedulesByClazzUid(clazzUid: Long): PagingSource<Int, Schedule> =
      _dao.findAllSchedulesByClazzUid(clazzUid)

  override fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule> = throw
      IllegalStateException("findAllSchedulesByClazzUidAsList: synchronous db access is NOT possible on Javascript!")

  override fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long): Flow<List<Schedule>> =
      _dao.findAllSchedulesByClazzUidAsLiveList(clazzUid)

  override suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule> =
      _dao.findAllSchedulesByClazzUidAsync(clazzUid)

  public override suspend fun insertAsync(entity: Schedule): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.scheduleUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(21)
      entity.scheduleUid = _newPk
    }
    entity.scheduleLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.scheduleUid
  }

  public override fun insertList(entityList: List<Schedule>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Schedule): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
