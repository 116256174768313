package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.VerbLangMapEntry
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class VerbLangMapEntryDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: VerbLangMapEntryDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : VerbLangMapEntryDao() {
  override suspend fun upsertList(list: List<VerbLangMapEntry>) {
    _repo.withRepoChangeMonitorAsync("VerbLangMapEntry") {
      _dao.upsertList(list)
    }
  }

  override suspend fun findByVerbUidAsync(verbUid: Long): List<VerbLangMapEntry> {
    val _result = _dao.findByVerbUidAsync(verbUid)
    return _result
  }
}
