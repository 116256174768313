package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseTerminology
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseTerminologyDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseTerminologyDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseTerminologyDao() {
  override fun findAllCourseTerminologyPagingSource(): PagingSource<Int, CourseTerminology> =
      DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "CourseTerminologyDao/findAllCourseTerminologyPagingSource",
    dbPagingSource = _dao.findAllCourseTerminologyPagingSource(),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "CourseTerminologyDao/findAllCourseTerminologyPagingSource") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseTerminologyDao/findAllCourseTerminologyPagingSource")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override fun findAllCourseTerminologyList(): List<CourseTerminology> {
    val _result = _dao.findAllCourseTerminologyList()
    return _result
  }

  override suspend fun getTerminologyForClazz(clazzUid: Long): CourseTerminology? {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "CourseTerminologyDao/getTerminologyForClazz") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "CourseTerminologyDao/getTerminologyForClazz")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getTerminologyForClazz(clazzUid)
    return _result
  }

  override suspend fun getTerminologyForAssignment(assignmentUid: Long): CourseTerminology? {
    val _result = _dao.getTerminologyForAssignment(assignmentUid)
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): CourseTerminology? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "CourseTerminologyDao/findByUidAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "CourseTerminologyDao/findByUidAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override suspend fun updateAsync(entity: CourseTerminology): Int {
    val _result = _repo.withRepoChangeMonitorAsync("CourseTerminology") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override suspend fun upsertAsync(entity: CourseTerminology): Long {
    val _result = _repo.withRepoChangeMonitorAsync("CourseTerminology") {
      _dao.upsertAsync(entity)
    }
    return _result
  }

  public override fun insert(entity: CourseTerminology): Long {
    val _result = _repo.withRepoChangeMonitor("CourseTerminology") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: CourseTerminology): Long {
    val _result = _repo.withRepoChangeMonitorAsync("CourseTerminology") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<CourseTerminology>) {
    _repo.withRepoChangeMonitor("CourseTerminology") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: CourseTerminology) {
    _repo.withRepoChangeMonitor("CourseTerminology") {
      _dao.update(entity)
    }
  }
}
