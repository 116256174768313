package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.CommentsAndName
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.Person
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CommentsDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CommentsDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CommentsDao() {
  override suspend fun insertAsync(comments: Comments): Long {
    val _result = _repo.withRepoChangeMonitorAsync("Comments") {
      _dao.insertAsync(comments)
    }
    return _result
  }

  override suspend fun updateDeletedByCommentUid(
    uid: Long,
    deleted: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("Comments") {
      _dao.updateDeletedByCommentUid(uid, deleted, changeTime)
    }
  }

  override fun findPrivateCommentsForUserByAssignmentUid(
    accountPersonUid: Long,
    assignmentUid: Long,
    includeDeleted: Boolean,
  ): PagingSource<Int, CommentsAndName> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "CommentsDao/findPrivateCommentsForUserByAssignmentUid",
    dbPagingSource = _dao.findPrivateCommentsForUserByAssignmentUid(accountPersonUid, assignmentUid,
        includeDeleted),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "CommentsDao/findPrivateCommentsForUserByAssignmentUid") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CommentsDao/findPrivateCommentsForUserByAssignmentUid")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
          parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
              includeDeleted))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun findPrivateCommentsForUserByAssignmentUidPersons(accountPersonUid: Long,
      assignmentUid: Long): List<PersonAndPicture> {
    val _result = _dao.findPrivateCommentsForUserByAssignmentUidPersons(accountPersonUid,
        assignmentUid)
    return _result
  }

  override fun findPrivateCommentsForSubmitterByAssignmentUid(
    submitterUid: Long,
    assignmentUid: Long,
    includeDeleted: Boolean,
  ): PagingSource<Int, CommentsAndName> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "CommentsDao/findPrivateCommentsForSubmitterByAssignmentUid",
    dbPagingSource = _dao.findPrivateCommentsForSubmitterByAssignmentUid(submitterUid,
        assignmentUid, includeDeleted),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "CommentsDao/findPrivateCommentsForSubmitterByAssignmentUid") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CommentsDao/findPrivateCommentsForSubmitterByAssignmentUid")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("submitterUid", _repo.config.json.encodeToString(Long.serializer(),
              submitterUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
          parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
              includeDeleted))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override fun findPrivateCommentsForSubmitterByAssignmentUidPersons(submitterUid: Long,
      assignmentUid: Long): List<PersonAndPicture> {
    val _result = _dao.findPrivateCommentsForSubmitterByAssignmentUidPersons(submitterUid,
        assignmentUid)
    return _result
  }

  override fun findCourseCommentsByAssignmentUid(assignmentUid: Long, includeDeleted: Boolean):
      PagingSource<Int, CommentsAndName> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "CommentsDao/findCourseCommentsByAssignmentUid",
    dbPagingSource = _dao.findCourseCommentsByAssignmentUid(assignmentUid, includeDeleted),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "CommentsDao/findCourseCommentsByAssignmentUid") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CommentsDao/findCourseCommentsByAssignmentUid")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
          parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
              includeDeleted))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun findCourseCommentsByAssignmentUidPersons(assignmentUid: Long): List<Person> {
    val _result = _dao.findCourseCommentsByAssignmentUidPersons(assignmentUid)
    return _result
  }
}
