package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryParentChildJoinDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryParentChildJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentEntryParentChildJoinDao() {
  override suspend fun insertListAsync(entityList: List<ContentEntryParentChildJoin>) {
    _repo.withRepoChangeMonitorAsync("ContentEntryParentChildJoin") {
      _dao.insertListAsync(entityList)
    }
  }

  override fun publicContentEntryParentChildJoins(): List<ContentEntryParentChildJoin> {
    val _result = _dao.publicContentEntryParentChildJoins()
    return _result
  }

  override fun all(): List<ContentEntryParentChildJoin> {
    val _result = _dao.all()
    return _result
  }

  override fun findParentByChildUuids(childEntryContentUid: Long): ContentEntryParentChildJoin? {
    val _result = _dao.findParentByChildUuids(childEntryContentUid)
    return _result
  }

  override fun findListOfParentsByChildUuid(childEntryContentUid: Long):
      List<ContentEntryParentChildJoin> {
    val _result = _dao.findListOfParentsByChildUuid(childEntryContentUid)
    return _result
  }

  override suspend fun findListOfChildsByParentUuid(parentUid: Long):
      List<ContentEntryParentChildJoin> {
    val _result = _dao.findListOfChildsByParentUuid(parentUid)
    return _result
  }

  override fun findJoinByParentChildUuids(parentUid: Long, childUid: Long):
      ContentEntryParentChildJoin? {
    val _result = _dao.findJoinByParentChildUuids(parentUid, childUid)
    return _result
  }

  override suspend fun selectTopEntries(): List<ContentEntry> {
    val _result = _dao.selectTopEntries()
    return _result
  }

  override fun replaceList(entries: List<ContentEntryParentChildJoin>) {
    _repo.withRepoChangeMonitor("ContentEntryParentChildJoin") {
      _dao.replaceList(entries)
    }
  }

  override fun insertWithReplace(parentChildJoinDao: ContentEntryParentChildJoin) {
    _repo.withRepoChangeMonitor("ContentEntryParentChildJoin") {
      _dao.insertWithReplace(parentChildJoinDao)
    }
  }

  override suspend fun moveListOfEntriesToNewParent(
    contentEntryUid: Long,
    selectedItems: List<Long>,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ContentEntryParentChildJoin") {
      _dao.moveListOfEntriesToNewParent(contentEntryUid, selectedItems, updateTime)
    }
  }

  override suspend fun setEntriesDeleted(
    selectedUids: List<Long>,
    isDeleted: Boolean,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ContentEntryParentChildJoin") {
      _dao.setEntriesDeleted(selectedUids, isDeleted, updateTime)
    }
  }

  override suspend fun findByUid(uid: Long): ContentEntryParentChildJoin? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override fun insert(entity: ContentEntryParentChildJoin): Long {
    val _result = _repo.withRepoChangeMonitor("ContentEntryParentChildJoin") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ContentEntryParentChildJoin): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ContentEntryParentChildJoin") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ContentEntryParentChildJoin>) {
    _repo.withRepoChangeMonitor("ContentEntryParentChildJoin") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: ContentEntryParentChildJoin) {
    _repo.withRepoChangeMonitor("ContentEntryParentChildJoin") {
      _dao.update(entity)
    }
  }
}
