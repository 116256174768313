package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin
import kotlin.Long
import kotlin.collections.List

public actual abstract class ContentEntryContentCategoryJoinDao :
    BaseDao<ContentEntryContentCategoryJoin> {
  public actual abstract fun publicContentEntryContentCategoryJoins():
      List<ContentEntryContentCategoryJoin>

  public actual abstract fun findJoinByParentChildUuids(categoryUid: Long, contentEntry: Long):
      ContentEntryContentCategoryJoin?
}
