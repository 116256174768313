package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.DiscussionPost
import kotlin.Unit

public actual fun DiscussionPost.shallowCopy(block: DiscussionPost.() -> Unit): DiscussionPost =
    DiscussionPost().also {
  it.discussionPostUid = this.discussionPostUid
  it.discussionPostReplyToPostUid = this.discussionPostReplyToPostUid
  it.discussionPostTitle = this.discussionPostTitle
  it.discussionPostMessage = this.discussionPostMessage
  it.discussionPostStartDate = this.discussionPostStartDate
  it.discussionPostCourseBlockUid = this.discussionPostCourseBlockUid
  it.dpDeleted = this.dpDeleted
  it.discussionPostStartedPersonUid = this.discussionPostStartedPersonUid
  it.discussionPostClazzUid = this.discussionPostClazzUid
  it.discussionPostLct = this.discussionPostLct
  it.discussionPostVisible = this.discussionPostVisible
  it.discussionPostArchive = this.discussionPostArchive
  block(it)
}
