package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.CourseAssignmentMarkAndMarkerName
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.AverageCourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseAssignmentMarkWithPersonMarker
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseAssignmentMarkDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseAssignmentMarkDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseAssignmentMarkDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override fun checkNoSubmissionsMarked(assignmentUid: Long): Flow<Boolean> {
    val _result = _dao.checkNoSubmissionsMarked(assignmentUid)
    return _result
  }

  override fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long, submitterUid: Long):
      Flow<AverageCourseAssignmentMark?> {
    val _result = _dao.getMarkOfAssignmentForSubmitterLiveData(assignmentUid, submitterUid)
    return _result
  }

  override fun getAllMarksForUserAsFlow(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentMarkAndMarkerName>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.getAllMarksForUserAsFlow(accountPersonUid, assignmentUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseAssignmentMarkDao/getAllMarksForUserAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseAssignmentMarkDao/getAllMarksForUserAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override fun getAllMarksForSubmitterAsFlow(submitterUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentMarkAndMarkerName>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.getAllMarksForSubmitterAsFlow(submitterUid, assignmentUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseAssignmentMarkDao/getAllMarksForSubmitterAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseAssignmentMarkDao/getAllMarksForSubmitterAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("submitterUid", _repo.config.json.encodeToString(Long.serializer(),
              submitterUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun getAllMarksForSubmitterAsFlowMarkerPersons(submitterUid: Long,
      assignmentUid: Long): List<PersonAndPicture> {
    val _result = _dao.getAllMarksForSubmitterAsFlowMarkerPersons(submitterUid, assignmentUid)
    return _result
  }

  override fun getAllMarksOfAssignmentForSubmitter(
    assignmentUid: Long,
    submitterUid: Long,
    filter: Int,
  ): PagingSource<Int, CourseAssignmentMarkWithPersonMarker> {
    val _result = _dao.getAllMarksOfAssignmentForSubmitter(assignmentUid, submitterUid, filter)
    return _result
  }

  override fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark? {
    val _result = _dao.getMarkOfAssignmentForStudent(assignmentUid, submitterUid)
    return _result
  }

  override suspend fun findNextSubmitterToMarkForAssignment(
    assignmentUid: Long,
    submitterUid: Long,
    markerUid: Long,
  ): Long {
    val _result = _dao.findNextSubmitterToMarkForAssignment(assignmentUid, submitterUid, markerUid)
    return _result
  }

  public override fun insert(entity: CourseAssignmentMark): Long {
    val _result = _repo.withRepoChangeMonitor("CourseAssignmentMark") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: CourseAssignmentMark): Long {
    val _result = _repo.withRepoChangeMonitorAsync("CourseAssignmentMark") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<CourseAssignmentMark>) {
    _repo.withRepoChangeMonitor("CourseAssignmentMark") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: CourseAssignmentMark) {
    _repo.withRepoChangeMonitor("CourseAssignmentMark") {
      _dao.update(entity)
    }
  }
}
