package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.getFloatNullable
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.setFloatNullable
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.paging.DoorLimitOffsetPagingSource
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.CourseBlockAndAssignment
import com.ustadmobile.lib.db.composites.CourseBlockAndDbEntities
import com.ustadmobile.lib.db.composites.CourseBlockAndDisplayDetails
import com.ustadmobile.lib.db.composites.CourseBlockAndGradebookDisplayDetails
import com.ustadmobile.lib.db.composites.CourseBlockAndPicture
import com.ustadmobile.lib.db.composites.CourseBlockUidAndClazzUid
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryPicture2
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.CourseBlockPicture
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.Float
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CourseBlockDao_JdbcImpl(
  public val _db: RoomDatabase,
) : CourseBlockDao() {
  public val _insertAdapterCourseBlock_upsert: EntityInsertionAdapter<CourseBlock> = object :
      EntityInsertionAdapter<CourseBlock>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbClazzSourcedId, cbActive, cbHidden, cbEntityUid, cbLct, cbSourcedId, cbMetadata, cbCreatedByAppId) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseBlock) {
      if(entity.cbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cbUid)
      }
      stmt.setInt(2, entity.cbType)
      stmt.setInt(3, entity.cbIndentLevel)
      stmt.setLong(4, entity.cbModuleParentBlockUid)
      stmt.setString(5, entity.cbTitle)
      stmt.setString(6, entity.cbDescription)
      stmt.setInt(7, entity.cbCompletionCriteria)
      stmt.setLong(8, entity.cbHideUntilDate)
      stmt.setLong(9, entity.cbDeadlineDate)
      stmt.setInt(10, entity.cbLateSubmissionPenalty)
      stmt.setLong(11, entity.cbGracePeriodDate)
      stmt.setFloatNullable(12, entity.cbMaxPoints)
      stmt.setFloatNullable(13, entity.cbMinPoints)
      stmt.setInt(14, entity.cbIndex)
      stmt.setLong(15, entity.cbClazzUid)
      stmt.setString(16, entity.cbClazzSourcedId)
      stmt.setBoolean(17, entity.cbActive)
      stmt.setBoolean(18, entity.cbHidden)
      stmt.setLong(19, entity.cbEntityUid)
      stmt.setLong(20, entity.cbLct)
      stmt.setString(21, entity.cbSourcedId)
      stmt.setString(22, entity.cbMetadata)
      stmt.setString(23, entity.cbCreatedByAppId)
    }
  }

  public val _insertAdapterCourseBlock_abort: EntityInsertionAdapter<CourseBlock> = object :
      EntityInsertionAdapter<CourseBlock>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbClazzSourcedId, cbActive, cbHidden, cbEntityUid, cbLct, cbSourcedId, cbMetadata, cbCreatedByAppId) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseBlock) {
      if(entity.cbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cbUid)
      }
      stmt.setInt(2, entity.cbType)
      stmt.setInt(3, entity.cbIndentLevel)
      stmt.setLong(4, entity.cbModuleParentBlockUid)
      stmt.setString(5, entity.cbTitle)
      stmt.setString(6, entity.cbDescription)
      stmt.setInt(7, entity.cbCompletionCriteria)
      stmt.setLong(8, entity.cbHideUntilDate)
      stmt.setLong(9, entity.cbDeadlineDate)
      stmt.setInt(10, entity.cbLateSubmissionPenalty)
      stmt.setLong(11, entity.cbGracePeriodDate)
      stmt.setFloatNullable(12, entity.cbMaxPoints)
      stmt.setFloatNullable(13, entity.cbMinPoints)
      stmt.setInt(14, entity.cbIndex)
      stmt.setLong(15, entity.cbClazzUid)
      stmt.setString(16, entity.cbClazzSourcedId)
      stmt.setBoolean(17, entity.cbActive)
      stmt.setBoolean(18, entity.cbHidden)
      stmt.setLong(19, entity.cbEntityUid)
      stmt.setLong(20, entity.cbLct)
      stmt.setString(21, entity.cbSourcedId)
      stmt.setString(22, entity.cbMetadata)
      stmt.setString(23, entity.cbCreatedByAppId)
    }
  }

  override suspend fun replaceListAsync(list: List<CourseBlock>) {
    _insertAdapterCourseBlock_upsert.insertListAsync(list)
  }

  override suspend fun upsertListAsync(entities: List<CourseBlock>) {
    _insertAdapterCourseBlock_upsert.insertListAsync(entities)
  }

  public override fun insert(entity: CourseBlock): Long {
    val _retVal = _insertAdapterCourseBlock_abort.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseBlock): Long {
    val _retVal = _insertAdapterCourseBlock_abort.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<CourseBlock>) {
    _insertAdapterCourseBlock_abort.insertList(entityList)
  }

  public override suspend fun insertListAsync(entityList: List<CourseBlock>) {
    _insertAdapterCourseBlock_abort.insertListAsync(entityList)
  }

  override suspend fun updateAsync(entity: CourseBlock): Int {
    var _result = 0
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbClazzSourcedId = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ?, cbSourcedId = ?, cbMetadata = ?, cbCreatedByAppId = ? WHERE cbUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.cbType)
      _stmt.setInt(2, entity.cbIndentLevel)
      _stmt.setLong(3, entity.cbModuleParentBlockUid)
      _stmt.setString(4, entity.cbTitle)
      _stmt.setString(5, entity.cbDescription)
      _stmt.setInt(6, entity.cbCompletionCriteria)
      _stmt.setLong(7, entity.cbHideUntilDate)
      _stmt.setLong(8, entity.cbDeadlineDate)
      _stmt.setInt(9, entity.cbLateSubmissionPenalty)
      _stmt.setLong(10, entity.cbGracePeriodDate)
      _stmt.setFloatNullable(11, entity.cbMaxPoints)
      _stmt.setFloatNullable(12, entity.cbMinPoints)
      _stmt.setInt(13, entity.cbIndex)
      _stmt.setLong(14, entity.cbClazzUid)
      _stmt.setString(15, entity.cbClazzSourcedId)
      _stmt.setBoolean(16, entity.cbActive)
      _stmt.setBoolean(17, entity.cbHidden)
      _stmt.setLong(18, entity.cbEntityUid)
      _stmt.setLong(19, entity.cbLct)
      _stmt.setString(20, entity.cbSourcedId)
      _stmt.setString(21, entity.cbMetadata)
      _stmt.setString(22, entity.cbCreatedByAppId)
      _stmt.setLong(23, entity.cbUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun update(entity: CourseBlock) {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbClazzSourcedId = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ?, cbSourcedId = ?, cbMetadata = ?, cbCreatedByAppId = ? WHERE cbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.cbType)
      _stmt.setInt(2, entity.cbIndentLevel)
      _stmt.setLong(3, entity.cbModuleParentBlockUid)
      _stmt.setString(4, entity.cbTitle)
      _stmt.setString(5, entity.cbDescription)
      _stmt.setInt(6, entity.cbCompletionCriteria)
      _stmt.setLong(7, entity.cbHideUntilDate)
      _stmt.setLong(8, entity.cbDeadlineDate)
      _stmt.setInt(9, entity.cbLateSubmissionPenalty)
      _stmt.setLong(10, entity.cbGracePeriodDate)
      _stmt.setFloatNullable(11, entity.cbMaxPoints)
      _stmt.setFloatNullable(12, entity.cbMinPoints)
      _stmt.setInt(13, entity.cbIndex)
      _stmt.setLong(14, entity.cbClazzUid)
      _stmt.setString(15, entity.cbClazzSourcedId)
      _stmt.setBoolean(16, entity.cbActive)
      _stmt.setBoolean(17, entity.cbHidden)
      _stmt.setLong(18, entity.cbEntityUid)
      _stmt.setLong(19, entity.cbLct)
      _stmt.setString(20, entity.cbSourcedId)
      _stmt.setString(21, entity.cbMetadata)
      _stmt.setString(22, entity.cbCreatedByAppId)
      _stmt.setLong(23, entity.cbUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun updateListAsync(entityList: List<CourseBlock>) {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbClazzSourcedId = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ?, cbSourcedId = ?, cbMetadata = ?, cbCreatedByAppId = ? WHERE cbUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setInt(1, _entity.cbType)
        _stmt.setInt(2, _entity.cbIndentLevel)
        _stmt.setLong(3, _entity.cbModuleParentBlockUid)
        _stmt.setString(4, _entity.cbTitle)
        _stmt.setString(5, _entity.cbDescription)
        _stmt.setInt(6, _entity.cbCompletionCriteria)
        _stmt.setLong(7, _entity.cbHideUntilDate)
        _stmt.setLong(8, _entity.cbDeadlineDate)
        _stmt.setInt(9, _entity.cbLateSubmissionPenalty)
        _stmt.setLong(10, _entity.cbGracePeriodDate)
        _stmt.setFloatNullable(11, _entity.cbMaxPoints)
        _stmt.setFloatNullable(12, _entity.cbMinPoints)
        _stmt.setInt(13, _entity.cbIndex)
        _stmt.setLong(14, _entity.cbClazzUid)
        _stmt.setString(15, _entity.cbClazzSourcedId)
        _stmt.setBoolean(16, _entity.cbActive)
        _stmt.setBoolean(17, _entity.cbHidden)
        _stmt.setLong(18, _entity.cbEntityUid)
        _stmt.setLong(19, _entity.cbLct)
        _stmt.setString(20, _entity.cbSourcedId)
        _stmt.setString(21, _entity.cbMetadata)
        _stmt.setString(22, _entity.cbCreatedByAppId)
        _stmt.setLong(23, _entity.cbUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  override suspend fun findByUidAsync(uid: Long): CourseBlock? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM CourseBlock WHERE cbUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM CourseBlock WHERE cbUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cbUid = _result.getLong("cbUid")
        val _tmp_cbType = _result.getInt("cbType")
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        val _tmp_cbTitle = _result.getString("cbTitle")
        val _tmp_cbDescription = _result.getString("cbDescription")
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
        val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
        val _tmp_cbIndex = _result.getInt("cbIndex")
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
        val _tmp_cbActive = _result.getBoolean("cbActive")
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        val _tmp_cbLct = _result.getLong("cbLct")
        val _tmp_cbSourcedId = _result.getString("cbSourcedId")
        val _tmp_cbMetadata = _result.getString("cbMetadata")
        val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
        CourseBlock().apply {
          this.cbUid = _tmp_cbUid
          this.cbType = _tmp_cbType
          this.cbIndentLevel = _tmp_cbIndentLevel
          this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
          this.cbTitle = _tmp_cbTitle
          this.cbDescription = _tmp_cbDescription
          this.cbCompletionCriteria = _tmp_cbCompletionCriteria
          this.cbHideUntilDate = _tmp_cbHideUntilDate
          this.cbDeadlineDate = _tmp_cbDeadlineDate
          this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
          this.cbGracePeriodDate = _tmp_cbGracePeriodDate
          this.cbMaxPoints = _tmp_cbMaxPoints
          this.cbMinPoints = _tmp_cbMinPoints
          this.cbIndex = _tmp_cbIndex
          this.cbClazzUid = _tmp_cbClazzUid
          this.cbClazzSourcedId = _tmp_cbClazzSourcedId
          this.cbActive = _tmp_cbActive
          this.cbHidden = _tmp_cbHidden
          this.cbEntityUid = _tmp_cbEntityUid
          this.cbLct = _tmp_cbLct
          this.cbSourcedId = _tmp_cbSourcedId
          this.cbMetadata = _tmp_cbMetadata
          this.cbCreatedByAppId = _tmp_cbCreatedByAppId
        }
      }
    }
  }

  override suspend fun existsByUid(cbUid: Long): Boolean =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT EXISTS(
    |               SELECT CourseBlock.cbUid
    |                 FROM CourseBlock
    |                WHERE CourseBlock.cbUid = CAST(? AS BIGINT))
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT EXISTS(
    |               SELECT CourseBlock.cbUid
    |                 FROM CourseBlock
    |                WHERE CourseBlock.cbUid = ?)
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,cbUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(false) {
        _result.getBoolean(1)
      }
    }
  }

  override fun findByUidAsyncAsFlow(uid: Long): Flow<CourseBlock?> =
      _db.doorFlow(arrayOf("CourseBlock")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * FROM CourseBlock WHERE cbUid = CAST(? AS BIGINT)",
      postgreSql = """
      |SELECT * FROM CourseBlock WHERE cbUid = ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_cbUid = _result.getLong("cbUid")
          val _tmp_cbType = _result.getInt("cbType")
          val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
          val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
          val _tmp_cbTitle = _result.getString("cbTitle")
          val _tmp_cbDescription = _result.getString("cbDescription")
          val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
          val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
          val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
          val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
          val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
          val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
          val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
          val _tmp_cbIndex = _result.getInt("cbIndex")
          val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
          val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
          val _tmp_cbActive = _result.getBoolean("cbActive")
          val _tmp_cbHidden = _result.getBoolean("cbHidden")
          val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
          val _tmp_cbLct = _result.getLong("cbLct")
          val _tmp_cbSourcedId = _result.getString("cbSourcedId")
          val _tmp_cbMetadata = _result.getString("cbMetadata")
          val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
          CourseBlock().apply {
            this.cbUid = _tmp_cbUid
            this.cbType = _tmp_cbType
            this.cbIndentLevel = _tmp_cbIndentLevel
            this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
            this.cbTitle = _tmp_cbTitle
            this.cbDescription = _tmp_cbDescription
            this.cbCompletionCriteria = _tmp_cbCompletionCriteria
            this.cbHideUntilDate = _tmp_cbHideUntilDate
            this.cbDeadlineDate = _tmp_cbDeadlineDate
            this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
            this.cbGracePeriodDate = _tmp_cbGracePeriodDate
            this.cbMaxPoints = _tmp_cbMaxPoints
            this.cbMinPoints = _tmp_cbMinPoints
            this.cbIndex = _tmp_cbIndex
            this.cbClazzUid = _tmp_cbClazzUid
            this.cbClazzSourcedId = _tmp_cbClazzSourcedId
            this.cbActive = _tmp_cbActive
            this.cbHidden = _tmp_cbHidden
            this.cbEntityUid = _tmp_cbEntityUid
            this.cbLct = _tmp_cbLct
            this.cbSourcedId = _tmp_cbSourcedId
            this.cbMetadata = _tmp_cbMetadata
            this.cbCreatedByAppId = _tmp_cbCreatedByAppId
          }
        }
      }
    }
  }

  override fun findByUidWithPictureAsFlow(uid: Long): Flow<CourseBlockAndPicture?> =
      _db.doorFlow(arrayOf("CourseBlock", "CourseBlockPicture")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT CourseBlock.*, CourseBlockPicture.*
      |          FROM CourseBlock
      |               LEFT JOIN CourseBlockPicture 
      |                         ON CourseBlockPicture.cbpUid = CAST(? AS BIGINT)
      |         WHERE CourseBlock.cbUid = CAST(? AS BIGINT)                
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT CourseBlock.*, CourseBlockPicture.*
      |          FROM CourseBlock
      |               LEFT JOIN CourseBlockPicture 
      |                         ON CourseBlockPicture.cbpUid = ?
      |         WHERE CourseBlock.cbUid = ?                
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.setLong(2,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          var _tmp_CourseBlock_nullCount = 0
          val _tmp_cbUid = _result.getLong("cbUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbType = _result.getInt("cbType")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbTitle = _result.getString("cbTitle")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDescription = _result.getString("cbDescription")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndex = _result.getInt("cbIndex")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbActive = _result.getBoolean("cbActive")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHidden = _result.getBoolean("cbHidden")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLct = _result.getLong("cbLct")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbSourcedId = _result.getString("cbSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMetadata = _result.getString("cbMetadata")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 23
          var _tmp_CourseBlockPicture_nullCount = 0
          val _tmp_cbpUid = _result.getLong("cbpUid")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpLct = _result.getLong("cbpLct")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpPictureUri = _result.getString("cbpPictureUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpThumbnailUri = _result.getString("cbpThumbnailUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_CourseBlockPicture_isAllNull = _tmp_CourseBlockPicture_nullCount == 4
          CourseBlockAndPicture().apply {
            if(!_tmp_CourseBlock_isAllNull) {
              this.block = CourseBlock().apply {
                this.cbUid = _tmp_cbUid
                this.cbType = _tmp_cbType
                this.cbIndentLevel = _tmp_cbIndentLevel
                this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
                this.cbTitle = _tmp_cbTitle
                this.cbDescription = _tmp_cbDescription
                this.cbCompletionCriteria = _tmp_cbCompletionCriteria
                this.cbHideUntilDate = _tmp_cbHideUntilDate
                this.cbDeadlineDate = _tmp_cbDeadlineDate
                this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
                this.cbGracePeriodDate = _tmp_cbGracePeriodDate
                this.cbMaxPoints = _tmp_cbMaxPoints
                this.cbMinPoints = _tmp_cbMinPoints
                this.cbIndex = _tmp_cbIndex
                this.cbClazzUid = _tmp_cbClazzUid
                this.cbClazzSourcedId = _tmp_cbClazzSourcedId
                this.cbActive = _tmp_cbActive
                this.cbHidden = _tmp_cbHidden
                this.cbEntityUid = _tmp_cbEntityUid
                this.cbLct = _tmp_cbLct
                this.cbSourcedId = _tmp_cbSourcedId
                this.cbMetadata = _tmp_cbMetadata
                this.cbCreatedByAppId = _tmp_cbCreatedByAppId
              }
            }
            if(!_tmp_CourseBlockPicture_isAllNull) {
              this.picture = CourseBlockPicture().apply {
                this.cbpUid = _tmp_cbpUid
                this.cbpLct = _tmp_cbpLct
                this.cbpPictureUri = _tmp_cbpPictureUri
                this.cbpThumbnailUri = _tmp_cbpThumbnailUri
              }
            }
          }
        }
      }
    }
  }

  override suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long, includeInactive: Boolean):
      List<CourseBlockAndDbEntities> = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT CourseBlock.*, Assignment.*, Entry.*, Language.*, CourseBlockPicture.*,
    |               (SELECT CourseGroupSet.cgsName
    |                  FROM CourseGroupSet
    |                 WHERE CourseBlock.cbType = 103
    |                   AND assignment.caGroupUid != 0
    |                   AND CourseGroupSet.cgsUid = assignment.caGroupUid) AS assignmentCourseGroupSetName
    |          FROM CourseBlock 
    |               LEFT JOIN ClazzAssignment AS Assignment
    |                         ON assignment.caUid = CourseBlock.cbEntityUid
    |                            AND CourseBlock.cbType = 103
    |               LEFT JOIN ContentEntry AS Entry
    |                         ON entry.contentEntryUid = CourseBlock.cbEntityUid
    |                            AND CourseBlock.cbType = 104
    |               LEFT JOIN Language
    |                         ON Language.langUid = Entry.primaryLanguageUid
    |                            AND CourseBlock.cbType = 104
    |               LEFT JOIN CourseBlockPicture
    |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
    |         WHERE CourseBlock.cbClazzUid = CAST(? AS BIGINT)
    |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
    |           AND (CourseBlock.cbType != 300)
    |      ORDER BY CourseBlock.cbIndex
    |          
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT CourseBlock.*, Assignment.*, Entry.*, Language.*, CourseBlockPicture.*,
    |               (SELECT CourseGroupSet.cgsName
    |                  FROM CourseGroupSet
    |                 WHERE CourseBlock.cbType = 103
    |                   AND assignment.caGroupUid != 0
    |                   AND CourseGroupSet.cgsUid = assignment.caGroupUid) AS assignmentCourseGroupSetName
    |          FROM CourseBlock 
    |               LEFT JOIN ClazzAssignment AS Assignment
    |                         ON assignment.caUid = CourseBlock.cbEntityUid
    |                            AND CourseBlock.cbType = 103
    |               LEFT JOIN ContentEntry AS Entry
    |                         ON entry.contentEntryUid = CourseBlock.cbEntityUid
    |                            AND CourseBlock.cbType = 104
    |               LEFT JOIN Language
    |                         ON Language.langUid = Entry.primaryLanguageUid
    |                            AND CourseBlock.cbType = 104
    |               LEFT JOIN CourseBlockPicture
    |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
    |         WHERE CourseBlock.cbClazzUid = ?
    |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
    |           AND (CourseBlock.cbType != 300)
    |      ORDER BY CourseBlock.cbIndex
    |          
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.setBoolean(2,includeInactive)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_assignmentCourseGroupSetName = _result.getString("assignmentCourseGroupSetName")
        var _tmp_CourseBlock_nullCount = 0
        val _tmp_cbUid = _result.getLong("cbUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbType = _result.getInt("cbType")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbTitle = _result.getString("cbTitle")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbDescription = _result.getString("cbDescription")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbIndex = _result.getInt("cbIndex")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbActive = _result.getBoolean("cbActive")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbLct = _result.getLong("cbLct")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbSourcedId = _result.getString("cbSourcedId")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbMetadata = _result.getString("cbMetadata")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
        if(_result.wasNull()) _tmp_CourseBlock_nullCount++
        val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 23
        var _tmp_CourseBlockPicture_nullCount = 0
        val _tmp_cbpUid = _result.getLong("cbpUid")
        if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
        val _tmp_cbpLct = _result.getLong("cbpLct")
        if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
        val _tmp_cbpPictureUri = _result.getString("cbpPictureUri")
        if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
        val _tmp_cbpThumbnailUri = _result.getString("cbpThumbnailUri")
        if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
        val _tmp_CourseBlockPicture_isAllNull = _tmp_CourseBlockPicture_nullCount == 4
        var _tmp_ContentEntry_nullCount = 0
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_title = _result.getString("title")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_description = _result.getString("description")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_entryId = _result.getString("entryId")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_author = _result.getString("author")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publisher = _result.getString("publisher")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseType = _result.getInt("licenseType")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseName = _result.getString("licenseName")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentFlags = _result.getInt("contentFlags")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_leaf = _result.getBoolean("leaf")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publik = _result.getBoolean("publik")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_minScore = _result.getInt("minScore")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentOwner = _result.getLong("contentOwner")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        var _tmp_ClazzAssignment_nullCount = 0
        val _tmp_caUid = _result.getLong("caUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caTitle = _result.getString("caTitle")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caDescription = _result.getString("caDescription")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caGroupUid = _result.getLong("caGroupUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caActive = _result.getBoolean("caActive")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caFileType = _result.getInt("caFileType")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caMarkingType = _result.getInt("caMarkingType")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caTextLimit = _result.getInt("caTextLimit")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caClazzUid = _result.getLong("caClazzUid")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caPeerReviewerCount = _result.getInt("caPeerReviewerCount")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_caLct = _result.getLong("caLct")
        if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
        val _tmp_ClazzAssignment_isAllNull = _tmp_ClazzAssignment_nullCount == 24
        CourseBlockAndDbEntities().apply {
          this.assignmentCourseGroupSetName = _tmp_assignmentCourseGroupSetName
          if(!_tmp_CourseBlock_isAllNull) {
            this.courseBlock = CourseBlock().apply {
              this.cbUid = _tmp_cbUid
              this.cbType = _tmp_cbType
              this.cbIndentLevel = _tmp_cbIndentLevel
              this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
              this.cbTitle = _tmp_cbTitle
              this.cbDescription = _tmp_cbDescription
              this.cbCompletionCriteria = _tmp_cbCompletionCriteria
              this.cbHideUntilDate = _tmp_cbHideUntilDate
              this.cbDeadlineDate = _tmp_cbDeadlineDate
              this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
              this.cbGracePeriodDate = _tmp_cbGracePeriodDate
              this.cbMaxPoints = _tmp_cbMaxPoints
              this.cbMinPoints = _tmp_cbMinPoints
              this.cbIndex = _tmp_cbIndex
              this.cbClazzUid = _tmp_cbClazzUid
              this.cbClazzSourcedId = _tmp_cbClazzSourcedId
              this.cbActive = _tmp_cbActive
              this.cbHidden = _tmp_cbHidden
              this.cbEntityUid = _tmp_cbEntityUid
              this.cbLct = _tmp_cbLct
              this.cbSourcedId = _tmp_cbSourcedId
              this.cbMetadata = _tmp_cbMetadata
              this.cbCreatedByAppId = _tmp_cbCreatedByAppId
            }
          }
          if(!_tmp_CourseBlockPicture_isAllNull) {
            this.courseBlockPicture = CourseBlockPicture().apply {
              this.cbpUid = _tmp_cbpUid
              this.cbpLct = _tmp_cbpLct
              this.cbpPictureUri = _tmp_cbpPictureUri
              this.cbpThumbnailUri = _tmp_cbpThumbnailUri
            }
          }
          if(!_tmp_ContentEntry_isAllNull) {
            this.contentEntry = ContentEntry().apply {
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentOwnerType = _tmp_contentOwnerType
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
            }
          }
          if(!_tmp_Language_isAllNull) {
            this.contentEntryLang = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
          if(!_tmp_ClazzAssignment_isAllNull) {
            this.assignment = ClazzAssignment().apply {
              this.caUid = _tmp_caUid
              this.caTitle = _tmp_caTitle
              this.caDescription = _tmp_caDescription
              this.caGroupUid = _tmp_caGroupUid
              this.caActive = _tmp_caActive
              this.caClassCommentEnabled = _tmp_caClassCommentEnabled
              this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
              this.caCompletionCriteria = _tmp_caCompletionCriteria
              this.caRequireFileSubmission = _tmp_caRequireFileSubmission
              this.caFileType = _tmp_caFileType
              this.caSizeLimit = _tmp_caSizeLimit
              this.caNumberOfFiles = _tmp_caNumberOfFiles
              this.caSubmissionPolicy = _tmp_caSubmissionPolicy
              this.caMarkingType = _tmp_caMarkingType
              this.caRequireTextSubmission = _tmp_caRequireTextSubmission
              this.caTextLimitType = _tmp_caTextLimitType
              this.caTextLimit = _tmp_caTextLimit
              this.caXObjectUid = _tmp_caXObjectUid
              this.caClazzUid = _tmp_caClazzUid
              this.caPeerReviewerCount = _tmp_caPeerReviewerCount
              this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
              this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
              this.caLastChangedBy = _tmp_caLastChangedBy
              this.caLct = _tmp_caLct
            }
          }
        }
      }
    }
  }

  override fun findAllCourseBlockByClazzUidAsFlow(
    clazzUid: Long,
    includeInactive: Boolean,
    includeHidden: Boolean,
    hideUntilFilterTime: Long,
    accountPersonUid: Long,
  ): Flow<List<CourseBlockAndDisplayDetails>> = _db.doorFlow(arrayOf("ContentEntry", "CourseBlock",
      "CourseBlockPicture", "ContentEntryPicture2")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = CAST(? AS BIGINT)
      |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
      |           AND (CAST(? AS INTEGER) = 1 OR NOT CourseBlock.cbHidden)
      |           AND (CAST(? AS BIGINT) >= CourseBlock.cbHideUntilDate)
      |           AND (CAST(? AS BIGINT) >= COALESCE(
      |                (SELECT CourseBlockParent.cbHideUntilDate
      |                   FROM CourseBlock CourseBlockParent
      |                  WHERE CourseBlockParent.cbUid = CourseBlock.cbModuleParentBlockUid), 0))
      |           AND (CourseBlock.cbType != 300)
      |           AND CAST(? AS BIGINT) = CAST(? AS BIGINT)        
      |      ORDER BY CourseBlock.cbIndex       
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = ?
      |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
      |           AND (CAST(? AS INTEGER) = 1 OR NOT CourseBlock.cbHidden)
      |           AND (? >= CourseBlock.cbHideUntilDate)
      |           AND (? >= COALESCE(
      |                (SELECT CourseBlockParent.cbHideUntilDate
      |                   FROM CourseBlock CourseBlockParent
      |                  WHERE CourseBlockParent.cbUid = CourseBlock.cbModuleParentBlockUid), 0))
      |           AND (CourseBlock.cbType != 300)
      |           AND ? = ?        
      |      ORDER BY CourseBlock.cbIndex       
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.setBoolean(2,includeInactive)
      _stmt.setBoolean(3,includeHidden)
      _stmt.setLong(4,hideUntilFilterTime)
      _stmt.setLong(5,hideUntilFilterTime)
      _stmt.setLong(6,accountPersonUid)
      _stmt.setLong(7,accountPersonUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_CourseBlock_nullCount = 0
          val _tmp_cbUid = _result.getLong("cbUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbType = _result.getInt("cbType")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbTitle = _result.getString("cbTitle")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDescription = _result.getString("cbDescription")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndex = _result.getInt("cbIndex")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbActive = _result.getBoolean("cbActive")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHidden = _result.getBoolean("cbHidden")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLct = _result.getLong("cbLct")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbSourcedId = _result.getString("cbSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMetadata = _result.getString("cbMetadata")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 23
          var _tmp_CourseBlockPicture_nullCount = 0
          val _tmp_cbpUid = _result.getLong("cbpUid")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpLct = _result.getLong("cbpLct")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpPictureUri = _result.getString("cbpPictureUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpThumbnailUri = _result.getString("cbpThumbnailUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_CourseBlockPicture_isAllNull = _tmp_CourseBlockPicture_nullCount == 4
          var _tmp_ContentEntry_nullCount = 0
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_title = _result.getString("title")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_description = _result.getString("description")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_entryId = _result.getString("entryId")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_author = _result.getString("author")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publisher = _result.getString("publisher")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseType = _result.getInt("licenseType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseName = _result.getString("licenseName")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_lastModified = _result.getLong("lastModified")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentFlags = _result.getInt("contentFlags")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_leaf = _result.getBoolean("leaf")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publik = _result.getBoolean("publik")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_minScore = _result.getInt("minScore")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwner = _result.getLong("contentOwner")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
          var _tmp_ContentEntryPicture2_nullCount = 0
          val _tmp_cepUid = _result.getLong("cepUid")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepLct = _result.getLong("cepLct")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepPictureUri = _result.getString("cepPictureUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
          CourseBlockAndDisplayDetails().apply {
            if(!_tmp_CourseBlock_isAllNull) {
              this.courseBlock = CourseBlock().apply {
                this.cbUid = _tmp_cbUid
                this.cbType = _tmp_cbType
                this.cbIndentLevel = _tmp_cbIndentLevel
                this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
                this.cbTitle = _tmp_cbTitle
                this.cbDescription = _tmp_cbDescription
                this.cbCompletionCriteria = _tmp_cbCompletionCriteria
                this.cbHideUntilDate = _tmp_cbHideUntilDate
                this.cbDeadlineDate = _tmp_cbDeadlineDate
                this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
                this.cbGracePeriodDate = _tmp_cbGracePeriodDate
                this.cbMaxPoints = _tmp_cbMaxPoints
                this.cbMinPoints = _tmp_cbMinPoints
                this.cbIndex = _tmp_cbIndex
                this.cbClazzUid = _tmp_cbClazzUid
                this.cbClazzSourcedId = _tmp_cbClazzSourcedId
                this.cbActive = _tmp_cbActive
                this.cbHidden = _tmp_cbHidden
                this.cbEntityUid = _tmp_cbEntityUid
                this.cbLct = _tmp_cbLct
                this.cbSourcedId = _tmp_cbSourcedId
                this.cbMetadata = _tmp_cbMetadata
                this.cbCreatedByAppId = _tmp_cbCreatedByAppId
              }
            }
            if(!_tmp_CourseBlockPicture_isAllNull) {
              this.courseBlockPicture = CourseBlockPicture().apply {
                this.cbpUid = _tmp_cbpUid
                this.cbpLct = _tmp_cbpLct
                this.cbpPictureUri = _tmp_cbpPictureUri
                this.cbpThumbnailUri = _tmp_cbpThumbnailUri
              }
            }
            if(!_tmp_ContentEntry_isAllNull) {
              this.contentEntry = ContentEntry().apply {
                this.contentEntryUid = _tmp_contentEntryUid
                this.title = _tmp_title
                this.description = _tmp_description
                this.entryId = _tmp_entryId
                this.author = _tmp_author
                this.publisher = _tmp_publisher
                this.licenseType = _tmp_licenseType
                this.licenseName = _tmp_licenseName
                this.licenseUrl = _tmp_licenseUrl
                this.sourceUrl = _tmp_sourceUrl
                this.thumbnailUrl = _tmp_thumbnailUrl
                this.lastModified = _tmp_lastModified
                this.primaryLanguageUid = _tmp_primaryLanguageUid
                this.languageVariantUid = _tmp_languageVariantUid
                this.contentFlags = _tmp_contentFlags
                this.leaf = _tmp_leaf
                this.publik = _tmp_publik
                this.ceInactive = _tmp_ceInactive
                this.completionCriteria = _tmp_completionCriteria
                this.minScore = _tmp_minScore
                this.contentTypeFlag = _tmp_contentTypeFlag
                this.contentOwner = _tmp_contentOwner
                this.contentOwnerType = _tmp_contentOwnerType
                this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                this.contentEntryLct = _tmp_contentEntryLct
              }
            }
            if(!_tmp_ContentEntryPicture2_isAllNull) {
              this.contentEntryPicture2 = ContentEntryPicture2().apply {
                this.cepUid = _tmp_cepUid
                this.cepLct = _tmp_cepLct
                this.cepPictureUri = _tmp_cepPictureUri
                this.cepThumbnailUri = _tmp_cepThumbnailUri
              }
            }
          }
        }
      }
    }
  }

  override fun findAllCourseBlockByClazzUidAsPagingSource(
    clazzUid: Long,
    collapseList: List<Long>,
    includeInactive: Boolean,
    includeHidden: Boolean,
    hideUntilFilterTime: Long,
    accountPersonUid: Long,
  ): PagingSource<Int, CourseBlockAndDisplayDetails> = object :
      DoorLimitOffsetPagingSource<CourseBlockAndDisplayDetails>(db = _db
  , tableNames = arrayOf("ContentEntry", "CourseBlock", "CourseBlockPicture",
      "ContentEntryPicture2")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<CourseBlockAndDisplayDetails> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*,
      |               CourseBlock.cbUid NOT IN(?) AS expanded
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = CAST(? AS BIGINT)
      |           AND CourseBlock.cbModuleParentBlockUid NOT IN(?)
      |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
      |           AND (CAST(? AS INTEGER) = 1 OR NOT CourseBlock.cbHidden)
      |           AND (CAST(? AS BIGINT) >= CourseBlock.cbHideUntilDate)
      |           AND (CAST(? AS BIGINT) >= COALESCE(
      |                (SELECT CourseBlockParent.cbHideUntilDate
      |                   FROM CourseBlock CourseBlockParent
      |                  WHERE CourseBlockParent.cbUid = CourseBlock.cbModuleParentBlockUid), 0))
      |           AND (CourseBlock.cbType != 300) 
      |           AND CAST(? AS BIGINT) = CAST(? AS BIGINT)       
      |      ORDER BY CourseBlock.cbIndex       
      |    ) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |SELECT * FROM (
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*,
      |               CourseBlock.cbUid NOT IN(?) AS expanded
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = ?
      |           AND CourseBlock.cbModuleParentBlockUid NOT IN(?)
      |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
      |           AND (CAST(? AS INTEGER) = 1 OR NOT CourseBlock.cbHidden)
      |           AND (? >= CourseBlock.cbHideUntilDate)
      |           AND (? >= COALESCE(
      |                (SELECT CourseBlockParent.cbHideUntilDate
      |                   FROM CourseBlock CourseBlockParent
      |                  WHERE CourseBlockParent.cbUid = CourseBlock.cbModuleParentBlockUid), 0))
      |           AND (CourseBlock.cbType != 300) 
      |           AND ? = ?       
      |      ORDER BY CourseBlock.cbIndex       
      |    ) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          collapseList.toTypedArray()))
      _stmt.setLong(2,clazzUid)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          collapseList.toTypedArray()))
      _stmt.setBoolean(4,includeInactive)
      _stmt.setBoolean(5,includeHidden)
      _stmt.setLong(6,hideUntilFilterTime)
      _stmt.setLong(7,hideUntilFilterTime)
      _stmt.setLong(8,accountPersonUid)
      _stmt.setLong(9,accountPersonUid)
      _stmt.setInt(10,_limit)
      _stmt.setInt(11,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_CourseBlock_nullCount = 0
          val _tmp_cbUid = _result.getLong("cbUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbType = _result.getInt("cbType")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbTitle = _result.getString("cbTitle")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDescription = _result.getString("cbDescription")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndex = _result.getInt("cbIndex")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbActive = _result.getBoolean("cbActive")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHidden = _result.getBoolean("cbHidden")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLct = _result.getLong("cbLct")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbSourcedId = _result.getString("cbSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMetadata = _result.getString("cbMetadata")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 23
          var _tmp_CourseBlockPicture_nullCount = 0
          val _tmp_cbpUid = _result.getLong("cbpUid")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpLct = _result.getLong("cbpLct")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpPictureUri = _result.getString("cbpPictureUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpThumbnailUri = _result.getString("cbpThumbnailUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_CourseBlockPicture_isAllNull = _tmp_CourseBlockPicture_nullCount == 4
          var _tmp_ContentEntry_nullCount = 0
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_title = _result.getString("title")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_description = _result.getString("description")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_entryId = _result.getString("entryId")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_author = _result.getString("author")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publisher = _result.getString("publisher")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseType = _result.getInt("licenseType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseName = _result.getString("licenseName")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_lastModified = _result.getLong("lastModified")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentFlags = _result.getInt("contentFlags")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_leaf = _result.getBoolean("leaf")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publik = _result.getBoolean("publik")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_minScore = _result.getInt("minScore")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwner = _result.getLong("contentOwner")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
          var _tmp_ContentEntryPicture2_nullCount = 0
          val _tmp_cepUid = _result.getLong("cepUid")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepLct = _result.getLong("cepLct")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepPictureUri = _result.getString("cepPictureUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
          CourseBlockAndDisplayDetails().apply {
            if(!_tmp_CourseBlock_isAllNull) {
              this.courseBlock = CourseBlock().apply {
                this.cbUid = _tmp_cbUid
                this.cbType = _tmp_cbType
                this.cbIndentLevel = _tmp_cbIndentLevel
                this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
                this.cbTitle = _tmp_cbTitle
                this.cbDescription = _tmp_cbDescription
                this.cbCompletionCriteria = _tmp_cbCompletionCriteria
                this.cbHideUntilDate = _tmp_cbHideUntilDate
                this.cbDeadlineDate = _tmp_cbDeadlineDate
                this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
                this.cbGracePeriodDate = _tmp_cbGracePeriodDate
                this.cbMaxPoints = _tmp_cbMaxPoints
                this.cbMinPoints = _tmp_cbMinPoints
                this.cbIndex = _tmp_cbIndex
                this.cbClazzUid = _tmp_cbClazzUid
                this.cbClazzSourcedId = _tmp_cbClazzSourcedId
                this.cbActive = _tmp_cbActive
                this.cbHidden = _tmp_cbHidden
                this.cbEntityUid = _tmp_cbEntityUid
                this.cbLct = _tmp_cbLct
                this.cbSourcedId = _tmp_cbSourcedId
                this.cbMetadata = _tmp_cbMetadata
                this.cbCreatedByAppId = _tmp_cbCreatedByAppId
              }
            }
            if(!_tmp_CourseBlockPicture_isAllNull) {
              this.courseBlockPicture = CourseBlockPicture().apply {
                this.cbpUid = _tmp_cbpUid
                this.cbpLct = _tmp_cbpLct
                this.cbpPictureUri = _tmp_cbpPictureUri
                this.cbpThumbnailUri = _tmp_cbpThumbnailUri
              }
            }
            if(!_tmp_ContentEntry_isAllNull) {
              this.contentEntry = ContentEntry().apply {
                this.contentEntryUid = _tmp_contentEntryUid
                this.title = _tmp_title
                this.description = _tmp_description
                this.entryId = _tmp_entryId
                this.author = _tmp_author
                this.publisher = _tmp_publisher
                this.licenseType = _tmp_licenseType
                this.licenseName = _tmp_licenseName
                this.licenseUrl = _tmp_licenseUrl
                this.sourceUrl = _tmp_sourceUrl
                this.thumbnailUrl = _tmp_thumbnailUrl
                this.lastModified = _tmp_lastModified
                this.primaryLanguageUid = _tmp_primaryLanguageUid
                this.languageVariantUid = _tmp_languageVariantUid
                this.contentFlags = _tmp_contentFlags
                this.leaf = _tmp_leaf
                this.publik = _tmp_publik
                this.ceInactive = _tmp_ceInactive
                this.completionCriteria = _tmp_completionCriteria
                this.minScore = _tmp_minScore
                this.contentTypeFlag = _tmp_contentTypeFlag
                this.contentOwner = _tmp_contentOwner
                this.contentOwnerType = _tmp_contentOwnerType
                this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                this.contentEntryLct = _tmp_contentEntryLct
              }
            }
            if(!_tmp_ContentEntryPicture2_isAllNull) {
              this.contentEntryPicture2 = ContentEntryPicture2().apply {
                this.cepUid = _tmp_cepUid
                this.cepLct = _tmp_cepLct
                this.cepPictureUri = _tmp_cepPictureUri
                this.cepThumbnailUri = _tmp_cepThumbnailUri
              }
            }
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*,
      |               CourseBlock.cbUid NOT IN(?) AS expanded
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = CAST(? AS BIGINT)
      |           AND CourseBlock.cbModuleParentBlockUid NOT IN(?)
      |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
      |           AND (CAST(? AS INTEGER) = 1 OR NOT CourseBlock.cbHidden)
      |           AND (CAST(? AS BIGINT) >= CourseBlock.cbHideUntilDate)
      |           AND (CAST(? AS BIGINT) >= COALESCE(
      |                (SELECT CourseBlockParent.cbHideUntilDate
      |                   FROM CourseBlock CourseBlockParent
      |                  WHERE CourseBlockParent.cbUid = CourseBlock.cbModuleParentBlockUid), 0))
      |           AND (CourseBlock.cbType != 300) 
      |           AND CAST(? AS BIGINT) = CAST(? AS BIGINT)       
      |      ORDER BY CourseBlock.cbIndex       
      |    ) AS _PagingCount
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |SELECT COUNT(*) FROM (
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*,
      |               CourseBlock.cbUid NOT IN(?) AS expanded
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = ?
      |           AND CourseBlock.cbModuleParentBlockUid NOT IN(?)
      |           AND (CAST(? AS INTEGER) = 1 OR CourseBlock.cbActive)
      |           AND (CAST(? AS INTEGER) = 1 OR NOT CourseBlock.cbHidden)
      |           AND (? >= CourseBlock.cbHideUntilDate)
      |           AND (? >= COALESCE(
      |                (SELECT CourseBlockParent.cbHideUntilDate
      |                   FROM CourseBlock CourseBlockParent
      |                  WHERE CourseBlockParent.cbUid = CourseBlock.cbModuleParentBlockUid), 0))
      |           AND (CourseBlock.cbType != 300) 
      |           AND ? = ?       
      |      ORDER BY CourseBlock.cbIndex       
      |    ) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          collapseList.toTypedArray()))
      _stmt.setLong(2,clazzUid)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          collapseList.toTypedArray()))
      _stmt.setBoolean(4,includeInactive)
      _stmt.setBoolean(5,includeHidden)
      _stmt.setLong(6,hideUntilFilterTime)
      _stmt.setLong(7,hideUntilFilterTime)
      _stmt.setLong(8,accountPersonUid)
      _stmt.setLong(9,accountPersonUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE CourseBlock 
      |           SET cbActive = ?, 
      |               cbLct = CAST(? AS BIGINT)
      |         WHERE cbUid = CAST(? AS BIGINT)
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE CourseBlock 
      |           SET cbActive = ?, 
      |               cbLct = ?
      |         WHERE cbUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,cbUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override fun getTitleByAssignmentUid(assignmentUid: Long): Flow<String?> =
      _db.doorFlow(arrayOf("CourseBlock")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT CourseBlock.cbTitle
      |          FROM CourseBlock 
      |         WHERE CourseBlock.cbEntityUid = CAST(? AS BIGINT)
      |           AND CourseBlock.cbType = 103
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT CourseBlock.cbTitle
      |          FROM CourseBlock 
      |         WHERE CourseBlock.cbEntityUid = ?
      |           AND CourseBlock.cbType = 103
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,assignmentUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          _result.getString(1)
        }
      }
    }
  }

  override fun findByUidAsFlow(courseBlockUid: Long): Flow<CourseBlock?> =
      _db.doorFlow(arrayOf("CourseBlock")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT CourseBlock.*
      |          FROM CourseBlock
      |         WHERE CourseBlock.cbUid = CAST(? AS BIGINT) 
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT CourseBlock.*
      |          FROM CourseBlock
      |         WHERE CourseBlock.cbUid = ? 
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,courseBlockUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_cbUid = _result.getLong("cbUid")
          val _tmp_cbType = _result.getInt("cbType")
          val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
          val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
          val _tmp_cbTitle = _result.getString("cbTitle")
          val _tmp_cbDescription = _result.getString("cbDescription")
          val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
          val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
          val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
          val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
          val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
          val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
          val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
          val _tmp_cbIndex = _result.getInt("cbIndex")
          val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
          val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
          val _tmp_cbActive = _result.getBoolean("cbActive")
          val _tmp_cbHidden = _result.getBoolean("cbHidden")
          val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
          val _tmp_cbLct = _result.getLong("cbLct")
          val _tmp_cbSourcedId = _result.getString("cbSourcedId")
          val _tmp_cbMetadata = _result.getString("cbMetadata")
          val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
          CourseBlock().apply {
            this.cbUid = _tmp_cbUid
            this.cbType = _tmp_cbType
            this.cbIndentLevel = _tmp_cbIndentLevel
            this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
            this.cbTitle = _tmp_cbTitle
            this.cbDescription = _tmp_cbDescription
            this.cbCompletionCriteria = _tmp_cbCompletionCriteria
            this.cbHideUntilDate = _tmp_cbHideUntilDate
            this.cbDeadlineDate = _tmp_cbDeadlineDate
            this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
            this.cbGracePeriodDate = _tmp_cbGracePeriodDate
            this.cbMaxPoints = _tmp_cbMaxPoints
            this.cbMinPoints = _tmp_cbMinPoints
            this.cbIndex = _tmp_cbIndex
            this.cbClazzUid = _tmp_cbClazzUid
            this.cbClazzSourcedId = _tmp_cbClazzSourcedId
            this.cbActive = _tmp_cbActive
            this.cbHidden = _tmp_cbHidden
            this.cbEntityUid = _tmp_cbEntityUid
            this.cbLct = _tmp_cbLct
            this.cbSourcedId = _tmp_cbSourcedId
            this.cbMetadata = _tmp_cbMetadata
            this.cbCreatedByAppId = _tmp_cbCreatedByAppId
          }
        }
      }
    }
  }

  override suspend fun findCourseBlockAndClazzUidByDiscussionPostUid(postUid: Long):
      CourseBlockUidAndClazzUid? = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT COALESCE(CourseBlock.cbUid, 0) AS courseBlockUid,
    |               COALESCE(CourseBlock.cbClazzUid, 0) AS clazzUid
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbUid = 
    |               (SELECT DiscussionPost.discussionPostCourseBlockUid 
    |                  FROM DiscussionPost
    |                 WHERE DiscussionPost.discussionPostUid = CAST(? AS BIGINT))
    |         LIMIT 1
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT COALESCE(CourseBlock.cbUid, 0) AS courseBlockUid,
    |               COALESCE(CourseBlock.cbClazzUid, 0) AS clazzUid
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbUid = 
    |               (SELECT DiscussionPost.discussionPostCourseBlockUid 
    |                  FROM DiscussionPost
    |                 WHERE DiscussionPost.discussionPostUid = ?)
    |         LIMIT 1
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,postUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_courseBlockUid = _result.getLong("courseBlockUid")
        CourseBlockUidAndClazzUid().apply {
          this.clazzUid = _tmp_clazzUid
          this.courseBlockUid = _tmp_courseBlockUid
        }
      }
    }
  }

  override suspend fun findCourseBlockByDiscussionPostUid(postUid: Long): CourseBlock? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT CourseBlock.*
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbUid = 
    |               (SELECT DiscussionPost.discussionPostCourseBlockUid 
    |                  FROM DiscussionPost
    |                 WHERE DiscussionPost.discussionPostUid = CAST(? AS BIGINT)) 
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT CourseBlock.*
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbUid = 
    |               (SELECT DiscussionPost.discussionPostCourseBlockUid 
    |                  FROM DiscussionPost
    |                 WHERE DiscussionPost.discussionPostUid = ?) 
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,postUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cbUid = _result.getLong("cbUid")
        val _tmp_cbType = _result.getInt("cbType")
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        val _tmp_cbTitle = _result.getString("cbTitle")
        val _tmp_cbDescription = _result.getString("cbDescription")
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
        val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
        val _tmp_cbIndex = _result.getInt("cbIndex")
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
        val _tmp_cbActive = _result.getBoolean("cbActive")
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        val _tmp_cbLct = _result.getLong("cbLct")
        val _tmp_cbSourcedId = _result.getString("cbSourcedId")
        val _tmp_cbMetadata = _result.getString("cbMetadata")
        val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
        CourseBlock().apply {
          this.cbUid = _tmp_cbUid
          this.cbType = _tmp_cbType
          this.cbIndentLevel = _tmp_cbIndentLevel
          this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
          this.cbTitle = _tmp_cbTitle
          this.cbDescription = _tmp_cbDescription
          this.cbCompletionCriteria = _tmp_cbCompletionCriteria
          this.cbHideUntilDate = _tmp_cbHideUntilDate
          this.cbDeadlineDate = _tmp_cbDeadlineDate
          this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
          this.cbGracePeriodDate = _tmp_cbGracePeriodDate
          this.cbMaxPoints = _tmp_cbMaxPoints
          this.cbMinPoints = _tmp_cbMinPoints
          this.cbIndex = _tmp_cbIndex
          this.cbClazzUid = _tmp_cbClazzUid
          this.cbClazzSourcedId = _tmp_cbClazzSourcedId
          this.cbActive = _tmp_cbActive
          this.cbHidden = _tmp_cbHidden
          this.cbEntityUid = _tmp_cbEntityUid
          this.cbLct = _tmp_cbLct
          this.cbSourcedId = _tmp_cbSourcedId
          this.cbMetadata = _tmp_cbMetadata
          this.cbCreatedByAppId = _tmp_cbCreatedByAppId
        }
      }
    }
  }

  override suspend fun findClazzUidByCourseBlockUid(courseBlockUid: Long): Long =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT COALESCE(CourseBlock.cbClazzUid, 0) AS clazzUid
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbUid = CAST(? AS BIGINT)
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT COALESCE(CourseBlock.cbClazzUid, 0) AS clazzUid
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbUid = ?
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,courseBlockUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  override fun findCourseBlockByAssignmentUid(assignmentUid: Long): Flow<CourseBlockAndAssignment?>
      = _db.doorFlow(arrayOf("ClazzAssignment", "CourseBlock")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT ClazzAssignment.*, CourseBlock.* 
      |          FROM ClazzAssignment
      |               JOIN CourseBlock 
      |                    ON CourseBlock.cbEntityUid = CAST(? AS BIGINT)
      |         WHERE ClazzAssignment.caUid = CAST(? AS BIGINT)
      |         LIMIT 1 
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT ClazzAssignment.*, CourseBlock.* 
      |          FROM ClazzAssignment
      |               JOIN CourseBlock 
      |                    ON CourseBlock.cbEntityUid = ?
      |         WHERE ClazzAssignment.caUid = ?
      |         LIMIT 1 
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,assignmentUid)
      _stmt.setLong(2,assignmentUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          var _tmp_CourseBlock_nullCount = 0
          val _tmp_cbUid = _result.getLong("cbUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbType = _result.getInt("cbType")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbTitle = _result.getString("cbTitle")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDescription = _result.getString("cbDescription")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndex = _result.getInt("cbIndex")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbActive = _result.getBoolean("cbActive")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHidden = _result.getBoolean("cbHidden")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLct = _result.getLong("cbLct")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbSourcedId = _result.getString("cbSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMetadata = _result.getString("cbMetadata")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 23
          var _tmp_ClazzAssignment_nullCount = 0
          val _tmp_caUid = _result.getLong("caUid")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caTitle = _result.getString("caTitle")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caDescription = _result.getString("caDescription")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caGroupUid = _result.getLong("caGroupUid")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caActive = _result.getBoolean("caActive")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caClassCommentEnabled = _result.getBoolean("caClassCommentEnabled")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caPrivateCommentsEnabled = _result.getBoolean("caPrivateCommentsEnabled")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caCompletionCriteria = _result.getInt("caCompletionCriteria")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caRequireFileSubmission = _result.getBoolean("caRequireFileSubmission")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caFileType = _result.getInt("caFileType")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caSizeLimit = _result.getInt("caSizeLimit")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caNumberOfFiles = _result.getInt("caNumberOfFiles")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caSubmissionPolicy = _result.getInt("caSubmissionPolicy")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caMarkingType = _result.getInt("caMarkingType")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caRequireTextSubmission = _result.getBoolean("caRequireTextSubmission")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caTextLimitType = _result.getInt("caTextLimitType")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caTextLimit = _result.getInt("caTextLimit")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caXObjectUid = _result.getLong("caXObjectUid")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caClazzUid = _result.getLong("caClazzUid")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caPeerReviewerCount = _result.getInt("caPeerReviewerCount")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caLocalChangeSeqNum = _result.getLong("caLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caMasterChangeSeqNum = _result.getLong("caMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caLastChangedBy = _result.getInt("caLastChangedBy")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_caLct = _result.getLong("caLct")
          if(_result.wasNull()) _tmp_ClazzAssignment_nullCount++
          val _tmp_ClazzAssignment_isAllNull = _tmp_ClazzAssignment_nullCount == 24
          CourseBlockAndAssignment().apply {
            if(!_tmp_CourseBlock_isAllNull) {
              this.courseBlock = CourseBlock().apply {
                this.cbUid = _tmp_cbUid
                this.cbType = _tmp_cbType
                this.cbIndentLevel = _tmp_cbIndentLevel
                this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
                this.cbTitle = _tmp_cbTitle
                this.cbDescription = _tmp_cbDescription
                this.cbCompletionCriteria = _tmp_cbCompletionCriteria
                this.cbHideUntilDate = _tmp_cbHideUntilDate
                this.cbDeadlineDate = _tmp_cbDeadlineDate
                this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
                this.cbGracePeriodDate = _tmp_cbGracePeriodDate
                this.cbMaxPoints = _tmp_cbMaxPoints
                this.cbMinPoints = _tmp_cbMinPoints
                this.cbIndex = _tmp_cbIndex
                this.cbClazzUid = _tmp_cbClazzUid
                this.cbClazzSourcedId = _tmp_cbClazzSourcedId
                this.cbActive = _tmp_cbActive
                this.cbHidden = _tmp_cbHidden
                this.cbEntityUid = _tmp_cbEntityUid
                this.cbLct = _tmp_cbLct
                this.cbSourcedId = _tmp_cbSourcedId
                this.cbMetadata = _tmp_cbMetadata
                this.cbCreatedByAppId = _tmp_cbCreatedByAppId
              }
            }
            if(!_tmp_ClazzAssignment_isAllNull) {
              this.assignment = ClazzAssignment().apply {
                this.caUid = _tmp_caUid
                this.caTitle = _tmp_caTitle
                this.caDescription = _tmp_caDescription
                this.caGroupUid = _tmp_caGroupUid
                this.caActive = _tmp_caActive
                this.caClassCommentEnabled = _tmp_caClassCommentEnabled
                this.caPrivateCommentsEnabled = _tmp_caPrivateCommentsEnabled
                this.caCompletionCriteria = _tmp_caCompletionCriteria
                this.caRequireFileSubmission = _tmp_caRequireFileSubmission
                this.caFileType = _tmp_caFileType
                this.caSizeLimit = _tmp_caSizeLimit
                this.caNumberOfFiles = _tmp_caNumberOfFiles
                this.caSubmissionPolicy = _tmp_caSubmissionPolicy
                this.caMarkingType = _tmp_caMarkingType
                this.caRequireTextSubmission = _tmp_caRequireTextSubmission
                this.caTextLimitType = _tmp_caTextLimitType
                this.caTextLimit = _tmp_caTextLimit
                this.caXObjectUid = _tmp_caXObjectUid
                this.caClazzUid = _tmp_caClazzUid
                this.caPeerReviewerCount = _tmp_caPeerReviewerCount
                this.caLocalChangeSeqNum = _tmp_caLocalChangeSeqNum
                this.caMasterChangeSeqNum = _tmp_caMasterChangeSeqNum
                this.caLastChangedBy = _tmp_caLastChangedBy
                this.caLct = _tmp_caLct
              }
            }
          }
        }
      }
    }
  }

  override suspend fun findBySourcedId(sourcedId: String, accountPersonUid: Long): CourseBlock? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |            SELECT CourseBlock.*
    |              FROM CourseBlock
    |             WHERE CAST(cbUid AS TEXT) = ?
    |                OR cbSourcedId = ?
    |               AND CAST(? AS BIGINT) != 0 
    |        
    """.trimMargin(),
    postgreSql = """
    |
    |            SELECT CourseBlock.*
    |              FROM CourseBlock
    |             WHERE CAST(cbUid AS TEXT) = ?
    |                OR cbSourcedId = ?
    |               AND ? != 0 
    |        
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,sourcedId)
    _stmt.setString(2,sourcedId)
    _stmt.setLong(3,accountPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cbUid = _result.getLong("cbUid")
        val _tmp_cbType = _result.getInt("cbType")
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        val _tmp_cbTitle = _result.getString("cbTitle")
        val _tmp_cbDescription = _result.getString("cbDescription")
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
        val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
        val _tmp_cbIndex = _result.getInt("cbIndex")
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
        val _tmp_cbActive = _result.getBoolean("cbActive")
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        val _tmp_cbLct = _result.getLong("cbLct")
        val _tmp_cbSourcedId = _result.getString("cbSourcedId")
        val _tmp_cbMetadata = _result.getString("cbMetadata")
        val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
        CourseBlock().apply {
          this.cbUid = _tmp_cbUid
          this.cbType = _tmp_cbType
          this.cbIndentLevel = _tmp_cbIndentLevel
          this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
          this.cbTitle = _tmp_cbTitle
          this.cbDescription = _tmp_cbDescription
          this.cbCompletionCriteria = _tmp_cbCompletionCriteria
          this.cbHideUntilDate = _tmp_cbHideUntilDate
          this.cbDeadlineDate = _tmp_cbDeadlineDate
          this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
          this.cbGracePeriodDate = _tmp_cbGracePeriodDate
          this.cbMaxPoints = _tmp_cbMaxPoints
          this.cbMinPoints = _tmp_cbMinPoints
          this.cbIndex = _tmp_cbIndex
          this.cbClazzUid = _tmp_cbClazzUid
          this.cbClazzSourcedId = _tmp_cbClazzSourcedId
          this.cbActive = _tmp_cbActive
          this.cbHidden = _tmp_cbHidden
          this.cbEntityUid = _tmp_cbEntityUid
          this.cbLct = _tmp_cbLct
          this.cbSourcedId = _tmp_cbSourcedId
          this.cbMetadata = _tmp_cbMetadata
          this.cbCreatedByAppId = _tmp_cbCreatedByAppId
        }
      }
    }
  }

  override suspend fun findByClazzUid(clazzUid: Long): List<CourseBlock> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT CourseBlock.*
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbClazzUid = CAST(? AS BIGINT) 
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT CourseBlock.*
    |          FROM CourseBlock
    |         WHERE CourseBlock.cbClazzUid = ? 
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cbUid = _result.getLong("cbUid")
        val _tmp_cbType = _result.getInt("cbType")
        val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
        val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
        val _tmp_cbTitle = _result.getString("cbTitle")
        val _tmp_cbDescription = _result.getString("cbDescription")
        val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
        val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
        val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
        val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
        val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
        val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
        val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
        val _tmp_cbIndex = _result.getInt("cbIndex")
        val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
        val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
        val _tmp_cbActive = _result.getBoolean("cbActive")
        val _tmp_cbHidden = _result.getBoolean("cbHidden")
        val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
        val _tmp_cbLct = _result.getLong("cbLct")
        val _tmp_cbSourcedId = _result.getString("cbSourcedId")
        val _tmp_cbMetadata = _result.getString("cbMetadata")
        val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
        CourseBlock().apply {
          this.cbUid = _tmp_cbUid
          this.cbType = _tmp_cbType
          this.cbIndentLevel = _tmp_cbIndentLevel
          this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
          this.cbTitle = _tmp_cbTitle
          this.cbDescription = _tmp_cbDescription
          this.cbCompletionCriteria = _tmp_cbCompletionCriteria
          this.cbHideUntilDate = _tmp_cbHideUntilDate
          this.cbDeadlineDate = _tmp_cbDeadlineDate
          this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
          this.cbGracePeriodDate = _tmp_cbGracePeriodDate
          this.cbMaxPoints = _tmp_cbMaxPoints
          this.cbMinPoints = _tmp_cbMinPoints
          this.cbIndex = _tmp_cbIndex
          this.cbClazzUid = _tmp_cbClazzUid
          this.cbClazzSourcedId = _tmp_cbClazzSourcedId
          this.cbActive = _tmp_cbActive
          this.cbHidden = _tmp_cbHidden
          this.cbEntityUid = _tmp_cbEntityUid
          this.cbLct = _tmp_cbLct
          this.cbSourcedId = _tmp_cbSourcedId
          this.cbMetadata = _tmp_cbMetadata
          this.cbCreatedByAppId = _tmp_cbCreatedByAppId
        }
      }
    }
  }

  override fun findByClazzUidAsFlow(clazzUid: Long):
      Flow<List<CourseBlockAndGradebookDisplayDetails>> = _db.doorFlow(arrayOf("ContentEntry",
      "CourseBlock", "CourseBlockPicture", "ContentEntryPicture2")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = CAST(? AS BIGINT)
      |           AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |      ORDER BY CourseBlock.cbIndex
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT CourseBlock.*, ContentEntry.*, CourseBlockPicture.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               LEFT JOIN ContentEntry
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |               LEFT JOIN CourseBlockPicture
      |                         ON CourseBlockPicture.cbpUid = CourseBlock.cbUid    
      |               LEFT JOIN ContentEntryPicture2
      |                         ON CourseBlock.cbType = 104
      |                            AND ContentEntryPicture2.cepUid = CourseBlock.cbEntityUid
      |         WHERE CourseBlock.cbClazzUid = ?
      |           AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |      ORDER BY CourseBlock.cbIndex
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_CourseBlock_nullCount = 0
          val _tmp_cbUid = _result.getLong("cbUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbType = _result.getInt("cbType")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndentLevel = _result.getInt("cbIndentLevel")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbModuleParentBlockUid = _result.getLong("cbModuleParentBlockUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbTitle = _result.getString("cbTitle")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDescription = _result.getString("cbDescription")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCompletionCriteria = _result.getInt("cbCompletionCriteria")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHideUntilDate = _result.getLong("cbHideUntilDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbDeadlineDate = _result.getLong("cbDeadlineDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLateSubmissionPenalty = _result.getInt("cbLateSubmissionPenalty")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbGracePeriodDate = _result.getLong("cbGracePeriodDate")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMaxPoints = _result.getFloatNullable("cbMaxPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMinPoints = _result.getFloatNullable("cbMinPoints")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbIndex = _result.getInt("cbIndex")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzUid = _result.getLong("cbClazzUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbClazzSourcedId = _result.getString("cbClazzSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbActive = _result.getBoolean("cbActive")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbHidden = _result.getBoolean("cbHidden")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbEntityUid = _result.getLong("cbEntityUid")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbLct = _result.getLong("cbLct")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbSourcedId = _result.getString("cbSourcedId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbMetadata = _result.getString("cbMetadata")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_cbCreatedByAppId = _result.getString("cbCreatedByAppId")
          if(_result.wasNull()) _tmp_CourseBlock_nullCount++
          val _tmp_CourseBlock_isAllNull = _tmp_CourseBlock_nullCount == 23
          var _tmp_CourseBlockPicture_nullCount = 0
          val _tmp_cbpUid = _result.getLong("cbpUid")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpLct = _result.getLong("cbpLct")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpPictureUri = _result.getString("cbpPictureUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_cbpThumbnailUri = _result.getString("cbpThumbnailUri")
          if(_result.wasNull()) _tmp_CourseBlockPicture_nullCount++
          val _tmp_CourseBlockPicture_isAllNull = _tmp_CourseBlockPicture_nullCount == 4
          var _tmp_ContentEntry_nullCount = 0
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_title = _result.getString("title")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_description = _result.getString("description")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_entryId = _result.getString("entryId")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_author = _result.getString("author")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publisher = _result.getString("publisher")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseType = _result.getInt("licenseType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseName = _result.getString("licenseName")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_lastModified = _result.getLong("lastModified")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentFlags = _result.getInt("contentFlags")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_leaf = _result.getBoolean("leaf")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publik = _result.getBoolean("publik")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_minScore = _result.getInt("minScore")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwner = _result.getLong("contentOwner")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
          var _tmp_ContentEntryPicture2_nullCount = 0
          val _tmp_cepUid = _result.getLong("cepUid")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepLct = _result.getLong("cepLct")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepPictureUri = _result.getString("cepPictureUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
          CourseBlockAndGradebookDisplayDetails().apply {
            if(!_tmp_CourseBlock_isAllNull) {
              this.block = CourseBlock().apply {
                this.cbUid = _tmp_cbUid
                this.cbType = _tmp_cbType
                this.cbIndentLevel = _tmp_cbIndentLevel
                this.cbModuleParentBlockUid = _tmp_cbModuleParentBlockUid
                this.cbTitle = _tmp_cbTitle
                this.cbDescription = _tmp_cbDescription
                this.cbCompletionCriteria = _tmp_cbCompletionCriteria
                this.cbHideUntilDate = _tmp_cbHideUntilDate
                this.cbDeadlineDate = _tmp_cbDeadlineDate
                this.cbLateSubmissionPenalty = _tmp_cbLateSubmissionPenalty
                this.cbGracePeriodDate = _tmp_cbGracePeriodDate
                this.cbMaxPoints = _tmp_cbMaxPoints
                this.cbMinPoints = _tmp_cbMinPoints
                this.cbIndex = _tmp_cbIndex
                this.cbClazzUid = _tmp_cbClazzUid
                this.cbClazzSourcedId = _tmp_cbClazzSourcedId
                this.cbActive = _tmp_cbActive
                this.cbHidden = _tmp_cbHidden
                this.cbEntityUid = _tmp_cbEntityUid
                this.cbLct = _tmp_cbLct
                this.cbSourcedId = _tmp_cbSourcedId
                this.cbMetadata = _tmp_cbMetadata
                this.cbCreatedByAppId = _tmp_cbCreatedByAppId
              }
            }
            if(!_tmp_CourseBlockPicture_isAllNull) {
              this.courseBlockPicture = CourseBlockPicture().apply {
                this.cbpUid = _tmp_cbpUid
                this.cbpLct = _tmp_cbpLct
                this.cbpPictureUri = _tmp_cbpPictureUri
                this.cbpThumbnailUri = _tmp_cbpThumbnailUri
              }
            }
            if(!_tmp_ContentEntry_isAllNull) {
              this.contentEntry = ContentEntry().apply {
                this.contentEntryUid = _tmp_contentEntryUid
                this.title = _tmp_title
                this.description = _tmp_description
                this.entryId = _tmp_entryId
                this.author = _tmp_author
                this.publisher = _tmp_publisher
                this.licenseType = _tmp_licenseType
                this.licenseName = _tmp_licenseName
                this.licenseUrl = _tmp_licenseUrl
                this.sourceUrl = _tmp_sourceUrl
                this.thumbnailUrl = _tmp_thumbnailUrl
                this.lastModified = _tmp_lastModified
                this.primaryLanguageUid = _tmp_primaryLanguageUid
                this.languageVariantUid = _tmp_languageVariantUid
                this.contentFlags = _tmp_contentFlags
                this.leaf = _tmp_leaf
                this.publik = _tmp_publik
                this.ceInactive = _tmp_ceInactive
                this.completionCriteria = _tmp_completionCriteria
                this.minScore = _tmp_minScore
                this.contentTypeFlag = _tmp_contentTypeFlag
                this.contentOwner = _tmp_contentOwner
                this.contentOwnerType = _tmp_contentOwnerType
                this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                this.contentEntryLct = _tmp_contentEntryLct
              }
            }
            if(!_tmp_ContentEntryPicture2_isAllNull) {
              this.contentEntryPicture2 = ContentEntryPicture2().apply {
                this.cepUid = _tmp_cepUid
                this.cepLct = _tmp_cepLct
                this.cepPictureUri = _tmp_cepPictureUri
                this.cepThumbnailUri = _tmp_cepThumbnailUri
              }
            }
          }
        }
      }
    }
  }

  override suspend fun updateFromLineItem(
    cbUid: Long,
    active: Boolean,
    dateLastModified: Long,
    title: String,
    description: String,
    assignDate: Long,
    dueDate: Long,
    resultValueMin: Float,
    resultValueMax: Float,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE CourseBlock
      |           SET cbActive = ?,
      |               cbLct = CAST(? AS BIGINT),
      |               cbTitle = ?,
      |               cbDescription = ?,
      |               cbHideUntilDate = CAST(? AS BIGINT),
      |               cbDeadlineDate = CAST(? AS BIGINT),
      |               cbMinPoints = ?,
      |               cbMaxPoints = ?
      |         WHERE cbUid = CAST(? AS BIGINT)      
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE CourseBlock
      |           SET cbActive = ?,
      |               cbLct = ?,
      |               cbTitle = ?,
      |               cbDescription = ?,
      |               cbHideUntilDate = ?,
      |               cbDeadlineDate = ?,
      |               cbMinPoints = ?,
      |               cbMaxPoints = ?
      |         WHERE cbUid = ?      
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,dateLastModified)
      _stmt.setString(3,title)
      _stmt.setString(4,description)
      _stmt.setLong(5,assignDate)
      _stmt.setLong(6,dueDate)
      _stmt.setFloat(7,resultValueMin)
      _stmt.setFloat(8,resultValueMax)
      _stmt.setLong(9,cbUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override suspend fun findCourseBlockAndClazzUidByCbUid(cbUid: Long, accountPersonUid: Long):
      CourseBlockUidAndClazzUid? = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT CourseBlock.cbUid AS courseBlockUid, 
    |               CourseBlock.cbClazzUid AS clazzUid
    |          FROM CourseBlock
    |         WHERE cbUid = CAST(? AS BIGINT)
    |           AND CAST(? AS BIGINT) != 0     
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT CourseBlock.cbUid AS courseBlockUid, 
    |               CourseBlock.cbClazzUid AS clazzUid
    |          FROM CourseBlock
    |         WHERE cbUid = ?
    |           AND ? != 0     
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,cbUid)
    _stmt.setLong(2,accountPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzUid = _result.getLong("clazzUid")
        val _tmp_courseBlockUid = _result.getLong("courseBlockUid")
        CourseBlockUidAndClazzUid().apply {
          this.clazzUid = _tmp_clazzUid
          this.courseBlockUid = _tmp_courseBlockUid
        }
      }
    }
  }
}
