package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Schedule
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ScheduleDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ScheduleDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ScheduleDao() {
  override fun insert(entity: Schedule): Long {
    val _result = _repo.withRepoChangeMonitor("Schedule") {
      _dao.insert(entity)
    }
    return _result
  }

  override suspend fun updateAsync(entity: Schedule): Int {
    val _result = _repo.withRepoChangeMonitorAsync("Schedule") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override suspend fun upsertListAsync(entities: List<Schedule>) {
    _repo.withRepoChangeMonitorAsync("Schedule") {
      _dao.upsertListAsync(entities)
    }
  }

  override suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("Schedule") {
      _dao.updateScheduleActivated(scheduleUid, active, changeTime)
    }
  }

  override fun findByUid(uid: Long): Schedule? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): Schedule? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override fun findAllSchedulesByClazzUid(clazzUid: Long): PagingSource<Int, Schedule> {
    val _result = _dao.findAllSchedulesByClazzUid(clazzUid)
    return _result
  }

  override fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule> {
    val _result = _dao.findAllSchedulesByClazzUidAsList(clazzUid)
    return _result
  }

  override fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long): Flow<List<Schedule>> {
    val _result = _dao.findAllSchedulesByClazzUidAsLiveList(clazzUid)
    return _result
  }

  override suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule> {
    val _result = _dao.findAllSchedulesByClazzUidAsync(clazzUid)
    return _result
  }

  public override suspend fun insertAsync(entity: Schedule): Long {
    val _result = _repo.withRepoChangeMonitorAsync("Schedule") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<Schedule>) {
    _repo.withRepoChangeMonitor("Schedule") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: Schedule) {
    _repo.withRepoChangeMonitor("Schedule") {
      _dao.update(entity)
    }
  }
}
