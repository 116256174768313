package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class LanguageDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: LanguageDao,
) : LanguageDao() {
  override suspend fun insertListAsync(languageList: List<Language>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    languageList.iterator().forEach  {
      if(it.langUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(13)
        it.langUid = _newPk
        _generatedPks += _newPk
      }
      it.langLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(languageList)
  }

  override fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      PagingSource<Int, Language> = _dao.findLanguagesAsSource(sortOrder, searchText)

  override fun findLanguagesList(): List<Language> = throw
      IllegalStateException("findLanguagesList: synchronous db access is NOT possible on Javascript!")

  override fun findByName(name: String): Language? = throw
      IllegalStateException("findByName: synchronous db access is NOT possible on Javascript!")

  override fun findByTwoCode(langCode: String): Language? = throw
      IllegalStateException("findByTwoCode: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByTwoCodeAsync(langCode: String): Language? =
      _dao.findByTwoCodeAsync(langCode)

  override fun findByThreeCode(langCode: String): Language? = throw
      IllegalStateException("findByThreeCode: synchronous db access is NOT possible on Javascript!")

  override fun totalLanguageCount(): Int = throw
      IllegalStateException("totalLanguageCount: synchronous db access is NOT possible on Javascript!")

  override fun update(entity: Language): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")

  override fun findByUid(primaryLanguageUid: Long): Language? = throw
      IllegalStateException("findByUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUidAsync(primaryLanguageUid: Long): Language? =
      _dao.findByUidAsync(primaryLanguageUid)

  override suspend fun updateAsync(entity: Language): Int {
    entity.langLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override fun findAllLanguageLive(): Flow<List<Language>> = _dao.findAllLanguageLive()

  override fun findByUidList(uidList: List<Long>): List<Long> = throw
      IllegalStateException("findByUidList: synchronous db access is NOT possible on Javascript!")

  override suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ) {
    _dao.toggleVisibilityLanguage(toggleVisibility, selectedItem, updateTime)
  }

  override fun replaceList(entityList: List<Language>): Unit = throw
      IllegalStateException("replaceList: synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: Language): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Language): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.langUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(13)
      entity.langUid = _newPk
    }
    entity.langLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.langUid
  }

  public override fun insertList(entityList: List<Language>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")
}
