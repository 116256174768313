package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentEntryVersion
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ContentEntryVersionDao {
  public actual abstract suspend fun findByUidAsync(cevUid: Long): ContentEntryVersion?

  public actual abstract suspend fun insertAsync(contentEntryVersion: ContentEntryVersion): Long

  public actual abstract suspend
      fun findLatestVersionUidByContentEntryUidEntity(contentEntryUid: Long): ContentEntryVersion?

  public actual abstract fun findLatestByContentEntryUidAsFlow(contentEntryUid: Long):
      Flow<ContentEntryVersion?>

  public actual abstract suspend fun updateTransferJobItemEtag(entityUid: Long,
      transferJobItemUid: Int)

  public actual abstract suspend fun findContentEntryVersionsWithoutCacheLock():
      List<ContentEntryVersion>
}
