package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class SiteTermsDao : OneToManyJoinDao<SiteTerms> {
  public actual abstract suspend fun findSiteTerms(langCode: String): SiteTerms?

  public actual abstract suspend fun insertAsync(siteTerms: SiteTerms): Long

  public actual abstract suspend fun findByUidAsync(uid: Long): SiteTerms?

  public actual abstract fun findAllTermsAsListFlow(activeOnly: Int): Flow<List<SiteTerms>>

  public actual abstract suspend fun findAllTerms(activeOnly: Int): List<SiteTerms>

  public actual abstract suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage>

  public actual abstract suspend fun upsertList(termList: List<SiteTerms>)

  public actual abstract suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long,
  )

  public actual abstract suspend fun findAvailableSiteTermLanguages(active: Int): List<String?>

  public actual abstract suspend fun findLatestByLanguage(lang: String): SiteTerms?
}
