package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.StatementEntityJson
import kotlin.Long
import kotlin.collections.List

public class StatementEntityJsonDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StatementEntityJsonDao,
) : StatementEntityJsonDao() {
  override suspend fun insertOrIgnoreListAsync(entityList: List<StatementEntityJson>) {
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreListAsync(entityList)
  }

  override suspend fun getStatements(stmtJsonIdHi: Long, stmtJsonIdLo: Long):
      List<StatementEntityJson> = _dao.getStatements(stmtJsonIdHi, stmtJsonIdLo)
}
