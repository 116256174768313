package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonPicture
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class PersonPictureDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonPictureDao,
) : PersonPictureDao() {
  override suspend fun upsert(personPicture: PersonPicture) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(personPicture.personPictureUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(50)
      personPicture.personPictureUid = _newPk
    }
    personPicture.personPictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsert(personPicture)
  }

  override suspend fun findByPersonUidAsync(personUid: Long): PersonPicture? =
      _dao.findByPersonUidAsync(personUid)

  override fun findByPersonUidLive(personUid: Long): Flow<PersonPicture?> =
      _dao.findByPersonUidLive(personUid)

  override fun findByPersonUidAsFlow(personUid: Long): Flow<PersonPicture?> =
      _dao.findByPersonUidAsFlow(personUid)

  override suspend fun updateAsync(personPicture: PersonPicture) {
    personPicture.personPictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(personPicture)
  }

  override suspend fun updateLct(uid: Long, time: Long) {
    _dao.updateLct(uid, time)
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _dao.updateUri(uid, uri, thumbnailUri, time)
  }

  override suspend fun updateTransferJobItemEtag(entityUid: Long, transferJobItemUid: Int) {
    _dao.updateTransferJobItemEtag(entityUid, transferJobItemUid)
  }

  public override fun insert(entity: PersonPicture): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: PersonPicture): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.personPictureUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(50)
      entity.personPictureUid = _newPk
    }
    entity.personPictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.personPictureUid
  }

  public override fun insertList(entityList: List<PersonPicture>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: PersonPicture): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
