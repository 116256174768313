package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryPicture
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ContentEntryPictureDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentEntryPictureDao,
) : ContentEntryPictureDao() {
  override suspend fun findByContentEntryUidAsync(entryUid: Long): ContentEntryPicture? =
      _dao.findByContentEntryUidAsync(entryUid)

  override fun findByContentEntryUidLive(entryUid: Long): Flow<ContentEntryPicture?> =
      _dao.findByContentEntryUidLive(entryUid)

  override suspend fun updateAsync(ContentEntryPicture: ContentEntryPicture) {
    ContentEntryPicture.cepTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(ContentEntryPicture)
  }

  public override fun insert(entity: ContentEntryPicture): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentEntryPicture): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cepUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(138)
      entity.cepUid = _newPk
    }
    entity.cepTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cepUid
  }

  public override fun insertList(entityList: List<ContentEntryPicture>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContentEntryPicture): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
