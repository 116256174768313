package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ScopedGrant
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ScopedGrantDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ScopedGrantDao() {
  public val _insertAdapterScopedGrant_abort: EntityInsertionAdapter<ScopedGrant> = object :
      EntityInsertionAdapter<ScopedGrant>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ScopedGrant (sgUid, sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ScopedGrant) {
      if(entity.sgUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.sgUid)
      }
      stmt.setLong(2, entity.sgPcsn)
      stmt.setLong(3, entity.sgLcsn)
      stmt.setInt(4, entity.sgLcb)
      stmt.setLong(5, entity.sgLct)
      stmt.setInt(6, entity.sgTableId)
      stmt.setLong(7, entity.sgEntityUid)
      stmt.setLong(8, entity.sgPermissions)
      stmt.setLong(9, entity.sgGroupUid)
      stmt.setInt(10, entity.sgIndex)
      stmt.setInt(11, entity.sgFlags)
    }
  }

  override suspend fun insertAsync(scopedGrant: ScopedGrant): Long {
    val _retVal = _insertAdapterScopedGrant_abort.insertAndReturnIdAsync(scopedGrant)
    return _retVal
  }

  override suspend fun insertListAsync(scopedGrantList: List<ScopedGrant>) {
    _insertAdapterScopedGrant_abort.insertListAsync(scopedGrantList)
  }
}
