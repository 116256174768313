package com.ustadmobile.core.db

import com.ustadmobile.door.ext.DoorDatabaseMetadata
import com.ustadmobile.door.util.DoorJsImplClasses
import kotlin.reflect.KClass

public object UmAppDatabaseJsImplementations : DoorJsImplClasses<UmAppDatabase>() {
  override val dbKClass: KClass<UmAppDatabase> = UmAppDatabase::class

  override val dbImplKClass: KClass<*> = UmAppDatabase_JdbcImpl::class

  override val replicateWrapperImplClass: KClass<*>? = UmAppDatabase_DoorWrapper::class

  override val repositoryImplClass: KClass<*>? = UmAppDatabase_Repo::class

  override val metadata: DoorDatabaseMetadata<UmAppDatabase> = UmAppDatabase_DoorMetadata()
}
