package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAuth
import kotlin.Int
import kotlin.Long
import kotlin.String

public actual abstract class PersonAuthDao : BaseDao<PersonAuth> {
  public actual abstract suspend fun findByUidAsync(uid: Long): PersonAuth?

  public actual abstract fun findByUid(uid: Long): PersonAuth?

  public actual abstract fun findPersonByUsername(username: String): Person?

  public actual abstract suspend fun updateAsync(entity: PersonAuth): Int

  public actual abstract suspend fun updatePasswordForPersonUid(personUid: Long,
      passwordHash: String): Int
}
