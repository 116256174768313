package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.StatementContextActivityJoin
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class StatementContextActivityJoinDao {
  public actual abstract suspend
      fun insertOrIgnoreListAsync(entities: List<StatementContextActivityJoin>)

  public actual abstract suspend fun findAllByStatementId(
    statementIdHi: Long,
    statementIdLo: Long,
    scajContextType: Int,
  ): List<StatementContextActivityJoin>
}
