package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.ActivityInteractionEntity
import kotlin.Long
import kotlin.collections.List

public class ActivityInteractionDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ActivityInteractionDao,
) : ActivityInteractionDao() {
  override suspend fun insertOrIgnoreAsync(entities: List<ActivityInteractionEntity>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.aieLastMod = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreAsync(entities)
  }

  override suspend fun findActivityUidsWithInteractionEntitiesAsync(activityUids: List<Long>):
      List<Long> = _dao.findActivityUidsWithInteractionEntitiesAsync(activityUids)

  override suspend fun findAllByActivityUidAsync(activityUid: Long): List<ActivityInteractionEntity>
      = _dao.findAllByActivityUidAsync(activityUid)
}
