package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.CoursePicture
import kotlin.Long
import kotlin.String

public actual abstract class CoursePictureDao : ImageDao {
  public actual abstract suspend fun upsertAsync(coursePicture: CoursePicture)

  actual abstract override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  )
}
