package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import kotlin.Unit

public actual fun CourseAssignmentMark.shallowCopy(block: CourseAssignmentMark.() -> Unit):
    CourseAssignmentMark = CourseAssignmentMark().also {
  it.camUid = this.camUid
  it.camAssignmentUid = this.camAssignmentUid
  it.camSubmitterUid = this.camSubmitterUid
  it.camMarkerSubmitterUid = this.camMarkerSubmitterUid
  it.camMarkerPersonUid = this.camMarkerPersonUid
  it.camMarkerComment = this.camMarkerComment
  it.camMark = this.camMark
  it.camMaxMark = this.camMaxMark
  it.camPenalty = this.camPenalty
  it.camLct = this.camLct
  it.camClazzUid = this.camClazzUid
  block(it)
}
