package com.ustadmobile.core.db.dao

import com.ustadmobile.door.entities.OutgoingReplication
import kotlin.collections.List

public actual abstract class OutgoingReplicationDao {
  public actual abstract suspend fun insert(outgoing: List<OutgoingReplication>)

  public actual abstract suspend fun listReplications(): List<OutgoingReplication>
}
