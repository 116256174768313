package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryParentChildJoinDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentEntryParentChildJoinDao,
) : ContentEntryParentChildJoinDao() {
  override suspend fun insertListAsync(entityList: List<ContentEntryParentChildJoin>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.cepcjUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(7)
        it.cepcjUid = _newPk
        _generatedPks += _newPk
      }
      it.cepcjLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  override fun publicContentEntryParentChildJoins(): List<ContentEntryParentChildJoin> = throw
      IllegalStateException("publicContentEntryParentChildJoins: synchronous db access is NOT possible on Javascript!")

  override fun all(): List<ContentEntryParentChildJoin> = throw
      IllegalStateException("all: synchronous db access is NOT possible on Javascript!")

  override fun findParentByChildUuids(childEntryContentUid: Long): ContentEntryParentChildJoin? =
      throw
      IllegalStateException("findParentByChildUuids: synchronous db access is NOT possible on Javascript!")

  override fun findListOfParentsByChildUuid(childEntryContentUid: Long):
      List<ContentEntryParentChildJoin> = throw
      IllegalStateException("findListOfParentsByChildUuid: synchronous db access is NOT possible on Javascript!")

  override suspend fun findListOfChildsByParentUuid(parentUid: Long):
      List<ContentEntryParentChildJoin> = _dao.findListOfChildsByParentUuid(parentUid)

  override fun findJoinByParentChildUuids(parentUid: Long, childUid: Long):
      ContentEntryParentChildJoin? = throw
      IllegalStateException("findJoinByParentChildUuids: synchronous db access is NOT possible on Javascript!")

  override suspend fun selectTopEntries(): List<ContentEntry> = _dao.selectTopEntries()

  override fun replaceList(entries: List<ContentEntryParentChildJoin>): Unit = throw
      IllegalStateException("replaceList: synchronous db access is NOT possible on Javascript!")

  override fun insertWithReplace(parentChildJoinDao: ContentEntryParentChildJoin): Unit = throw
      IllegalStateException("insertWithReplace: synchronous db access is NOT possible on Javascript!")

  override suspend fun moveListOfEntriesToNewParent(
    contentEntryUid: Long,
    selectedItems: List<Long>,
    updateTime: Long,
  ) {
    _dao.moveListOfEntriesToNewParent(contentEntryUid, selectedItems, updateTime)
  }

  override suspend fun setEntriesDeleted(
    selectedUids: List<Long>,
    isDeleted: Boolean,
    updateTime: Long,
  ) {
    _dao.setEntriesDeleted(selectedUids, isDeleted, updateTime)
  }

  override suspend fun findByUid(uid: Long): ContentEntryParentChildJoin? = _dao.findByUid(uid)

  public override fun insert(entity: ContentEntryParentChildJoin): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentEntryParentChildJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cepcjUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(7)
      entity.cepcjUid = _newPk
    }
    entity.cepcjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cepcjUid
  }

  public override fun insertList(entityList: List<ContentEntryParentChildJoin>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContentEntryParentChildJoin): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
