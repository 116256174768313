package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseGroupSet
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CourseGroupSetDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseGroupSetDao,
) : CourseGroupSetDao() {
  override suspend fun updateAsync(entity: CourseGroupSet): Int {
    entity.cgsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override fun findAllCourseGroupSetForClazz(
    clazzUid: Long,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, CourseGroupSet> = _dao.findAllCourseGroupSetForClazz(clazzUid, searchText,
      sortOrder)

  override fun findAllCourseGroupSetForClazzList(clazzUid: Long): List<CourseGroupSet> = throw
      IllegalStateException("findAllCourseGroupSetForClazzList: synchronous db access is NOT possible on Javascript!")

  override suspend fun findAllCourseGroupSetForClazzListAsync(clazzUid: Long): List<CourseGroupSet>
      = _dao.findAllCourseGroupSetForClazzListAsync(clazzUid)

  override suspend fun findByUidAsync(uid: Long): CourseGroupSet? = _dao.findByUidAsync(uid)

  override fun findByUidAsFlow(uid: Long): Flow<CourseGroupSet?> = _dao.findByUidAsFlow(uid)

  override suspend fun upsertAsync(entity: CourseGroupSet) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cgsUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(242)
      entity.cgsUid = _newPk
    }
    entity.cgsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsertAsync(entity)
  }

  public override fun insert(entity: CourseGroupSet): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseGroupSet): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cgsUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(242)
      entity.cgsUid = _newPk
    }
    entity.cgsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cgsUid
  }

  public override fun insertList(entityList: List<CourseGroupSet>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseGroupSet): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
