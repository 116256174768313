package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Site
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public class SiteDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: SiteDao,
) : SiteDao() {
  override fun getSite(): Site? = throw
      IllegalStateException("getSite: synchronous db access is NOT possible on Javascript!")

  override suspend fun getSiteAsync(): Site? = _dao.getSiteAsync()

  override suspend fun getSiteAuthSaltAsync(): String? = _dao.getSiteAuthSaltAsync()

  override fun getSiteAsFlow(): Flow<Site?> = _dao.getSiteAsFlow()

  override suspend fun replaceAsync(site: Site): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(site.siteUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(189)
      site.siteUid = _newPk
    }
    site.siteLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.replaceAsync(site)
    return site.siteUid
  }

  override fun insert(site: Site): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  override suspend fun updateAsync(workspace: Site) {
    workspace.siteLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(workspace)
  }
}
