package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class SiteTermsDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: SiteTermsDao,
) : SiteTermsDao() {
  override suspend fun findSiteTerms(langCode: String): SiteTerms? = _dao.findSiteTerms(langCode)

  override suspend fun insertAsync(siteTerms: SiteTerms): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(siteTerms.sTermsUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(272)
      siteTerms.sTermsUid = _newPk
    }
    siteTerms.sTermsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(siteTerms)
    return siteTerms.sTermsUid
  }

  override suspend fun findByUidAsync(uid: Long): SiteTerms? = _dao.findByUidAsync(uid)

  override fun findAllTermsAsListFlow(activeOnly: Int): Flow<List<SiteTerms>> =
      _dao.findAllTermsAsListFlow(activeOnly)

  override suspend fun findAllTerms(activeOnly: Int): List<SiteTerms> =
      _dao.findAllTerms(activeOnly)

  override suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage> =
      _dao.findAllWithLanguageAsList()

  override suspend fun upsertList(termList: List<SiteTerms>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    termList.iterator().forEach  {
      if(it.sTermsUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(272)
        it.sTermsUid = _newPk
        _generatedPks += _newPk
      }
      it.sTermsLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertList(termList)
  }

  override suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _dao.updateActiveByUid(sTermsUid, active, changeTime)
  }

  override suspend fun findAvailableSiteTermLanguages(active: Int): List<String?> =
      _dao.findAvailableSiteTermLanguages(active)

  override suspend fun findLatestByLanguage(lang: String): SiteTerms? =
      _dao.findLatestByLanguage(lang)

  public override suspend fun insertListAsync(entityList: List<SiteTerms>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.sTermsUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(272)
        it.sTermsUid = _newPk
        _generatedPks += _newPk
      }
      it.sTermsLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<SiteTerms>) {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.sTermsLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
