package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LanguageVariant
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class LanguageVariantDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: LanguageVariantDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : LanguageVariantDao() {
  override fun findByCode(countryCode: String): LanguageVariant? {
    val _result = _dao.findByCode(countryCode)
    return _result
  }

  public override fun insert(entity: LanguageVariant): Long {
    val _result = _repo.withRepoChangeMonitor("LanguageVariant") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: LanguageVariant): Long {
    val _result = _repo.withRepoChangeMonitorAsync("LanguageVariant") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<LanguageVariant>) {
    _repo.withRepoChangeMonitor("LanguageVariant") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: LanguageVariant) {
    _repo.withRepoChangeMonitor("LanguageVariant") {
      _dao.update(entity)
    }
  }
}
