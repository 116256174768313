package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.LanguageVariant
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class LanguageVariantDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: LanguageVariantDao,
) : LanguageVariantDao() {
  override fun findByCode(countryCode: String): LanguageVariant? = throw
      IllegalStateException("findByCode: synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: LanguageVariant): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: LanguageVariant): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.langVariantUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(10)
      entity.langVariantUid = _newPk
    }
    entity.langVariantLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.langVariantUid
  }

  public override fun insertList(entityList: List<LanguageVariant>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: LanguageVariant): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
