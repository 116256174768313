package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CoursePicture
import kotlin.Long
import kotlin.String

public class CoursePictureDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CoursePictureDao,
) : CoursePictureDao() {
  override suspend fun upsertAsync(coursePicture: CoursePicture) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(coursePicture.coursePictureUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(125)
      coursePicture.coursePictureUid = _newPk
    }
    coursePicture.coursePictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsertAsync(coursePicture)
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _dao.updateUri(uid, uri, thumbnailUri, time)
  }
}
