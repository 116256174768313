package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentCategorySchema
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentCategorySchemaDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentCategorySchemaDao,
) : ContentCategorySchemaDao() {
  override fun publicContentCategorySchemas(): List<ContentCategorySchema> = throw
      IllegalStateException("publicContentCategorySchemas: synchronous db access is NOT possible on Javascript!")

  override fun findBySchemaUrl(schemaUrl: String): ContentCategorySchema? = throw
      IllegalStateException("findBySchemaUrl: synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: ContentCategorySchema): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentCategorySchema): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.contentCategorySchemaUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(2)
      entity.contentCategorySchemaUid = _newPk
    }
    entity.contentCategorySchemaLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.contentCategorySchemaUid
  }

  public override fun insertList(entityList: List<ContentCategorySchema>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContentCategorySchema): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
