package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.CourseTerminology
import kotlin.Unit

public actual fun CourseTerminology.shallowCopy(block: CourseTerminology.() -> Unit):
    CourseTerminology = CourseTerminology().also {
  it.ctUid = this.ctUid
  it.ctTitle = this.ctTitle
  it.ctTerminology = this.ctTerminology
  it.ctLct = this.ctLct
  block(it)
}
