package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.StatementContextActivityJoin
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public class StatementContextActivityJoinDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StatementContextActivityJoinDao,
) : StatementContextActivityJoinDao() {
  override suspend fun insertOrIgnoreListAsync(entities: List<StatementContextActivityJoin>) {
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreListAsync(entities)
  }

  override suspend fun findAllByStatementId(
    statementIdHi: Long,
    statementIdLo: Long,
    scajContextType: Int,
  ): List<StatementContextActivityJoin> = _dao.findAllByStatementId(statementIdHi, statementIdLo,
      scajContextType)
}
