package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.ActivityLangMapEntry
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ActivityLangMapEntryDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ActivityLangMapEntryDao,
) : ActivityLangMapEntryDao() {
  override suspend fun upsertList(entities: List<ActivityLangMapEntry>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.almeLastMod = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertList(entities)
  }

  override suspend fun upsertIfInteractionEntityExists(
    almeActivityUid: Long,
    almeHash: Long,
    almeLangCode: String?,
    almeValue: String?,
    almeAieHash: Long,
    almeLastMod: Long,
  ) {
    _dao.upsertIfInteractionEntityExists(almeActivityUid, almeHash, almeLangCode, almeValue,
        almeAieHash, almeLastMod)
  }

  override suspend fun updateIfChanged(
    almeActivityUid: Long,
    almeHash: Long,
    almeValue: String?,
    almeLastMod: Long,
  ) {
    _dao.updateIfChanged(almeActivityUid, almeHash, almeValue, almeLastMod)
  }

  override suspend fun findAllByActivityUid(activityUid: Long): List<ActivityLangMapEntry> =
      _dao.findAllByActivityUid(activityUid)
}
