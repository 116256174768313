package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.LeavingReason
import kotlin.Unit

public actual fun LeavingReason.shallowCopy(block: LeavingReason.() -> Unit): LeavingReason =
    LeavingReason().also {
  it.leavingReasonUid = this.leavingReasonUid
  it.leavingReasonTitle = this.leavingReasonTitle
  it.leavingReasonMCSN = this.leavingReasonMCSN
  it.leavingReasonCSN = this.leavingReasonCSN
  it.leavingReasonLCB = this.leavingReasonLCB
  it.leavingReasonLct = this.leavingReasonLct
  block(it)
}
