package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseGroupSet
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseGroupSetDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseGroupSetDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseGroupSetDao() {
  override suspend fun updateAsync(entity: CourseGroupSet): Int {
    val _result = _repo.withRepoChangeMonitorAsync("CourseGroupSet") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override fun findAllCourseGroupSetForClazz(
    clazzUid: Long,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, CourseGroupSet> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "CourseGroupSetDao/findAllCourseGroupSetForClazz",
    dbPagingSource = _dao.findAllCourseGroupSetForClazz(clazzUid, searchText, sortOrder),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "CourseGroupSetDao/findAllCourseGroupSetForClazz") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseGroupSetDao/findAllCourseGroupSetForClazz")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("searchText", _repo.config.json.encodeToString(String.serializer(), searchText))
          parameter("sortOrder", _repo.config.json.encodeToString(Int.serializer(), sortOrder))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override fun findAllCourseGroupSetForClazzList(clazzUid: Long): List<CourseGroupSet> {
    val _result = _dao.findAllCourseGroupSetForClazzList(clazzUid)
    return _result
  }

  override suspend fun findAllCourseGroupSetForClazzListAsync(clazzUid: Long):
      List<CourseGroupSet> {
    val _result = _dao.findAllCourseGroupSetForClazzListAsync(clazzUid)
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): CourseGroupSet? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "CourseGroupSetDao/findByUidAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "CourseGroupSetDao/findByUidAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override fun findByUidAsFlow(uid: Long): Flow<CourseGroupSet?> {
    val _result = _dao.findByUidAsFlow(uid)
    return _result
  }

  override suspend fun upsertAsync(entity: CourseGroupSet) {
    _repo.withRepoChangeMonitorAsync("CourseGroupSet") {
      _dao.upsertAsync(entity)
    }
  }

  public override fun insert(entity: CourseGroupSet): Long {
    val _result = _repo.withRepoChangeMonitor("CourseGroupSet") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: CourseGroupSet): Long {
    val _result = _repo.withRepoChangeMonitorAsync("CourseGroupSet") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<CourseGroupSet>) {
    _repo.withRepoChangeMonitor("CourseGroupSet") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: CourseGroupSet) {
    _repo.withRepoChangeMonitor("CourseGroupSet") {
      _dao.update(entity)
    }
  }
}
