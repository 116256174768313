package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.composites.CourseAssignmentSubmissionFileAndTransferJob
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionFile
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class CourseAssignmentSubmissionFileDao :
    BaseDao<CourseAssignmentSubmissionFile> {
  public actual abstract suspend
      fun insertListAsync(entityList: List<CourseAssignmentSubmissionFile>)

  public actual abstract fun getBySubmissionUid(submissionUid: Long):
      Flow<List<CourseAssignmentSubmissionFileAndTransferJob>>

  public actual abstract fun getByAssignmentUidAndPersonUid(accountPersonUid: Long,
      assignmentUid: Long): Flow<List<CourseAssignmentSubmissionFileAndTransferJob>>

  public actual abstract fun getAllSubmissionFilesFromSubmitterAsFlow(submitterUid: Long,
      assignmentUid: Long): Flow<List<CourseAssignmentSubmissionFileAndTransferJob>>

  public actual abstract suspend fun updateUri(
    casaUid: Long,
    uri: String,
    updateTime: Long,
  )

  public actual abstract suspend fun setDeleted(
    casaUid: Long,
    deleted: Boolean,
    updateTime: Long,
  )
}
