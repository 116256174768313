package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.lib.db.entities.Report
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ReportDao : BaseDao<Report> {
  public actual abstract fun getResults(query: DoorQuery): List<Report>

  public actual abstract fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): PagingSource<Int, Report>

  public actual abstract suspend fun findByUid(entityUid: Long): Report?

  public actual abstract suspend fun updateAsync(entity: Report)

  public actual abstract fun findByUidLive(uid: Long): Flow<Report?>

  public actual abstract fun findAllActiveReportLive(isTemplate: Boolean): Flow<List<Report>>

  public actual abstract fun findAllActiveReportList(isTemplate: Boolean): List<Report>

  public actual abstract fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  )

  public actual abstract fun replaceList(entityList: List<Report>)
}
