package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CoursePicture
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CoursePictureDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CoursePictureDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CoursePictureDao() {
  override suspend fun upsertAsync(coursePicture: CoursePicture) {
    _repo.withRepoChangeMonitorAsync("CoursePicture") {
      _dao.upsertAsync(coursePicture)
    }
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("CoursePicture") {
      _dao.updateUri(uid, uri, thumbnailUri, time)
    }
  }
}
