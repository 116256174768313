package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.PersonPicture
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public actual abstract class PersonPictureDao : BaseDao<PersonPicture>, ImageDao {
  public actual abstract suspend fun upsert(personPicture: PersonPicture)

  public actual abstract suspend fun findByPersonUidAsync(personUid: Long): PersonPicture?

  public actual abstract fun findByPersonUidLive(personUid: Long): Flow<PersonPicture?>

  public actual abstract fun findByPersonUidAsFlow(personUid: Long): Flow<PersonPicture?>

  public actual abstract suspend fun updateAsync(personPicture: PersonPicture)

  public actual abstract suspend fun updateLct(uid: Long, time: Long)

  actual abstract override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  )

  public actual abstract suspend fun updateTransferJobItemEtag(entityUid: Long,
      transferJobItemUid: Int)
}
