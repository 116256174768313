package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.CourseAssignmentMarkAndMarkerName
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.AverageCourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseAssignmentMarkWithPersonMarker
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CourseAssignmentMarkDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseAssignmentMarkDao,
) : CourseAssignmentMarkDao() {
  override fun checkNoSubmissionsMarked(assignmentUid: Long): Flow<Boolean> =
      _dao.checkNoSubmissionsMarked(assignmentUid)

  override fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long, submitterUid: Long):
      Flow<AverageCourseAssignmentMark?> =
      _dao.getMarkOfAssignmentForSubmitterLiveData(assignmentUid, submitterUid)

  override fun getAllMarksForUserAsFlow(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentMarkAndMarkerName>> =
      _dao.getAllMarksForUserAsFlow(accountPersonUid, assignmentUid)

  override fun getAllMarksForSubmitterAsFlow(submitterUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentMarkAndMarkerName>> =
      _dao.getAllMarksForSubmitterAsFlow(submitterUid, assignmentUid)

  override suspend fun getAllMarksForSubmitterAsFlowMarkerPersons(submitterUid: Long,
      assignmentUid: Long): List<PersonAndPicture> =
      _dao.getAllMarksForSubmitterAsFlowMarkerPersons(submitterUid, assignmentUid)

  override fun getAllMarksOfAssignmentForSubmitter(
    assignmentUid: Long,
    submitterUid: Long,
    filter: Int,
  ): PagingSource<Int, CourseAssignmentMarkWithPersonMarker> =
      _dao.getAllMarksOfAssignmentForSubmitter(assignmentUid, submitterUid, filter)

  override fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark? = throw
      IllegalStateException("getMarkOfAssignmentForStudent: synchronous db access is NOT possible on Javascript!")

  override suspend fun findNextSubmitterToMarkForAssignment(
    assignmentUid: Long,
    submitterUid: Long,
    markerUid: Long,
  ): Long = _dao.findNextSubmitterToMarkForAssignment(assignmentUid, submitterUid, markerUid)

  public override fun insert(entity: CourseAssignmentMark): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseAssignmentMark): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.camUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(523)
      entity.camUid = _newPk
    }
    entity.camLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.camUid
  }

  public override fun insertList(entityList: List<CourseAssignmentMark>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseAssignmentMark): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
