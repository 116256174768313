package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class HolidayCalendarDao : BaseDao<HolidayCalendar> {
  public actual abstract fun findAllHolidaysWithEntriesCount():
      PagingSource<Int, HolidayCalendarWithNumEntries>

  public actual abstract fun replaceList(list: List<HolidayCalendar>)

  public actual abstract fun findAllHolidaysLiveData(): Flow<List<HolidayCalendar>>

  public actual abstract fun findByUidLive(uid: Long): Flow<HolidayCalendar?>

  public actual abstract suspend fun updateAsync(entity: HolidayCalendar): Int

  public actual abstract suspend fun findByUid(uid: Long): HolidayCalendar?

  public actual abstract suspend fun findByUidAsync(uid: Long): HolidayCalendar?
}
