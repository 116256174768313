package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.ActivityInteractionEntity
import kotlin.Long
import kotlin.collections.List

public actual abstract class ActivityInteractionDao {
  public actual abstract suspend fun insertOrIgnoreAsync(entities: List<ActivityInteractionEntity>)

  public actual abstract suspend
      fun findActivityUidsWithInteractionEntitiesAsync(activityUids: List<Long>): List<Long>

  public actual abstract suspend fun findAllByActivityUidAsync(activityUid: Long):
      List<ActivityInteractionEntity>
}
