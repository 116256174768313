package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonGroupMember
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonGroupMemberDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonGroupMemberDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : PersonGroupMemberDao() {
  override suspend fun findAllGroupWherePersonIsIn(personUid: Long): List<PersonGroupMember> {
    val _result = _dao.findAllGroupWherePersonIsIn(personUid)
    return _result
  }

  override suspend fun checkPersonBelongsToGroup(groupUid: Long, personUid: Long):
      List<PersonGroupMember> {
    val _result = _dao.checkPersonBelongsToGroup(groupUid, personUid)
    return _result
  }

  override suspend fun moveGroupAsync(
    personUid: Long,
    newGroup: Long,
    oldGroup: Long,
    changeTime: Long,
  ): Int {
    val _result = _repo.withRepoChangeMonitorAsync("PersonGroupMember") {
      _dao.moveGroupAsync(personUid, newGroup, oldGroup, changeTime)
    }
    return _result
  }

  override suspend fun updateGroupMemberActive(
    activeStatus: Boolean,
    personUid: Long,
    groupUid: Long,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("PersonGroupMember") {
      _dao.updateGroupMemberActive(activeStatus, personUid, groupUid, updateTime)
    }
  }

  override suspend fun findByPersonUidAndGroupUid(personUid: Long, groupUid: Long):
      PersonGroupMember? {
    val _result = _dao.findByPersonUidAndGroupUid(personUid, groupUid)
    return _result
  }

  public override fun insert(entity: PersonGroupMember): Long {
    val _result = _repo.withRepoChangeMonitor("PersonGroupMember") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: PersonGroupMember): Long {
    val _result = _repo.withRepoChangeMonitorAsync("PersonGroupMember") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<PersonGroupMember>) {
    _repo.withRepoChangeMonitor("PersonGroupMember") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: PersonGroupMember) {
    _repo.withRepoChangeMonitor("PersonGroupMember") {
      _dao.update(entity)
    }
  }
}
