package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.PersonGroupMember
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class PersonGroupMemberDao : BaseDao<PersonGroupMember> {
  public actual abstract suspend fun findAllGroupWherePersonIsIn(personUid: Long):
      List<PersonGroupMember>

  public actual abstract suspend fun checkPersonBelongsToGroup(groupUid: Long, personUid: Long):
      List<PersonGroupMember>

  public actual abstract suspend fun moveGroupAsync(
    personUid: Long,
    newGroup: Long,
    oldGroup: Long,
    changeTime: Long,
  ): Int

  public actual abstract suspend fun updateGroupMemberActive(
    activeStatus: Boolean,
    personUid: Long,
    groupUid: Long,
    updateTime: Long,
  )

  public actual abstract suspend fun findByPersonUidAndGroupUid(personUid: Long, groupUid: Long):
      PersonGroupMember?
}
