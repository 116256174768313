package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.DiscussionPostAndPosterNames
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class DiscussionPostDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: DiscussionPostDao,
) : DiscussionPostDao() {
  override fun getTopLevelPostsByCourseBlockUid(courseBlockUid: Long, includeDeleted: Boolean):
      PagingSource<Int, DiscussionPostWithDetails> =
      _dao.getTopLevelPostsByCourseBlockUid(courseBlockUid, includeDeleted)

  override suspend fun getTopLevelPostsByCourseBlockUidPersons(courseBlockUid: Long):
      List<PersonAndPicture> = _dao.getTopLevelPostsByCourseBlockUidPersons(courseBlockUid)

  override suspend fun getTopLevelPostsByCourseBlockUidLatestMessage(courseBlockUid: Long):
      List<DiscussionPost> = _dao.getTopLevelPostsByCourseBlockUidLatestMessage(courseBlockUid)

  override suspend fun getPostTitle(postUid: Long): String? = _dao.getPostTitle(postUid)

  override suspend fun findByUid(uid: Long): DiscussionPost? = _dao.findByUid(uid)

  override fun getTitleByUidAsFlow(uid: Long): Flow<String?> = _dao.getTitleByUidAsFlow(uid)

  override suspend fun updateAsync(entity: DiscussionPost): Int {
    entity.discussionPostLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override fun findByPostIdWithAllReplies(postUid: Long, includeDeleted: Boolean):
      PagingSource<Int, DiscussionPostAndPosterNames> = _dao.findByPostIdWithAllReplies(postUid,
      includeDeleted)

  override suspend fun findByPostIdWithAllRepliesPersons(postUid: Long): List<PersonAndPicture> =
      _dao.findByPostIdWithAllRepliesPersons(postUid)

  override suspend fun upsertAsync(entity: DiscussionPost) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.discussionPostUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(132)
      entity.discussionPostUid = _newPk
    }
    entity.discussionPostLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsertAsync(entity)
  }

  override suspend fun setDeletedAsync(
    uid: Long,
    deleted: Boolean,
    updateTime: Long,
  ) {
    _dao.setDeletedAsync(uid, deleted, updateTime)
  }

  public override fun insert(entity: DiscussionPost): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: DiscussionPost): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.discussionPostUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(132)
      entity.discussionPostUid = _newPk
    }
    entity.discussionPostLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.discussionPostUid
  }

  public override fun insertList(entityList: List<DiscussionPost>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: DiscussionPost): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
