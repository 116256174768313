package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ExternalAppPermission
import kotlin.Int
import kotlin.Long
import kotlin.String

public actual abstract class ExternalAppPermissionDao {
  public actual abstract suspend fun insertAsync(externalAppPermission: ExternalAppPermission): Long

  public actual abstract suspend fun getGrantedAuthToken(
    packageId: String,
    personUid: Long,
    currentTime: Long,
  ): String?

  public actual abstract suspend fun getExternalAccessPermissionByUid(eapUid: Int):
      ExternalAppPermission?

  public actual abstract suspend fun getPersonUidByAuthToken(token: String, currentTime: Long): Long
}
