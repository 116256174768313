package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.CourseGroupSet
import kotlin.Unit

public actual fun CourseGroupSet.shallowCopy(block: CourseGroupSet.() -> Unit): CourseGroupSet =
    CourseGroupSet().also {
  it.cgsUid = this.cgsUid
  it.cgsName = this.cgsName
  it.cgsTotalGroups = this.cgsTotalGroups
  it.cgsActive = this.cgsActive
  it.cgsClazzUid = this.cgsClazzUid
  it.cgsLct = this.cgsLct
  block(it)
}
