package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.paging.DoorLimitOffsetPagingSource
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class LanguageDao_JdbcImpl(
  public val _db: RoomDatabase,
) : LanguageDao() {
  public val _insertAdapterLanguage_abort: EntityInsertionAdapter<Language> = object :
      EntityInsertionAdapter<Language>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Language) {
      if(entity.langUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.langUid)
      }
      stmt.setString(2, entity.name)
      stmt.setString(3, entity.iso_639_1_standard)
      stmt.setString(4, entity.iso_639_2_standard)
      stmt.setString(5, entity.iso_639_3_standard)
      stmt.setString(6, entity.Language_Type)
      stmt.setBoolean(7, entity.languageActive)
      stmt.setLong(8, entity.langLocalChangeSeqNum)
      stmt.setLong(9, entity.langMasterChangeSeqNum)
      stmt.setInt(10, entity.langLastChangedBy)
      stmt.setLong(11, entity.langLct)
    }
  }

  public val _insertAdapterLanguage_upsert: EntityInsertionAdapter<Language> = object :
      EntityInsertionAdapter<Language>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Language) {
      if(entity.langUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.langUid)
      }
      stmt.setString(2, entity.name)
      stmt.setString(3, entity.iso_639_1_standard)
      stmt.setString(4, entity.iso_639_2_standard)
      stmt.setString(5, entity.iso_639_3_standard)
      stmt.setString(6, entity.Language_Type)
      stmt.setBoolean(7, entity.languageActive)
      stmt.setLong(8, entity.langLocalChangeSeqNum)
      stmt.setLong(9, entity.langMasterChangeSeqNum)
      stmt.setInt(10, entity.langLastChangedBy)
      stmt.setLong(11, entity.langLct)
    }
  }

  override suspend fun insertListAsync(languageList: List<Language>) {
    _insertAdapterLanguage_abort.insertListAsync(languageList)
  }

  override fun replaceList(entityList: List<Language>) {
    _insertAdapterLanguage_upsert.insertList(entityList)
  }

  public override fun insert(entity: Language): Long {
    val _retVal = _insertAdapterLanguage_abort.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Language): Long {
    val _retVal = _insertAdapterLanguage_abort.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Language>) {
    _insertAdapterLanguage_abort.insertList(entityList)
  }

  override fun update(entity: Language) {
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.name)
      _stmt.setString(2, entity.iso_639_1_standard)
      _stmt.setString(3, entity.iso_639_2_standard)
      _stmt.setString(4, entity.iso_639_3_standard)
      _stmt.setString(5, entity.Language_Type)
      _stmt.setBoolean(6, entity.languageActive)
      _stmt.setLong(7, entity.langLocalChangeSeqNum)
      _stmt.setLong(8, entity.langMasterChangeSeqNum)
      _stmt.setInt(9, entity.langLastChangedBy)
      _stmt.setLong(10, entity.langLct)
      _stmt.setLong(11, entity.langUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun updateAsync(entity: Language): Int {
    var _result = 0
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.name)
      _stmt.setString(2, entity.iso_639_1_standard)
      _stmt.setString(3, entity.iso_639_2_standard)
      _stmt.setString(4, entity.iso_639_3_standard)
      _stmt.setString(5, entity.Language_Type)
      _stmt.setBoolean(6, entity.languageActive)
      _stmt.setLong(7, entity.langLocalChangeSeqNum)
      _stmt.setLong(8, entity.langMasterChangeSeqNum)
      _stmt.setInt(9, entity.langLastChangedBy)
      _stmt.setLong(10, entity.langLct)
      _stmt.setLong(11, entity.langUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  override fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      PagingSource<Int, Language> = object : DoorLimitOffsetPagingSource<Language>(db = _db
  , tableNames = arrayOf("Language")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<Language> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (
      |        SELECT Language.* 
      |        FROM Language
      |        WHERE name LIKE ?
      |        ORDER BY CASE(?)
      |            WHEN 1 THEN Language.name 
      |            WHEN 3 THEN Language.iso_639_1_standard 
      |            WHEN 5 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Language.name 
      |            WHEN 4 THEN Language.iso_639_1_standard 
      |            WHEN 6 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END DESC
      |    ) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setString(1,searchText)
      _stmt.setInt(2,sortOrder)
      _stmt.setInt(3,sortOrder)
      _stmt.setInt(4,_limit)
      _stmt.setInt(5,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_langUid = _result.getLong("langUid")
          val _tmp_name = _result.getString("name")
          val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
          val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
          val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
          val _tmp_Language_Type = _result.getString("Language_Type")
          val _tmp_languageActive = _result.getBoolean("languageActive")
          val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
          val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
          val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
          val _tmp_langLct = _result.getLong("langLct")
          Language().apply {
            this.langUid = _tmp_langUid
            this.name = _tmp_name
            this.iso_639_1_standard = _tmp_iso_639_1_standard
            this.iso_639_2_standard = _tmp_iso_639_2_standard
            this.iso_639_3_standard = _tmp_iso_639_3_standard
            this.Language_Type = _tmp_Language_Type
            this.languageActive = _tmp_languageActive
            this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
            this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
            this.langLastChangedBy = _tmp_langLastChangedBy
            this.langLct = _tmp_langLct
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (
      |        SELECT Language.* 
      |        FROM Language
      |        WHERE name LIKE ?
      |        ORDER BY CASE(?)
      |            WHEN 1 THEN Language.name 
      |            WHEN 3 THEN Language.iso_639_1_standard 
      |            WHEN 5 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Language.name 
      |            WHEN 4 THEN Language.iso_639_1_standard 
      |            WHEN 6 THEN Language.iso_639_2_standard 
      |            ELSE ''
      |        END DESC
      |    ) AS _PagingCount
      """.trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setString(1,searchText)
      _stmt.setInt(2,sortOrder)
      _stmt.setInt(3,sortOrder)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override fun findLanguagesList(): List<Language> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM Language",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  override fun findByName(name: String): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM Language WHERE name = ? LIMIT 1",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,name)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  override fun findByTwoCode(langCode: String): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,langCode)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  override suspend fun findByTwoCodeAsync(langCode: String): Language? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,langCode)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  override fun findByThreeCode(langCode: String): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM LANGUAGE WHERE iso_639_3_standard = ? OR iso_639_2_standard = ? LIMIT 1 ",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,langCode)
    _stmt.setString(2,langCode)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  override fun totalLanguageCount(): Int = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT COUNT(*) FROM LANGUAGE",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  override fun findByUid(primaryLanguageUid: Long): Language? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT *  FROM LANGUAGE where langUid = CAST(? AS BIGINT) LIMIT 1",
    postgreSql = """
    |SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,primaryLanguageUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  override suspend fun findByUidAsync(primaryLanguageUid: Long): Language? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT *  FROM LANGUAGE where langUid = CAST(? AS BIGINT) LIMIT 1",
    postgreSql = """
    |SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,primaryLanguageUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_langUid = _result.getLong("langUid")
        val _tmp_name = _result.getString("name")
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        val _tmp_Language_Type = _result.getString("Language_Type")
        val _tmp_languageActive = _result.getBoolean("languageActive")
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        val _tmp_langLct = _result.getLong("langLct")
        Language().apply {
          this.langUid = _tmp_langUid
          this.name = _tmp_name
          this.iso_639_1_standard = _tmp_iso_639_1_standard
          this.iso_639_2_standard = _tmp_iso_639_2_standard
          this.iso_639_3_standard = _tmp_iso_639_3_standard
          this.Language_Type = _tmp_Language_Type
          this.languageActive = _tmp_languageActive
          this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
          this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
          this.langLastChangedBy = _tmp_langLastChangedBy
          this.langLct = _tmp_langLct
        }
      }
    }
  }

  override fun findAllLanguageLive(): Flow<List<Language>> = _db.doorFlow(arrayOf("LANGUAGE")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * FROM LANGUAGE",
      readOnly = true,)
    ) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_langUid = _result.getLong("langUid")
          val _tmp_name = _result.getString("name")
          val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
          val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
          val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
          val _tmp_Language_Type = _result.getString("Language_Type")
          val _tmp_languageActive = _result.getBoolean("languageActive")
          val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
          val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
          val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
          val _tmp_langLct = _result.getLong("langLct")
          Language().apply {
            this.langUid = _tmp_langUid
            this.name = _tmp_name
            this.iso_639_1_standard = _tmp_iso_639_1_standard
            this.iso_639_2_standard = _tmp_iso_639_2_standard
            this.iso_639_3_standard = _tmp_iso_639_3_standard
            this.Language_Type = _tmp_Language_Type
            this.languageActive = _tmp_languageActive
            this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
            this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
            this.langLastChangedBy = _tmp_langLastChangedBy
            this.langLct = _tmp_langLct
          }
        }
      }
    }
  }

  override fun findByUidList(uidList: List<Long>): List<Long> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT langUid FROM LANGUAGE WHERE langUid IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  override suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE Language 
      |           SET languageActive = ?, 
      |               langLct = CAST(? AS BIGINT)
      |         WHERE langUid IN (?)
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |
      |        UPDATE Language 
      |           SET languageActive = ?, 
      |               langLct = ?
      |         WHERE langUid IN (?)
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,toggleVisibility)
      _stmt.setLong(2,updateTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedItem.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
