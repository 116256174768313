package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryPicture2
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ContentEntryPicture2Dao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContentEntryPicture2Dao() {
  public val _insertAdapterContentEntryPicture2_upsert: EntityInsertionAdapter<ContentEntryPicture2>
      = object : EntityInsertionAdapter<ContentEntryPicture2>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO ContentEntryPicture2 (cepUid, cepLct, cepPictureUri, cepThumbnailUri) VALUES(?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentEntryPicture2) {
      stmt.setLong(1, entity.cepUid)
      stmt.setLong(2, entity.cepLct)
      stmt.setString(3, entity.cepPictureUri)
      stmt.setString(4, entity.cepThumbnailUri)
    }
  }

  override suspend fun upsertListAsync(entities: List<ContentEntryPicture2>) {
    _insertAdapterContentEntryPicture2_upsert.insertListAsync(entities)
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE ContentEntryPicture2
      |           SET cepPictureUri = ?,
      |               cepThumbnailUri = ?,
      |               cepLct = CAST(? AS BIGINT)
      |         WHERE cepUid = CAST(? AS BIGINT)  
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE ContentEntryPicture2
      |           SET cepPictureUri = ?,
      |               cepThumbnailUri = ?,
      |               cepLct = ?
      |         WHERE cepUid = ?  
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setString(1,uri)
      _stmt.setString(2,thumbnailUri)
      _stmt.setLong(3,time)
      _stmt.setLong(4,uid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
