package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import kotlin.Unit

public actual
    fun ClazzEnrolmentWithLeavingReason.shallowCopy(block: ClazzEnrolmentWithLeavingReason.() -> Unit):
    ClazzEnrolmentWithLeavingReason = ClazzEnrolmentWithLeavingReason().also {
  it.leavingReason = this.leavingReason
  it.timeZone = this.timeZone
  it.clazzEnrolmentUid = this.clazzEnrolmentUid
  it.clazzEnrolmentPersonUid = this.clazzEnrolmentPersonUid
  it.clazzEnrolmentClazzUid = this.clazzEnrolmentClazzUid
  it.clazzEnrolmentDateJoined = this.clazzEnrolmentDateJoined
  it.clazzEnrolmentDateLeft = this.clazzEnrolmentDateLeft
  it.clazzEnrolmentRole = this.clazzEnrolmentRole
  it.clazzEnrolmentAttendancePercentage = this.clazzEnrolmentAttendancePercentage
  it.clazzEnrolmentActive = this.clazzEnrolmentActive
  it.clazzEnrolmentLeavingReasonUid = this.clazzEnrolmentLeavingReasonUid
  it.clazzEnrolmentOutcome = this.clazzEnrolmentOutcome
  it.clazzEnrolmentLocalChangeSeqNum = this.clazzEnrolmentLocalChangeSeqNum
  it.clazzEnrolmentMasterChangeSeqNum = this.clazzEnrolmentMasterChangeSeqNum
  it.clazzEnrolmentLastChangedBy = this.clazzEnrolmentLastChangedBy
  it.clazzEnrolmentLct = this.clazzEnrolmentLct
  block(it)
}
