package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.ContentEntry
import kotlin.Unit

public actual fun ContentEntry.shallowCopy(block: ContentEntry.() -> Unit): ContentEntry =
    ContentEntry().also {
  it.contentEntryUid = this.contentEntryUid
  it.title = this.title
  it.description = this.description
  it.entryId = this.entryId
  it.author = this.author
  it.publisher = this.publisher
  it.licenseType = this.licenseType
  it.licenseName = this.licenseName
  it.licenseUrl = this.licenseUrl
  it.sourceUrl = this.sourceUrl
  it.thumbnailUrl = this.thumbnailUrl
  it.lastModified = this.lastModified
  it.primaryLanguageUid = this.primaryLanguageUid
  it.languageVariantUid = this.languageVariantUid
  it.contentFlags = this.contentFlags
  it.leaf = this.leaf
  it.publik = this.publik
  it.ceInactive = this.ceInactive
  it.completionCriteria = this.completionCriteria
  it.minScore = this.minScore
  it.contentTypeFlag = this.contentTypeFlag
  it.contentOwner = this.contentOwner
  it.contentOwnerType = this.contentOwnerType
  it.contentEntryLocalChangeSeqNum = this.contentEntryLocalChangeSeqNum
  it.contentEntryMasterChangeSeqNum = this.contentEntryMasterChangeSeqNum
  it.contentEntryLastChangedBy = this.contentEntryLastChangedBy
  it.contentEntryLct = this.contentEntryLct
  block(it)
}
