package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.GroupMemberActorJoin
import kotlin.Long
import kotlin.collections.List

public actual abstract class GroupMemberActorJoinDao {
  public actual abstract suspend fun insertOrIgnoreListAsync(entities: List<GroupMemberActorJoin>)

  public actual abstract suspend fun upsertListAsync(entities: List<GroupMemberActorJoin>)

  public actual abstract suspend fun updateLastModifiedTimeIfNeededAsync(
    gmajGroupActorUid: Long,
    gmajMemberActorUid: Long,
    lastModTime: Long,
  )
}
