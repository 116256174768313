package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.composites.MessageAndOtherPerson
import com.ustadmobile.lib.db.entities.Message
import kotlin.Int
import kotlin.Long
import kotlin.String

public actual abstract class MessageDao {
  public actual abstract fun messagesFromOtherUserAsPagingSource(accountPersonUid: Long,
      otherPersonUid: Long): PagingSource<Int, Message>

  public actual abstract fun conversationsForUserAsPagingSource(searchQuery: String,
      accountPersonUid: Long): PagingSource<Int, MessageAndOtherPerson>

  public actual abstract suspend fun insert(message: Message)
}
