package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.ActivityEntity
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class ActivityEntityDao {
  public actual abstract suspend fun insertOrIgnoreAsync(entities: List<ActivityEntity>)

  public actual abstract suspend fun updateIfMoreInfoChanged(
    activityUid: Long,
    actMoreInfo: String?,
    actLct: Long,
  )

  public actual abstract suspend fun updateIfNotYetDefined(
    actUid: Long,
    actType: String?,
    actMoreInfo: String?,
    actInteractionType: Int,
    actCorrectResponsePatterns: String?,
  )

  public actual abstract suspend fun findByUidAsync(activityUid: Long): ActivityEntity?
}
