package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonPicture
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonPictureDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonPictureDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : PersonPictureDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun upsert(personPicture: PersonPicture) {
    _repo.withRepoChangeMonitorAsync("PersonPicture") {
      _dao.upsert(personPicture)
    }
  }

  override suspend fun findByPersonUidAsync(personUid: Long): PersonPicture? {
    val _result = _dao.findByPersonUidAsync(personUid)
    return _result
  }

  override fun findByPersonUidLive(personUid: Long): Flow<PersonPicture?> =
      repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByPersonUidLive(personUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath = "PersonPictureDao/findByPersonUidLive") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "PersonPictureDao/findByPersonUidLive")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("personUid", _repo.config.json.encodeToString(Long.serializer(), personUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override fun findByPersonUidAsFlow(personUid: Long): Flow<PersonPicture?> {
    val _result = _dao.findByPersonUidAsFlow(personUid)
    return _result
  }

  override suspend fun updateAsync(personPicture: PersonPicture) {
    _repo.withRepoChangeMonitorAsync("PersonPicture") {
      _dao.updateAsync(personPicture)
    }
  }

  override suspend fun updateLct(uid: Long, time: Long) {
    _repo.withRepoChangeMonitorAsync("PersonPicture") {
      _dao.updateLct(uid, time)
    }
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("PersonPicture") {
      _dao.updateUri(uid, uri, thumbnailUri, time)
    }
  }

  override suspend fun updateTransferJobItemEtag(entityUid: Long, transferJobItemUid: Int) {
    _repo.withRepoChangeMonitorAsync("TransferJobItem") {
      _dao.updateTransferJobItemEtag(entityUid, transferJobItemUid)
    }
  }

  public override fun insert(entity: PersonPicture): Long {
    val _result = _repo.withRepoChangeMonitor("PersonPicture") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: PersonPicture): Long {
    val _result = _repo.withRepoChangeMonitorAsync("PersonPicture") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<PersonPicture>) {
    _repo.withRepoChangeMonitor("PersonPicture") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: PersonPicture) {
    _repo.withRepoChangeMonitor("PersonPicture") {
      _dao.update(entity)
    }
  }
}
