package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.VerbLangMapEntry
import kotlin.Long
import kotlin.collections.List

public class VerbLangMapEntryDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: VerbLangMapEntryDao,
) : VerbLangMapEntryDao() {
  override suspend fun upsertList(list: List<VerbLangMapEntry>) {
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      it.vlmeLastModified = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertList(list)
  }

  override suspend fun findByVerbUidAsync(verbUid: Long): List<VerbLangMapEntry> =
      _dao.findByVerbUidAsync(verbUid)
}
