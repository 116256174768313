package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.UserSession
import com.ustadmobile.lib.db.entities.UserSessionAndPerson
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class UserSessionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: UserSessionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : UserSessionDao() {
  override suspend fun insertSession(session: UserSession): Long {
    val _result = _repo.withRepoChangeMonitorAsync("UserSession") {
      _dao.insertSession(session)
    }
    return _result
  }

  override suspend fun findSessionsByPerson(personUid: Long): List<UserSession> {
    val _result = _dao.findSessionsByPerson(personUid)
    return _result
  }

  override fun findAllLocalSessionsLive(): Flow<List<UserSessionAndPerson>> {
    val _result = _dao.findAllLocalSessionsLive()
    return _result
  }

  override suspend fun findAllLocalSessionsAsync(): List<UserSessionAndPerson> {
    val _result = _dao.findAllLocalSessionsAsync()
    return _result
  }

  override suspend fun findLocalSessionByUsername(username: String?): UserSessionAndPerson? {
    val _result = _dao.findLocalSessionByUsername(username)
    return _result
  }

  override suspend fun countAllLocalSessionsAsync(maxDateOfBirth: Long): Int {
    val _result = _dao.countAllLocalSessionsAsync(maxDateOfBirth)
    return _result
  }

  override suspend fun endSession(
    sessionUid: Long,
    newStatus: Int,
    reason: Int,
    endTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("UserSession") {
      _dao.endSession(sessionUid, newStatus, reason, endTime)
    }
  }

  override fun findByUidLive(sessionUid: Long): Flow<UserSession?> {
    val _result = _dao.findByUidLive(sessionUid)
    return _result
  }

  override suspend fun endOtherSessions(
    personUid: Long,
    exemptNodeId: Long,
    newStatus: Int,
    reason: Int,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("UserSession") {
      _dao.endOtherSessions(personUid, exemptNodeId, newStatus, reason, changeTime)
    }
  }

  override suspend fun findActiveNodeIdsByPersonUids(personUids: List<Long>): List<Long> {
    val _result = _dao.findActiveNodeIdsByPersonUids(personUids)
    return _result
  }

  override suspend fun findActiveNodesIdsByGroupUids(groupUids: List<Long>): List<Long> {
    val _result = _dao.findActiveNodesIdsByGroupUids(groupUids)
    return _result
  }

  override suspend fun countActiveSessionsForUserAndNode(personUid: Long, nodeId: Long): Int {
    val _result = _dao.countActiveSessionsForUserAndNode(personUid, nodeId)
    return _result
  }
}
