package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.composites.TransferJobAndTotals
import com.ustadmobile.lib.db.entities.TransferJob
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class TransferJobDao {
  public actual abstract suspend fun insert(job: TransferJob): Long

  public actual abstract suspend fun findByUid(jobUid: Int): TransferJob?

  public actual abstract fun findByUidAsFlow(jobUid: Int): Flow<TransferJob?>

  public actual abstract suspend fun updateStatus(jobUid: Int, status: Int)

  public actual abstract suspend fun getJobStatus(jobUid: Int): Int

  public actual abstract suspend fun updateStatusIfComplete(jobUid: Int): Int

  public actual abstract suspend fun findJobByEntityAndTableUid(tableId: Int, entityUid: Long):
      List<TransferJob>

  public actual abstract fun findByContentEntryUidWithTotalsAsFlow(contentEntryUid: Long,
      jobType: Int): Flow<List<TransferJobAndTotals>>

  public actual abstract suspend fun findOfflineItemUidForTransferJobUid(jobUid: Int): Long
}
