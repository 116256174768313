package com.ustadmobile.core.db.dao

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.StudentResultAndCourseBlockSourcedId
import com.ustadmobile.lib.db.entities.StudentResult
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class StudentResultDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StudentResultDao,
) : StudentResultDao() {
  override suspend fun insertListAsync(list: List<StudentResult>) {
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      it.srLastModified = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(list)
  }

  override suspend fun upsertAsync(studentResult: StudentResult) {
    studentResult.srLastModified = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsertAsync(studentResult)
  }

  override suspend fun findByClazzAndStudent(
    clazzUid: Long,
    studentPersonUid: Long,
    accountPersonUid: Long,
  ): List<StudentResultAndCourseBlockSourcedId> = _dao.findByClazzAndStudent(clazzUid,
      studentPersonUid, accountPersonUid)

  override suspend fun findUidBySourcedId(sourcedId: String): Long =
      _dao.findUidBySourcedId(sourcedId)

  override suspend fun existsByUid(srUid: Long): Boolean = _dao.existsByUid(srUid)
}
