package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.StateDeleteCommand
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StateDeleteCommandDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StateDeleteCommandDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StateDeleteCommandDao() {
  override suspend fun insertAsync(deleteCommand: StateDeleteCommand) {
    _repo.withRepoChangeMonitorAsync("StateDeleteCommand") {
      _dao.insertAsync(deleteCommand)
    }
  }
}
