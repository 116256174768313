package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Holiday
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class HolidayDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: HolidayDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : HolidayDao() {
  override fun findByHolidayCalendaUid(holidayCalendarUid: Long): List<Holiday> {
    val _result = _dao.findByHolidayCalendaUid(holidayCalendarUid)
    return _result
  }

  override suspend fun findByHolidayCalendaUidAsync(holidayCalendarUid: Long): List<Holiday> {
    val _result = _dao.findByHolidayCalendaUidAsync(holidayCalendarUid)
    return _result
  }

  override fun updateActiveByUid(
    holidayUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitor("Holiday") {
      _dao.updateActiveByUid(holidayUid, active, changeTime)
    }
  }

  override suspend fun updateAsync(entity: Holiday) {
    _repo.withRepoChangeMonitorAsync("Holiday") {
      _dao.updateAsync(entity)
    }
  }

  public override fun insert(entity: Holiday): Long {
    val _result = _repo.withRepoChangeMonitor("Holiday") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: Holiday): Long {
    val _result = _repo.withRepoChangeMonitorAsync("Holiday") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<Holiday>) {
    _repo.withRepoChangeMonitor("Holiday") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: Holiday) {
    _repo.withRepoChangeMonitor("Holiday") {
      _dao.update(entity)
    }
  }

  public override suspend fun insertListAsync(entityList: List<Holiday>) {
    _repo.withRepoChangeMonitorAsync("Holiday") {
      _dao.insertListAsync(entityList)
    }
  }

  public override suspend fun updateListAsync(entityList: List<Holiday>) {
    _repo.withRepoChangeMonitorAsync("Holiday") {
      _dao.updateListAsync(entityList)
    }
  }
}
