package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentEntryPicture2
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class ContentEntryPicture2Dao : ImageDao {
  public actual abstract suspend fun upsertListAsync(entities: List<ContentEntryPicture2>)

  actual abstract override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  )
}
