package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class LeavingReasonDao : BaseDao<LeavingReason> {
  public actual abstract fun findAllReasons(): PagingSource<Int, LeavingReason>

  public actual abstract fun findAllReasonsLive(): List<LeavingReason>

  public actual abstract suspend fun findAllReasonsAsync(): List<LeavingReason>

  public actual abstract suspend fun findByUidAsync(uid: Long): LeavingReason?

  public actual abstract suspend fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract fun findByUidLive(uid: Long): Flow<LeavingReason?>

  public actual abstract suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel>

  public actual abstract suspend fun replaceList(entityList: List<LeavingReason>)

  public actual abstract suspend fun updateAsync(entity: LeavingReason): Int
}
