package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.ActivityLangMapEntry
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class ActivityLangMapEntryDao {
  public actual abstract suspend fun upsertList(entities: List<ActivityLangMapEntry>)

  public actual abstract suspend fun upsertIfInteractionEntityExists(
    almeActivityUid: Long,
    almeHash: Long,
    almeLangCode: String?,
    almeValue: String?,
    almeAieHash: Long,
    almeLastMod: Long,
  )

  public actual abstract suspend fun updateIfChanged(
    almeActivityUid: Long,
    almeHash: Long,
    almeValue: String?,
    almeLastMod: Long,
  )

  public actual abstract suspend fun findAllByActivityUid(activityUid: Long):
      List<ActivityLangMapEntry>
}
