package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.EnrolmentRequestAndCoursePic
import com.ustadmobile.lib.db.composites.EnrolmentRequestAndPersonDetails
import com.ustadmobile.lib.db.entities.EnrolmentRequest
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class EnrolmentRequestDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: EnrolmentRequestDao,
) : EnrolmentRequestDao() {
  override suspend fun insert(enrolmentRequest: EnrolmentRequest) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(enrolmentRequest.erUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(10070)
      enrolmentRequest.erUid = _newPk
    }
    enrolmentRequest.erLastModified = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insert(enrolmentRequest)
  }

  override suspend fun findByClazzAndPerson(
    personUid: Long,
    clazzUid: Long,
    statusFilter: Int,
  ): List<EnrolmentRequest> = _dao.findByClazzAndPerson(personUid, clazzUid, statusFilter)

  override suspend fun hasPendingRequests(personUid: Long, clazzUid: Long): Boolean =
      _dao.hasPendingRequests(personUid, clazzUid)

  override fun findRequestsForUserAsFlow(accountPersonUid: Long, statusFilter: Int):
      Flow<List<EnrolmentRequestAndCoursePic>> = _dao.findRequestsForUserAsFlow(accountPersonUid,
      statusFilter)

  override suspend fun updateStatus(
    uid: Long,
    status: Int,
    updateTime: Long,
  ) {
    _dao.updateStatus(uid, status, updateTime)
  }

  override fun findPendingEnrolmentsForCourse(
    clazzUid: Long,
    includeDeleted: Boolean,
    statusFilter: Int,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, EnrolmentRequestAndPersonDetails> =
      _dao.findPendingEnrolmentsForCourse(clazzUid, includeDeleted, statusFilter, searchText,
      sortOrder)
}
