package com.ustadmobile.core.db.dao

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryPicture2
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ContentEntryPicture2Dao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentEntryPicture2Dao,
) : ContentEntryPicture2Dao() {
  override suspend fun upsertListAsync(entities: List<ContentEntryPicture2>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.cepLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entities)
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _dao.updateUri(uid, uri, thumbnailUri, time)
  }
}
