package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PeerReviewerAllocation
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PeerReviewerAllocationDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PeerReviewerAllocationDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : PeerReviewerAllocationDao() {
  override suspend fun replaceListAsync(entries: List<PeerReviewerAllocation>) {
    _repo.withRepoChangeMonitorAsync("PeerReviewerAllocation") {
      _dao.replaceListAsync(entries)
    }
  }

  override suspend fun getAllPeerReviewerAllocations(assignmentUid: List<Long>):
      List<PeerReviewerAllocation> {
    val _result = _dao.getAllPeerReviewerAllocations(assignmentUid)
    return _result
  }

  override suspend fun getAllPeerReviewerAllocationsByClazzUid(clazzUid: Long,
      includeInactive: Boolean): List<PeerReviewerAllocation> {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "PeerReviewerAllocationDao/getAllPeerReviewerAllocationsByClazzUid") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config,
            "PeerReviewerAllocationDao/getAllPeerReviewerAllocationsByClazzUid")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("includeInactive", _repo.config.json.encodeToString(Boolean.serializer(),
            includeInactive))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getAllPeerReviewerAllocationsByClazzUid(clazzUid, includeInactive)
    return _result
  }

  override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("PeerReviewerAllocation") {
      _dao.updateActiveByUid(cbUid, active, changeTime)
    }
  }

  override suspend fun upsertList(entityList: List<PeerReviewerAllocation>) {
    _repo.withRepoChangeMonitorAsync("PeerReviewerAllocation") {
      _dao.upsertList(entityList)
    }
  }

  public override fun insert(entity: PeerReviewerAllocation): Long {
    val _result = _repo.withRepoChangeMonitor("PeerReviewerAllocation") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: PeerReviewerAllocation): Long {
    val _result = _repo.withRepoChangeMonitorAsync("PeerReviewerAllocation") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<PeerReviewerAllocation>) {
    _repo.withRepoChangeMonitor("PeerReviewerAllocation") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: PeerReviewerAllocation) {
    _repo.withRepoChangeMonitor("PeerReviewerAllocation") {
      _dao.update(entity)
    }
  }
}
