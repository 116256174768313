package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.composites.OfflineItemAndState
import com.ustadmobile.lib.db.entities.OfflineItem
import kotlin.Boolean
import kotlin.Long
import kotlinx.coroutines.flow.Flow

public actual abstract class OfflineItemDao {
  public actual abstract suspend fun insertAsync(item: OfflineItem): Long

  public actual abstract fun findByContentEntryUid(contentEntryUid: Long, nodeId: Long):
      Flow<OfflineItemAndState?>

  public actual abstract suspend fun updateActiveByOfflineItemUid(oiUid: Long, active: Boolean)
}
