package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.VerbEntity
import kotlin.Long
import kotlin.collections.List

public class VerbDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: VerbDao,
) : VerbDao() {
  override suspend fun insertOrIgnoreAsync(entities: List<VerbEntity>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.verbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreAsync(entities)
  }

  override suspend fun findByUid(uid: Long): VerbEntity? = _dao.findByUid(uid)
}
