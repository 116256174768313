package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.ActorUidEtagAndLastMod
import com.ustadmobile.lib.db.entities.xapi.ActorEntity
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ActorDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ActorDao,
) : ActorDao() {
  override suspend fun insertOrIgnoreListAsync(entities: List<ActorEntity>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      if(it.actorUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(68)
        it.actorUid = _newPk
        _generatedPks += _newPk
      }
      it.actorLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreListAsync(entities)
  }

  override suspend fun upsertListAsync(entities: List<ActorEntity>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      if(it.actorUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(68)
        it.actorUid = _newPk
        _generatedPks += _newPk
      }
      it.actorLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entities)
  }

  override suspend fun updateIfNameChanged(
    uid: Long,
    name: String?,
    updateTime: Long,
  ) {
    _dao.updateIfNameChanged(uid, name, updateTime)
  }

  override suspend fun findByUidAsync(uid: Long): ActorEntity? = _dao.findByUidAsync(uid)

  override suspend fun findByUidAndPersonUidAsync(actorUid: Long, accountPersonUid: Long):
      ActorEntity? = _dao.findByUidAndPersonUidAsync(actorUid, accountPersonUid)

  override suspend fun findUidAndEtagByListAsync(uidList: List<Long>): List<ActorUidEtagAndLastMod>
      = _dao.findUidAndEtagByListAsync(uidList)

  override suspend fun findGroupMembers(groupActorUid: Long): List<ActorEntity> =
      _dao.findGroupMembers(groupActorUid)
}
