package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class LanguageDao : BaseDao<Language> {
  public actual abstract suspend fun insertListAsync(languageList: List<Language>)

  public actual abstract fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      PagingSource<Int, Language>

  public actual abstract fun findLanguagesList(): List<Language>

  public actual abstract fun findByName(name: String): Language?

  public actual abstract fun findByTwoCode(langCode: String): Language?

  public actual abstract suspend fun findByTwoCodeAsync(langCode: String): Language?

  public actual abstract fun findByThreeCode(langCode: String): Language?

  public actual abstract fun totalLanguageCount(): Int

  actual abstract override fun update(entity: Language)

  public actual abstract fun findByUid(primaryLanguageUid: Long): Language?

  public actual abstract suspend fun findByUidAsync(primaryLanguageUid: Long): Language?

  public actual abstract suspend fun updateAsync(entity: Language): Int

  public actual abstract fun findAllLanguageLive(): Flow<List<Language>>

  public actual abstract fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  )

  public actual abstract fun replaceList(entityList: List<Language>)
}
