package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryPicture2
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryPicture2Dao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryPicture2Dao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentEntryPicture2Dao() {
  override suspend fun upsertListAsync(entities: List<ContentEntryPicture2>) {
    _repo.withRepoChangeMonitorAsync("ContentEntryPicture2") {
      _dao.upsertListAsync(entities)
    }
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ContentEntryPicture2") {
      _dao.updateUri(uid, uri, thumbnailUri, time)
    }
  }
}
