package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.composites.CourseAssignmentMarkAndMarkerName
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.AverageCourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseAssignmentMarkWithPersonMarker
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class CourseAssignmentMarkDao : BaseDao<CourseAssignmentMark> {
  public actual abstract fun checkNoSubmissionsMarked(assignmentUid: Long): Flow<Boolean>

  public actual abstract fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long,
      submitterUid: Long): Flow<AverageCourseAssignmentMark?>

  public actual abstract fun getAllMarksForUserAsFlow(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentMarkAndMarkerName>>

  public actual abstract fun getAllMarksForSubmitterAsFlow(submitterUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentMarkAndMarkerName>>

  public actual abstract suspend fun getAllMarksForSubmitterAsFlowMarkerPersons(submitterUid: Long,
      assignmentUid: Long): List<PersonAndPicture>

  public actual abstract fun getAllMarksOfAssignmentForSubmitter(
    assignmentUid: Long,
    submitterUid: Long,
    filter: Int,
  ): PagingSource<Int, CourseAssignmentMarkWithPersonMarker>

  public actual abstract fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark?

  public actual abstract suspend fun findNextSubmitterToMarkForAssignment(
    assignmentUid: Long,
    submitterUid: Long,
    markerUid: Long,
  ): Long
}
