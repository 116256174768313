package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.TransferJobError
import kotlin.Boolean
import kotlin.Int
import kotlin.collections.List

public actual abstract class TransferJobErrorDao {
  public actual abstract suspend fun insertAsync(error: TransferJobError)

  public actual abstract suspend fun findByJobId(jobUid: Int): List<TransferJobError>

  public actual abstract suspend fun dismissErrorByJobId(jobUid: Int, dismissed: Boolean)
}
