package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.composites.ActorUidEtagAndLastMod
import com.ustadmobile.lib.db.entities.xapi.ActorEntity
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class ActorDao {
  public actual abstract suspend fun insertOrIgnoreListAsync(entities: List<ActorEntity>)

  public actual abstract suspend fun upsertListAsync(entities: List<ActorEntity>)

  public actual abstract suspend fun updateIfNameChanged(
    uid: Long,
    name: String?,
    updateTime: Long,
  )

  public actual abstract suspend fun findByUidAsync(uid: Long): ActorEntity?

  public actual abstract suspend fun findByUidAndPersonUidAsync(actorUid: Long,
      accountPersonUid: Long): ActorEntity?

  public actual abstract suspend fun findUidAndEtagByListAsync(uidList: List<Long>):
      List<ActorUidEtagAndLastMod>

  public actual abstract suspend fun findGroupMembers(groupActorUid: Long): List<ActorEntity>
}
