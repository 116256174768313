package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CoursePicture
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class CoursePictureDao_JdbcImpl(
  public val _db: RoomDatabase,
) : CoursePictureDao() {
  public val _insertAdapterCoursePicture_upsert: EntityInsertionAdapter<CoursePicture> = object :
      EntityInsertionAdapter<CoursePicture>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO CoursePicture (coursePictureUid, coursePictureLct, coursePictureUri, coursePictureThumbnailUri, coursePictureActive) VALUES(?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CoursePicture) {
      if(entity.coursePictureUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.coursePictureUid)
      }
      stmt.setLong(2, entity.coursePictureLct)
      stmt.setString(3, entity.coursePictureUri)
      stmt.setString(4, entity.coursePictureThumbnailUri)
      stmt.setBoolean(5, entity.coursePictureActive)
    }
  }

  override suspend fun upsertAsync(coursePicture: CoursePicture) {
    _insertAdapterCoursePicture_upsert.insertAsync(coursePicture)
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE CoursePicture
      |           SET coursePictureUri = ?,
      |               coursePictureThumbnailUri = ?,
      |               coursePictureLct = CAST(? AS BIGINT)
      |        WHERE coursePictureUid = CAST(? AS BIGINT)       
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE CoursePicture
      |           SET coursePictureUri = ?,
      |               coursePictureThumbnailUri = ?,
      |               coursePictureLct = ?
      |        WHERE coursePictureUid = ?       
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setString(1,uri)
      _stmt.setString(2,thumbnailUri)
      _stmt.setLong(3,time)
      _stmt.setLong(4,uid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
