package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ScopedGrant
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ScopedGrantDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ScopedGrantDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ScopedGrantDao() {
  override suspend fun insertAsync(scopedGrant: ScopedGrant): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ScopedGrant") {
      _dao.insertAsync(scopedGrant)
    }
    return _result
  }

  override suspend fun insertListAsync(scopedGrantList: List<ScopedGrant>) {
    _repo.withRepoChangeMonitorAsync("ScopedGrant") {
      _dao.insertListAsync(scopedGrantList)
    }
  }
}
