package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.Site
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public actual abstract class SiteDao {
  public actual abstract fun getSite(): Site?

  public actual abstract suspend fun getSiteAsync(): Site?

  public actual abstract suspend fun getSiteAuthSaltAsync(): String?

  public actual abstract fun getSiteAsFlow(): Flow<Site?>

  public actual abstract suspend fun replaceAsync(site: Site): Long

  public actual abstract fun insert(site: Site): Long

  public actual abstract suspend fun updateAsync(workspace: Site)
}
