package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.StatementEntityJson
import kotlin.Long
import kotlin.collections.List

public actual abstract class StatementEntityJsonDao {
  public actual abstract suspend fun insertOrIgnoreListAsync(entityList: List<StatementEntityJson>)

  public actual abstract suspend fun getStatements(stmtJsonIdHi: Long, stmtJsonIdLo: Long):
      List<StatementEntityJson>
}
