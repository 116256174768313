package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.OfflineItemAndState
import com.ustadmobile.lib.db.entities.OfflineItem
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class OfflineItemDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: OfflineItemDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : OfflineItemDao() {
  override suspend fun insertAsync(item: OfflineItem): Long {
    val _result = _repo.withRepoChangeMonitorAsync("OfflineItem") {
      _dao.insertAsync(item)
    }
    return _result
  }

  override fun findByContentEntryUid(contentEntryUid: Long, nodeId: Long):
      Flow<OfflineItemAndState?> {
    val _result = _dao.findByContentEntryUid(contentEntryUid, nodeId)
    return _result
  }

  override suspend fun updateActiveByOfflineItemUid(oiUid: Long, active: Boolean) {
    _repo.withRepoChangeMonitorAsync("OfflineItem") {
      _dao.updateActiveByOfflineItemUid(oiUid, active)
    }
  }
}
