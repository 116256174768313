package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import kotlin.Unit

public actual
    fun CourseAssignmentSubmission.shallowCopy(block: CourseAssignmentSubmission.() -> Unit):
    CourseAssignmentSubmission = CourseAssignmentSubmission().also {
  it.casUid = this.casUid
  it.casAssignmentUid = this.casAssignmentUid
  it.casSubmitterUid = this.casSubmitterUid
  it.casSubmitterPersonUid = this.casSubmitterPersonUid
  it.casText = this.casText
  it.casType = this.casType
  it.casTimestamp = this.casTimestamp
  it.casClazzUid = this.casClazzUid
  block(it)
}
