package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.ClazzAssignment
import kotlin.Unit

public actual fun ClazzAssignment.shallowCopy(block: ClazzAssignment.() -> Unit): ClazzAssignment =
    ClazzAssignment().also {
  it.caUid = this.caUid
  it.caTitle = this.caTitle
  it.caDescription = this.caDescription
  it.caGroupUid = this.caGroupUid
  it.caActive = this.caActive
  it.caClassCommentEnabled = this.caClassCommentEnabled
  it.caPrivateCommentsEnabled = this.caPrivateCommentsEnabled
  it.caCompletionCriteria = this.caCompletionCriteria
  it.caRequireFileSubmission = this.caRequireFileSubmission
  it.caFileType = this.caFileType
  it.caSizeLimit = this.caSizeLimit
  it.caNumberOfFiles = this.caNumberOfFiles
  it.caSubmissionPolicy = this.caSubmissionPolicy
  it.caMarkingType = this.caMarkingType
  it.caRequireTextSubmission = this.caRequireTextSubmission
  it.caTextLimitType = this.caTextLimitType
  it.caTextLimit = this.caTextLimit
  it.caXObjectUid = this.caXObjectUid
  it.caClazzUid = this.caClazzUid
  it.caPeerReviewerCount = this.caPeerReviewerCount
  it.caLocalChangeSeqNum = this.caLocalChangeSeqNum
  it.caMasterChangeSeqNum = this.caMasterChangeSeqNum
  it.caLastChangedBy = this.caLastChangedBy
  it.caLct = this.caLct
  block(it)
}
