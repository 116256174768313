package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.CourseTerminology
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class CourseTerminologyDao : BaseDao<CourseTerminology> {
  public actual abstract fun findAllCourseTerminologyPagingSource():
      PagingSource<Int, CourseTerminology>

  public actual abstract fun findAllCourseTerminologyList(): List<CourseTerminology>

  public actual abstract suspend fun getTerminologyForClazz(clazzUid: Long): CourseTerminology?

  public actual abstract suspend fun getTerminologyForAssignment(assignmentUid: Long):
      CourseTerminology?

  public actual abstract suspend fun findByUidAsync(uid: Long): CourseTerminology?

  public actual abstract suspend fun updateAsync(entity: CourseTerminology): Int

  public actual abstract suspend fun upsertAsync(entity: CourseTerminology): Long
}
