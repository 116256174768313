package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonGroupMember
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PersonGroupMemberDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonGroupMemberDao,
) : PersonGroupMemberDao() {
  override suspend fun findAllGroupWherePersonIsIn(personUid: Long): List<PersonGroupMember> =
      _dao.findAllGroupWherePersonIsIn(personUid)

  override suspend fun checkPersonBelongsToGroup(groupUid: Long, personUid: Long):
      List<PersonGroupMember> = _dao.checkPersonBelongsToGroup(groupUid, personUid)

  override suspend fun moveGroupAsync(
    personUid: Long,
    newGroup: Long,
    oldGroup: Long,
    changeTime: Long,
  ): Int = _dao.moveGroupAsync(personUid, newGroup, oldGroup, changeTime)

  override suspend fun updateGroupMemberActive(
    activeStatus: Boolean,
    personUid: Long,
    groupUid: Long,
    updateTime: Long,
  ) {
    _dao.updateGroupMemberActive(activeStatus, personUid, groupUid, updateTime)
  }

  override suspend fun findByPersonUidAndGroupUid(personUid: Long, groupUid: Long):
      PersonGroupMember? = _dao.findByPersonUidAndGroupUid(personUid, groupUid)

  public override fun insert(entity: PersonGroupMember): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: PersonGroupMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.groupMemberUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(44)
      entity.groupMemberUid = _newPk
    }
    entity.groupMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.groupMemberUid
  }

  public override fun insertList(entityList: List<PersonGroupMember>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: PersonGroupMember): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
