package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Unit

public actual fun Schedule.shallowCopy(block: Schedule.() -> Unit): Schedule = Schedule().also {
  it.scheduleUid = this.scheduleUid
  it.sceduleStartTime = this.sceduleStartTime
  it.scheduleEndTime = this.scheduleEndTime
  it.scheduleDay = this.scheduleDay
  it.scheduleMonth = this.scheduleMonth
  it.scheduleFrequency = this.scheduleFrequency
  it.umCalendarUid = this.umCalendarUid
  it.scheduleClazzUid = this.scheduleClazzUid
  it.scheduleMasterChangeSeqNum = this.scheduleMasterChangeSeqNum
  it.scheduleLocalChangeSeqNum = this.scheduleLocalChangeSeqNum
  it.scheduleLastChangedBy = this.scheduleLastChangedBy
  it.scheduleLastChangedTime = this.scheduleLastChangedTime
  it.scheduleActive = this.scheduleActive
  block(it)
}
