package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.Holiday
import kotlin.Boolean
import kotlin.Long
import kotlin.collections.List

public actual abstract class HolidayDao : BaseDao<Holiday>, OneToManyJoinDao<Holiday> {
  public actual abstract fun findByHolidayCalendaUid(holidayCalendarUid: Long): List<Holiday>

  public actual abstract suspend fun findByHolidayCalendaUidAsync(holidayCalendarUid: Long):
      List<Holiday>

  public actual abstract fun updateActiveByUid(
    holidayUid: Long,
    active: Boolean,
    changeTime: Long,
  )

  public actual abstract suspend fun updateAsync(entity: Holiday)
}
