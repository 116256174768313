package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.composites.StudentResultAndCourseBlockSourcedId
import com.ustadmobile.lib.db.entities.StudentResult
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class StudentResultDao {
  public actual abstract suspend fun insertListAsync(list: List<StudentResult>)

  public actual abstract suspend fun upsertAsync(studentResult: StudentResult)

  public actual abstract suspend fun findByClazzAndStudent(
    clazzUid: Long,
    studentPersonUid: Long,
    accountPersonUid: Long,
  ): List<StudentResultAndCourseBlockSourcedId>

  public actual abstract suspend fun findUidBySourcedId(sourcedId: String): Long

  public actual abstract suspend fun existsByUid(srUid: Long): Boolean
}
