package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.ActivityExtensionEntity
import kotlin.Long
import kotlin.collections.List

public actual abstract class ActivityExtensionDao {
  public actual abstract suspend fun upsertListAsync(list: List<ActivityExtensionEntity>)

  public actual abstract suspend fun findAllByActivityUid(activityUid: Long):
      List<ActivityExtensionEntity>
}
