package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseAssignmentSubmissionDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseAssignmentSubmissionDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseAssignmentSubmissionDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun insertListAsync(entityList: List<CourseAssignmentSubmission>) {
    _repo.withRepoChangeMonitorAsync("CourseAssignmentSubmission") {
      _dao.insertListAsync(entityList)
    }
  }

  override fun getAllSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long):
      PagingSource<Int, CourseAssignmentSubmission> {
    val _result = _dao.getAllSubmissionsFromSubmitter(assignmentUid, submitterUid)
    return _result
  }

  override fun getAllSubmissionsForUser(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmission>> {
    val _result = _dao.getAllSubmissionsForUser(accountPersonUid, assignmentUid)
    return _result
  }

  override fun getAllSubmissionsFromSubmitterAsFlow(submitterUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmission>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.getAllSubmissionsFromSubmitterAsFlow(submitterUid, assignmentUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseAssignmentSubmissionDao/getAllSubmissionsFromSubmitterAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "CourseAssignmentSubmissionDao/getAllSubmissionsFromSubmitterAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("submitterUid", _repo.config.json.encodeToString(Long.serializer(),
              submitterUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun getLatestSubmissionForUserAsync(accountPersonUid: Long, assignmentUid: Long):
      CourseAssignmentSubmission? {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "CourseAssignmentSubmissionDao/getLatestSubmissionForUserAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "CourseAssignmentSubmissionDao/getLatestSubmissionForUserAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
            assignmentUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getLatestSubmissionForUserAsync(accountPersonUid, assignmentUid)
    return _result
  }

  override suspend fun doesUserHaveSubmissions(accountPersonUid: Long, assignmentUid: Long):
      Boolean {
    val _result = _dao.doesUserHaveSubmissions(accountPersonUid, assignmentUid)
    return _result
  }

  override suspend fun countFileSubmissionFromStudent(assignmentUid: Long, submitterUid: Long):
      Int {
    val _result = _dao.countFileSubmissionFromStudent(assignmentUid, submitterUid)
    return _result
  }

  override suspend fun countSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long): Int {
    val _result = _dao.countSubmissionsFromSubmitter(assignmentUid, submitterUid)
    return _result
  }

  override fun getStatusOfAssignmentForSubmitter(assignmentUid: Long, submitterUid: Long):
      Flow<Int> {
    val _result = _dao.getStatusOfAssignmentForSubmitter(assignmentUid, submitterUid)
    return _result
  }

  override suspend fun findLastSubmissionFromStudent(submitterUid: Long, assignmentUid: Long):
      CourseAssignmentSubmission? {
    val _result = _dao.findLastSubmissionFromStudent(submitterUid, assignmentUid)
    return _result
  }

  override fun checkNoSubmissionsMade(assignmentUid: Long): Flow<Boolean> {
    val _result = _dao.checkNoSubmissionsMade(assignmentUid)
    return _result
  }

  override suspend fun checkNoSubmissionsMadeAsync(assignmentUid: Long): Boolean {
    val _result = _dao.checkNoSubmissionsMadeAsync(assignmentUid)
    return _result
  }

  override fun checkNoSubmissionsMadeFlow(assignmentUid: Long): Flow<Boolean> {
    val _result = _dao.checkNoSubmissionsMadeFlow(assignmentUid)
    return _result
  }

  override fun findByUidAsFlow(submissionUid: Long): Flow<CourseAssignmentSubmission?> =
      repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByUidAsFlow(submissionUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseAssignmentSubmissionDao/findByUidAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseAssignmentSubmissionDao/findByUidAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("submissionUid", _repo.config.json.encodeToString(Long.serializer(),
              submissionUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override fun findByAssignmentUidAndAccountPersonUid(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmission>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByAssignmentUidAndAccountPersonUid(accountPersonUid, assignmentUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseAssignmentSubmissionDao/findByAssignmentUidAndAccountPersonUid") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "CourseAssignmentSubmissionDao/findByAssignmentUidAndAccountPersonUid")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  public override fun insert(entity: CourseAssignmentSubmission): Long {
    val _result = _repo.withRepoChangeMonitor("CourseAssignmentSubmission") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: CourseAssignmentSubmission): Long {
    val _result = _repo.withRepoChangeMonitorAsync("CourseAssignmentSubmission") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<CourseAssignmentSubmission>) {
    _repo.withRepoChangeMonitor("CourseAssignmentSubmission") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: CourseAssignmentSubmission) {
    _repo.withRepoChangeMonitor("CourseAssignmentSubmission") {
      _dao.update(entity)
    }
  }
}
