package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.CacheLockJoinDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryImportJobDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryVersionDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockPictureDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CoursePermissionDao
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.DeletedItemDao
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.EnrolmentRequestDao
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ExternalAppPermissionDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.OfflineItemDao
import com.ustadmobile.core.db.dao.OutgoingReplicationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.StudentResultDao
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.SystemPermissionDao
import com.ustadmobile.core.db.dao.TransferJobDao
import com.ustadmobile.core.db.dao.TransferJobErrorDao
import com.ustadmobile.core.db.dao.TransferJobItemDao
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.xapi.ActivityEntityDao
import com.ustadmobile.core.db.dao.xapi.ActivityExtensionDao
import com.ustadmobile.core.db.dao.xapi.ActivityInteractionDao
import com.ustadmobile.core.db.dao.xapi.ActivityLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.ActorDao
import com.ustadmobile.core.db.dao.xapi.GroupMemberActorJoinDao
import com.ustadmobile.core.db.dao.xapi.StateDeleteCommandDao
import com.ustadmobile.core.db.dao.xapi.StateEntityDao
import com.ustadmobile.core.db.dao.xapi.StatementContextActivityJoinDao
import com.ustadmobile.core.db.dao.xapi.StatementDao
import com.ustadmobile.core.db.dao.xapi.StatementEntityJsonDao
import com.ustadmobile.core.db.dao.xapi.VerbDao
import com.ustadmobile.core.db.dao.xapi.VerbLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.XapiSessionEntityDao
import com.ustadmobile.door.room.RoomDatabase

public actual abstract class UmAppDatabase : RoomDatabase() {
  public actual abstract fun personDao(): PersonDao

  public actual abstract fun clazzDao(): ClazzDao

  public actual abstract fun courseBlockDao(): CourseBlockDao

  public actual abstract fun courseTerminologyDao(): CourseTerminologyDao

  public actual abstract fun courseGroupSetDao(): CourseGroupSetDao

  public actual abstract fun courseGroupMemberDao(): CourseGroupMemberDao

  public actual abstract fun clazzEnrolmentDao(): ClazzEnrolmentDao

  public actual abstract fun leavingReasonDao(): LeavingReasonDao

  public actual abstract fun contentEntryDao(): ContentEntryDao

  public actual abstract fun contentEntryContentCategoryJoinDao():
      ContentEntryContentCategoryJoinDao

  public actual abstract fun contentEntryParentChildJoinDao(): ContentEntryParentChildJoinDao

  public actual abstract fun contentEntryRelatedEntryJoinDao(): ContentEntryRelatedEntryJoinDao

  public actual abstract fun contentCategorySchemaDao(): ContentCategorySchemaDao

  public actual abstract fun contentCategoryDao(): ContentCategoryDao

  public actual abstract fun languageDao(): LanguageDao

  public actual abstract fun languageVariantDao(): LanguageVariantDao

  public actual abstract fun personAuthDao(): PersonAuthDao

  public actual abstract fun personGroupDao(): PersonGroupDao

  public actual abstract fun personGroupMemberDao(): PersonGroupMemberDao

  public actual abstract fun personPictureDao(): PersonPictureDao

  public actual abstract fun verbDao(): VerbDao

  public actual abstract fun activityEntityDao(): ActivityEntityDao

  public actual abstract fun reportDao(): ReportDao

  public actual abstract fun statementDao(): StatementDao

  public actual abstract fun actorDao(): ActorDao

  public actual abstract fun clazzLogAttendanceRecordDao(): ClazzLogAttendanceRecordDao

  public actual abstract fun clazzLogDao(): ClazzLogDao

  public actual abstract fun scheduleDao(): ScheduleDao

  public actual abstract fun holidayCalendarDao(): HolidayCalendarDao

  public actual abstract fun holidayDao(): HolidayDao

  public actual abstract fun clazzAssignmentDao(): ClazzAssignmentDao

  public actual abstract fun courseAssignmentSubmissionDao(): CourseAssignmentSubmissionDao

  public actual abstract fun courseAssignmentSubmissionFileDao(): CourseAssignmentSubmissionFileDao

  public actual abstract fun courseAssignmentMarkDao(): CourseAssignmentMarkDao

  public actual abstract fun commentsDao(): CommentsDao

  public actual abstract fun syncNodeDao(): SyncNodeDao

  public actual abstract fun siteDao(): SiteDao

  public actual abstract fun siteTermsDao(): SiteTermsDao

  public actual abstract fun personParentJoinDao(): PersonParentJoinDao

  public actual abstract fun scopedGrantDao(): ScopedGrantDao

  public actual abstract fun errorReportDao(): ErrorReportDao

  public actual abstract fun personAuth2Dao(): PersonAuth2Dao

  public actual abstract fun userSessionDao(): UserSessionDao

  public actual abstract fun contentEntryImportJobDao(): ContentEntryImportJobDao

  public actual abstract fun coursePictureDao(): CoursePictureDao

  public actual abstract fun contentEntryPictureDao(): ContentEntryPictureDao

  public actual abstract fun messageDao(): MessageDao

  public actual abstract fun peerReviewerAllocationDao(): PeerReviewerAllocationDao

  public actual abstract fun discussionPostDao(): DiscussionPostDao

  public actual abstract fun externalAppPermissionDao(): ExternalAppPermissionDao

  public actual abstract fun contentEntryVersionDao(): ContentEntryVersionDao

  public actual abstract fun outgoingReplicationDao(): OutgoingReplicationDao

  public actual abstract fun transferJobDao(): TransferJobDao

  public actual abstract fun transferJobItemDao(): TransferJobItemDao

  public actual abstract fun cacheLockJoinDao(): CacheLockJoinDao

  public actual abstract fun offlineItemDao(): OfflineItemDao

  public actual abstract fun deletedItemDao(): DeletedItemDao

  public actual abstract fun enrolmentRequestDao(): EnrolmentRequestDao

  public actual abstract fun coursePermissionDao(): CoursePermissionDao

  public actual abstract fun systemPermissionDao(): SystemPermissionDao

  public actual abstract fun courseBlockPictureDao(): CourseBlockPictureDao

  public actual abstract fun contentEntryPicture2Dao(): ContentEntryPicture2Dao

  public actual abstract fun transferJobErrorDao(): TransferJobErrorDao

  public actual abstract fun studentResultDao(): StudentResultDao

  public actual abstract fun verbLangMapEntryDao(): VerbLangMapEntryDao

  public actual abstract fun groupMemberActorJoinDao(): GroupMemberActorJoinDao

  public actual abstract fun activityLangMapEntryDao(): ActivityLangMapEntryDao

  public actual abstract fun activityInteractionDao(): ActivityInteractionDao

  public actual abstract fun activityExtensionDao(): ActivityExtensionDao

  public actual abstract fun statementContextActivityJoinDao(): StatementContextActivityJoinDao

  public actual abstract fun xapiSessionEntityDao(): XapiSessionEntityDao

  public actual abstract fun statementEntityJsonDao(): StatementEntityJsonDao

  public actual abstract fun stateEntityDao(): StateEntityDao

  public actual abstract fun stateDeleteCommandDao(): StateDeleteCommandDao
}
