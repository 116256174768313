package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.CourseBlockPicture
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class CourseBlockPictureDao : ImageDao {
  public actual abstract suspend fun upsertListAsync(entities: List<CourseBlockPicture>)

  actual override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
  }
}
