package com.ustadmobile.lib.db.entities.xapi

import androidx.room.Entity
import androidx.room.Index
import androidx.room.PrimaryKey
import com.ustadmobile.door.annotation.*
import com.ustadmobile.lib.db.entities.TRIGGER_CONDITION_WHERE_NEWER
import com.ustadmobile.lib.db.entities.TRIGGER_UPSERT
import com.ustadmobile.lib.db.entities.xapi.XapiEntityObjectTypeFlags.AGENT
import kotlinx.serialization.Serializable

@Entity(
    indices = arrayOf(
        //Actor lookup will commonly be used to join to personuid
        Index("actorPersonUid", name = "idx_actorentity_uid_personuid"),

        //All queries that connect a statement and a person need to check the actor type to see if it
        //is a group, so index this
        Index("actorObjectType", name= "idx_actorentity_actorobjecttype")
    )
)
@ReplicateEntity(
    tableId = ActorEntity.TABLE_ID,
    remoteInsertStrategy = ReplicateEntity.RemoteInsertStrategy.INSERT_INTO_RECEIVE_VIEW,
)
@Triggers(
    arrayOf(
        Trigger(
            name = "agententity_remote_insert",
            order = Trigger.Order.INSTEAD_OF,
            on = Trigger.On.RECEIVEVIEW,
            events = [Trigger.Event.INSERT],
            conditionSql = TRIGGER_CONDITION_WHERE_NEWER,
            sqlStatements = [TRIGGER_UPSERT]
        )
    )
)
/**
 * Represents an xAPI Actor (Agent or Group). Various properties on a Statement are an actor that
 * can be a group or agent e.g. the actor themselves, instructor / team context, etc.
 *
 * Where the ActorEntity represents a group, then GroupMemberActorJoin is used to join the group
 * ActorEntity to a list of agent ActorEntities (members).
 *
 * @param actorUid For an agent or identified group, this is the XXHash64 of the mbox, openId, or
 *        "agentAccountName@agentHomePage" (e.g. username@https://example.com/path/ where
 *        https://example.com/path/ is the endpoint url) calculated as per XapiActor#identifierHash
 *
 *
 *
 * @param actorPersonUid the person uid (if any) for this actor. The Actor could be a group, in which
 *        case the personUid is zero. The actor could also from an outside system e.g. statements that
 *        were generated by a different system, in which case the person uid is also zero.
 *
 *        The Xapi Agents Resource ( as per
 *        https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Communication.md#24-agents-resource )
 *        describes how different Agents can be linked to one person. This would apply when a person
 *        initially doesn't have a username (e.g. a student without login where the teacher is
 *        recording assignment marks, attendance, etc) and then a username is created. In this case
 *        there will be multiple actors which will all link to the same personUid.
 *
 * @param actorEtag For an Agent this is the hash of the accountName (if non-null), otherwise,
 * 0. For a Group this is the hash of all members hash, where the member
 * list is sorted by the hash. This makes it easy to detect if an identified Group has been modified
 * (which means that all GroupMemberActorJoin last modified times will need updated).
 */
@Serializable
data class ActorEntity(
    @PrimaryKey(autoGenerate = true)
    var actorUid: Long = 0,

    var actorPersonUid: Long = 0,

    var actorName: String? = null,

    var actorMbox: String? = null,

    var actorMbox_sha1sum: String? = null,

    var actorOpenid: String? = null,

    var actorAccountName: String? = null,

    var actorAccountHomePage: String? = null,

    @ReplicateEtag
    var actorEtag: Long = 0,

    @ReplicateLastModified(autoSet = false)
    var actorLct: Long = 0,

    var actorObjectType: Int = AGENT,
) {

    companion object {


        const val TABLE_ID = 68
    }
}
