package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ScopedGrant
import kotlin.Long
import kotlin.collections.List

public class ScopedGrantDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ScopedGrantDao,
) : ScopedGrantDao() {
  override suspend fun insertAsync(scopedGrant: ScopedGrant): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(scopedGrant.sgUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(48)
      scopedGrant.sgUid = _newPk
    }
    scopedGrant.sgLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(scopedGrant)
    return scopedGrant.sgUid
  }

  override suspend fun insertListAsync(scopedGrantList: List<ScopedGrant>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    scopedGrantList.iterator().forEach  {
      if(it.sgUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(48)
        it.sgUid = _newPk
        _generatedPks += _newPk
      }
      it.sgLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(scopedGrantList)
  }
}
