package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class HolidayCalendarDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: HolidayCalendarDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : HolidayCalendarDao() {
  override fun findAllHolidaysWithEntriesCount(): PagingSource<Int, HolidayCalendarWithNumEntries> {
    val _result = _dao.findAllHolidaysWithEntriesCount()
    return _result
  }

  override fun replaceList(list: List<HolidayCalendar>) {
    _repo.withRepoChangeMonitor("HolidayCalendar") {
      _dao.replaceList(list)
    }
  }

  override fun findAllHolidaysLiveData(): Flow<List<HolidayCalendar>> {
    val _result = _dao.findAllHolidaysLiveData()
    return _result
  }

  override fun findByUidLive(uid: Long): Flow<HolidayCalendar?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  override suspend fun updateAsync(entity: HolidayCalendar): Int {
    val _result = _repo.withRepoChangeMonitorAsync("HolidayCalendar") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override suspend fun findByUid(uid: Long): HolidayCalendar? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  override suspend fun findByUidAsync(uid: Long): HolidayCalendar? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun insert(entity: HolidayCalendar): Long {
    val _result = _repo.withRepoChangeMonitor("HolidayCalendar") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: HolidayCalendar): Long {
    val _result = _repo.withRepoChangeMonitorAsync("HolidayCalendar") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<HolidayCalendar>) {
    _repo.withRepoChangeMonitor("HolidayCalendar") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: HolidayCalendar) {
    _repo.withRepoChangeMonitor("HolidayCalendar") {
      _dao.update(entity)
    }
  }
}
