package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.MessageAndOtherPerson
import com.ustadmobile.lib.db.entities.Message
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class MessageDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: MessageDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : MessageDao() {
  override fun messagesFromOtherUserAsPagingSource(accountPersonUid: Long, otherPersonUid: Long):
      PagingSource<Int, Message> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "MessageDao/messagesFromOtherUserAsPagingSource",
    dbPagingSource = _dao.messagesFromOtherUserAsPagingSource(accountPersonUid, otherPersonUid),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "MessageDao/messagesFromOtherUserAsPagingSource") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "MessageDao/messagesFromOtherUserAsPagingSource")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("otherPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              otherPersonUid))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override fun conversationsForUserAsPagingSource(searchQuery: String, accountPersonUid: Long):
      PagingSource<Int, MessageAndOtherPerson> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "MessageDao/conversationsForUserAsPagingSource",
    dbPagingSource = _dao.conversationsForUserAsPagingSource(searchQuery, accountPersonUid),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "MessageDao/conversationsForUserAsPagingSource") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "MessageDao/conversationsForUserAsPagingSource")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("searchQuery", _repo.config.json.encodeToString(String.serializer(),
              searchQuery))
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun insert(message: Message) {
    _repo.withRepoChangeMonitorAsync("Message") {
      _dao.insert(message)
    }
  }
}
