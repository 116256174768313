package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.ContentEntryAndDetail
import com.ustadmobile.lib.db.composites.ContentEntryAndLanguage
import com.ustadmobile.lib.db.composites.ContentEntryAndListDetail
import com.ustadmobile.lib.db.composites.ContentEntryAndPicture
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryWithLanguage
import com.ustadmobile.lib.db.entities.UidAndLabel
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ContentEntryDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun insertListAsync(entityList: List<ContentEntry>) {
    _repo.withRepoChangeMonitorAsync("ContentEntry") {
      _dao.insertListAsync(entityList)
    }
  }

  override suspend fun upsertAsync(entity: ContentEntry) {
    _repo.withRepoChangeMonitorAsync("ContentEntry") {
      _dao.upsertAsync(entity)
    }
  }

  override suspend fun findByUidAsync(entryUid: Long): ContentEntry? {
    val _result = _dao.findByUidAsync(entryUid)
    return _result
  }

  override suspend fun findEntryWithLanguageByEntryIdAsync(entryUuid: Long):
      ContentEntryAndLanguage? {
    val _result = _dao.findEntryWithLanguageByEntryIdAsync(entryUuid)
    return _result
  }

  override suspend fun findByUidWithEditDetails(uid: Long): ContentEntryAndPicture? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "ContentEntryDao/findByUidWithEditDetails") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ContentEntryDao/findByUidWithEditDetails")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByUidWithEditDetails(uid)
    return _result
  }

  override fun findByContentEntryUidWithDetailsAsFlow(
    contentEntryUid: Long,
    clazzUid: Long,
    courseBlockUid: Long,
    accountPersonUid: Long,
  ): Flow<ContentEntryAndDetail?> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByContentEntryUidWithDetailsAsFlow(contentEntryUid, clazzUid, courseBlockUid,
        accountPersonUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "ContentEntryDao/findByContentEntryUidWithDetailsAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "ContentEntryDao/findByContentEntryUidWithDetailsAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("contentEntryUid", _repo.config.json.encodeToString(Long.serializer(),
              contentEntryUid))
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("courseBlockUid", _repo.config.json.encodeToString(Long.serializer(),
              courseBlockUid))
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override fun findBySourceUrl(sourceUrl: String): ContentEntry? {
    val _result = _dao.findBySourceUrl(sourceUrl)
    return _result
  }

  override suspend fun findTitleByUidAsync(entryUid: Long): String? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "ContentEntryDao/findTitleByUidAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ContentEntryDao/findTitleByUidAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("entryUid", _repo.config.json.encodeToString(Long.serializer(), entryUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findTitleByUidAsync(entryUid)
    return _result
  }

  override fun getChildrenByParentUid(parentUid: Long): PagingSource<Int, ContentEntry> {
    val _result = _dao.getChildrenByParentUid(parentUid)
    return _result
  }

  override suspend fun getChildrenByParentAsync(parentUid: Long): List<ContentEntry> {
    val _result = _dao.getChildrenByParentAsync(parentUid)
    return _result
  }

  override suspend fun getCountNumberOfChildrenByParentUUidAsync(parentUid: Long): Int {
    val _result = _dao.getCountNumberOfChildrenByParentUUidAsync(parentUid)
    return _result
  }

  override suspend fun getContentByUuidAsync(parentUid: Long): ContentEntry? {
    val _result = _dao.getContentByUuidAsync(parentUid)
    return _result
  }

  override suspend fun findAllLanguageRelatedEntriesAsync(entryUuid: Long): List<ContentEntry> {
    val _result = _dao.findAllLanguageRelatedEntriesAsync(entryUuid)
    return _result
  }

  override fun update(entity: ContentEntry) {
    _repo.withRepoChangeMonitor("ContentEntry") {
      _dao.update(entity)
    }
  }

  override suspend fun findByUidWithLanguageAsync(uid: Long): ContentEntryWithLanguage? {
    val _result = _dao.findByUidWithLanguageAsync(uid)
    return _result
  }

  override fun findByUid(entryUid: Long): ContentEntry? {
    val _result = _dao.findByUid(entryUid)
    return _result
  }

  override fun findByTitle(title: String): Flow<ContentEntry?> {
    val _result = _dao.findByTitle(title)
    return _result
  }

  override suspend fun findBySourceUrlWithContentEntryStatusAsync(sourceUrl: String):
      ContentEntry? {
    val _result = _dao.findBySourceUrlWithContentEntryStatusAsync(sourceUrl)
    return _result
  }

  override fun getChildrenByParentUidWithCategoryFilterOrderByName(
    accountPersonUid: Long,
    parentUid: Long,
    langParam: Long,
    categoryParam0: Long,
    sortOrder: Int,
    includeDeleted: Boolean,
  ): PagingSource<Int, ContentEntryAndListDetail> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "ContentEntryDao/getChildrenByParentUidWithCategoryFilterOrderByName",
    dbPagingSource = _dao.getChildrenByParentUidWithCategoryFilterOrderByName(accountPersonUid,
        parentUid, langParam, categoryParam0, sortOrder, includeDeleted),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "ContentEntryDao/getChildrenByParentUidWithCategoryFilterOrderByName") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "ContentEntryDao/getChildrenByParentUidWithCategoryFilterOrderByName")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("parentUid", _repo.config.json.encodeToString(Long.serializer(), parentUid))
          parameter("langParam", _repo.config.json.encodeToString(Long.serializer(), langParam))
          parameter("categoryParam0", _repo.config.json.encodeToString(Long.serializer(),
              categoryParam0))
          parameter("sortOrder", _repo.config.json.encodeToString(Int.serializer(), sortOrder))
          parameter("includeDeleted", _repo.config.json.encodeToString(Boolean.serializer(),
              includeDeleted))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override fun getContentFromMyCourses(accountPersonUid: Long):
      PagingSource<Int, ContentEntryAndListDetail> {
    val _result = _dao.getContentFromMyCourses(accountPersonUid)
    return _result
  }

  override fun getContentByOwner(accountPersonUid: Long):
      PagingSource<Int, ContentEntryAndListDetail> {
    val _result = _dao.getContentByOwner(accountPersonUid)
    return _result
  }

  override suspend fun updateAsync(entity: ContentEntry): Int {
    val _result = _repo.withRepoChangeMonitorAsync("ContentEntry") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override fun getChildrenByAll(parentUid: Long): List<ContentEntry> {
    val _result = _dao.getChildrenByAll(parentUid)
    return _result
  }

  override fun findLiveContentEntry(parentUid: Long): Flow<ContentEntry?> {
    val _result = _dao.findLiveContentEntry(parentUid)
    return _result
  }

  override fun getContentEntryUidFromXapiObjectId(objectId: String): Long {
    val _result = _dao.getContentEntryUidFromXapiObjectId(objectId)
    return _result
  }

  override fun findSimilarIdEntryForKhan(sourceUrl: String): List<ContentEntry> {
    val _result = _dao.findSimilarIdEntryForKhan(sourceUrl)
    return _result
  }

  override fun updateContentEntryInActive(
    contentEntryUid: Long,
    ceInactive: Boolean,
    changedTime: Long,
  ) {
    _repo.withRepoChangeMonitor("ContentEntry") {
      _dao.updateContentEntryInActive(contentEntryUid, ceInactive, changedTime)
    }
  }

  override fun updateContentEntryContentFlag(
    contentFlag: Int,
    contentEntryUid: Long,
    changedTime: Long,
  ) {
    _repo.withRepoChangeMonitor("ContentEntry") {
      _dao.updateContentEntryContentFlag(contentFlag, contentEntryUid, changedTime)
    }
  }

  override suspend fun getContentEntryFromUids(contentEntryUids: List<Long>): List<UidAndLabel> {
    val _result = _dao.getContentEntryFromUids(contentEntryUids)
    return _result
  }

  public override fun insert(entity: ContentEntry): Long {
    val _result = _repo.withRepoChangeMonitor("ContentEntry") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ContentEntry): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ContentEntry") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ContentEntry>) {
    _repo.withRepoChangeMonitor("ContentEntry") {
      _dao.insertList(entityList)
    }
  }
}
