package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.CacheLockJoinDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.ClazzLogDao_DoorWrapper
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.CommentsDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategoryDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryImportJobDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryVersionDao
import com.ustadmobile.core.db.dao.ContentEntryVersionDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseBlockPictureDao
import com.ustadmobile.core.db.dao.CourseBlockPictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao_DoorWrapper
import com.ustadmobile.core.db.dao.CoursePermissionDao
import com.ustadmobile.core.db.dao.CoursePermissionDao_DoorWrapper
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CoursePictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao_DoorWrapper
import com.ustadmobile.core.db.dao.DeletedItemDao
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.DiscussionPostDao_DoorWrapper
import com.ustadmobile.core.db.dao.EnrolmentRequestDao
import com.ustadmobile.core.db.dao.EnrolmentRequestDao_DoorWrapper
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ErrorReportDao_DoorWrapper
import com.ustadmobile.core.db.dao.ExternalAppPermissionDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao_DoorWrapper
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.HolidayDao_DoorWrapper
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageDao_DoorWrapper
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LanguageVariantDao_DoorWrapper
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.LeavingReasonDao_DoorWrapper
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.MessageDao_DoorWrapper
import com.ustadmobile.core.db.dao.OfflineItemDao
import com.ustadmobile.core.db.dao.OfflineItemDao_DoorWrapper
import com.ustadmobile.core.db.dao.OutgoingReplicationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuth2Dao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.PersonPictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ReportDao_DoorWrapper
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.ScheduleDao_DoorWrapper
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.ScopedGrantDao_DoorWrapper
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteDao_DoorWrapper
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.SiteTermsDao_DoorWrapper
import com.ustadmobile.core.db.dao.StudentResultDao
import com.ustadmobile.core.db.dao.StudentResultDao_DoorWrapper
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.SystemPermissionDao
import com.ustadmobile.core.db.dao.SystemPermissionDao_DoorWrapper
import com.ustadmobile.core.db.dao.TransferJobDao
import com.ustadmobile.core.db.dao.TransferJobErrorDao
import com.ustadmobile.core.db.dao.TransferJobItemDao
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.UserSessionDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.ActivityEntityDao
import com.ustadmobile.core.db.dao.xapi.ActivityEntityDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.ActivityExtensionDao
import com.ustadmobile.core.db.dao.xapi.ActivityExtensionDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.ActivityInteractionDao
import com.ustadmobile.core.db.dao.xapi.ActivityInteractionDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.ActivityLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.ActivityLangMapEntryDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.ActorDao
import com.ustadmobile.core.db.dao.xapi.ActorDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.GroupMemberActorJoinDao
import com.ustadmobile.core.db.dao.xapi.GroupMemberActorJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.StateDeleteCommandDao
import com.ustadmobile.core.db.dao.xapi.StateDeleteCommandDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.StateEntityDao
import com.ustadmobile.core.db.dao.xapi.StateEntityDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.StatementContextActivityJoinDao
import com.ustadmobile.core.db.dao.xapi.StatementContextActivityJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.StatementDao
import com.ustadmobile.core.db.dao.xapi.StatementDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.StatementEntityJsonDao
import com.ustadmobile.core.db.dao.xapi.StatementEntityJsonDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.VerbDao
import com.ustadmobile.core.db.dao.xapi.VerbDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.VerbLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.VerbLangMapEntryDao_DoorWrapper
import com.ustadmobile.core.db.dao.xapi.XapiSessionEntityDao
import com.ustadmobile.core.db.dao.xapi.XapiSessionEntityDao_DoorWrapper
import com.ustadmobile.door.DoorDatabaseWrapper
import com.ustadmobile.door.log.DoorLogger
import com.ustadmobile.door.message.DoorMessageCallback
import com.ustadmobile.door.nodeevent.NodeEventManagerCommon
import com.ustadmobile.door.nodeevent.NodeEventManagerJs
import com.ustadmobile.door.room.InvalidationTracker
import com.ustadmobile.door.room.RoomDatabase
import kotlin.Any
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlin.reflect.KClass
import kotlinx.coroutines.Runnable

@Suppress("REDUNDANT_PROJECTION", "ClassName")
public class UmAppDatabase_DoorWrapper(
  private val _db: UmAppDatabase,
  override val nodeId: Long,
  private val messageCallback: DoorMessageCallback<UmAppDatabase>,
  logger: DoorLogger,
  dbName: String,
) : UmAppDatabase(), DoorDatabaseWrapper<UmAppDatabase> {
  override val dbVersion: Int
    get() = 202

  override val dbName: String
    get() = "DoorWrapper for [${_db.toString()}]"

  public val _PersonDao: PersonDao by lazy  {
        PersonDao_DoorWrapper(_db, _db.personDao())
      }


  public val _ClazzDao: ClazzDao by lazy  {
        ClazzDao_DoorWrapper(_db, _db.clazzDao())
      }


  public val _CourseBlockDao: CourseBlockDao by lazy  {
        CourseBlockDao_DoorWrapper(_db, _db.courseBlockDao())
      }


  public val _CourseTerminologyDao: CourseTerminologyDao by lazy  {
        CourseTerminologyDao_DoorWrapper(_db, _db.courseTerminologyDao())
      }


  public val _CourseGroupSetDao: CourseGroupSetDao by lazy  {
        CourseGroupSetDao_DoorWrapper(_db, _db.courseGroupSetDao())
      }


  public val _CourseGroupMemberDao: CourseGroupMemberDao by lazy  {
        CourseGroupMemberDao_DoorWrapper(_db, _db.courseGroupMemberDao())
      }


  public val _ClazzEnrolmentDao: ClazzEnrolmentDao by lazy  {
        ClazzEnrolmentDao_DoorWrapper(_db, _db.clazzEnrolmentDao())
      }


  public val _LeavingReasonDao: LeavingReasonDao by lazy  {
        LeavingReasonDao_DoorWrapper(_db, _db.leavingReasonDao())
      }


  public val _ContentEntryDao: ContentEntryDao by lazy  {
        ContentEntryDao_DoorWrapper(_db, _db.contentEntryDao())
      }


  public val _ContentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao by lazy  {
        ContentEntryContentCategoryJoinDao_DoorWrapper(_db,
            _db.contentEntryContentCategoryJoinDao())
      }


  public val _ContentEntryParentChildJoinDao: ContentEntryParentChildJoinDao by lazy  {
        ContentEntryParentChildJoinDao_DoorWrapper(_db, _db.contentEntryParentChildJoinDao())
      }


  public val _ContentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao by lazy  {
        ContentEntryRelatedEntryJoinDao_DoorWrapper(_db, _db.contentEntryRelatedEntryJoinDao())
      }


  public val _ContentCategorySchemaDao: ContentCategorySchemaDao by lazy  {
        ContentCategorySchemaDao_DoorWrapper(_db, _db.contentCategorySchemaDao())
      }


  public val _ContentCategoryDao: ContentCategoryDao by lazy  {
        ContentCategoryDao_DoorWrapper(_db, _db.contentCategoryDao())
      }


  public val _LanguageDao: LanguageDao by lazy  {
        LanguageDao_DoorWrapper(_db, _db.languageDao())
      }


  public val _LanguageVariantDao: LanguageVariantDao by lazy  {
        LanguageVariantDao_DoorWrapper(_db, _db.languageVariantDao())
      }


  public val _PersonGroupDao: PersonGroupDao by lazy  {
        PersonGroupDao_DoorWrapper(_db, _db.personGroupDao())
      }


  public val _PersonGroupMemberDao: PersonGroupMemberDao by lazy  {
        PersonGroupMemberDao_DoorWrapper(_db, _db.personGroupMemberDao())
      }


  public val _PersonPictureDao: PersonPictureDao by lazy  {
        PersonPictureDao_DoorWrapper(_db, _db.personPictureDao())
      }


  public val _VerbDao: VerbDao by lazy  {
        VerbDao_DoorWrapper(_db, _db.verbDao())
      }


  public val _ActivityEntityDao: ActivityEntityDao by lazy  {
        ActivityEntityDao_DoorWrapper(_db, _db.activityEntityDao())
      }


  public val _ReportDao: ReportDao by lazy  {
        ReportDao_DoorWrapper(_db, _db.reportDao())
      }


  public val _StatementDao: StatementDao by lazy  {
        StatementDao_DoorWrapper(_db, _db.statementDao())
      }


  public val _ActorDao: ActorDao by lazy  {
        ActorDao_DoorWrapper(_db, _db.actorDao())
      }


  public val _ClazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao by lazy  {
        ClazzLogAttendanceRecordDao_DoorWrapper(_db, _db.clazzLogAttendanceRecordDao())
      }


  public val _ClazzLogDao: ClazzLogDao by lazy  {
        ClazzLogDao_DoorWrapper(_db, _db.clazzLogDao())
      }


  public val _ScheduleDao: ScheduleDao by lazy  {
        ScheduleDao_DoorWrapper(_db, _db.scheduleDao())
      }


  public val _HolidayCalendarDao: HolidayCalendarDao by lazy  {
        HolidayCalendarDao_DoorWrapper(_db, _db.holidayCalendarDao())
      }


  public val _HolidayDao: HolidayDao by lazy  {
        HolidayDao_DoorWrapper(_db, _db.holidayDao())
      }


  public val _ClazzAssignmentDao: ClazzAssignmentDao by lazy  {
        ClazzAssignmentDao_DoorWrapper(_db, _db.clazzAssignmentDao())
      }


  public val _CourseAssignmentSubmissionDao: CourseAssignmentSubmissionDao by lazy  {
        CourseAssignmentSubmissionDao_DoorWrapper(_db, _db.courseAssignmentSubmissionDao())
      }


  public val _CourseAssignmentSubmissionFileDao: CourseAssignmentSubmissionFileDao by lazy  {
        CourseAssignmentSubmissionFileDao_DoorWrapper(_db, _db.courseAssignmentSubmissionFileDao())
      }


  public val _CourseAssignmentMarkDao: CourseAssignmentMarkDao by lazy  {
        CourseAssignmentMarkDao_DoorWrapper(_db, _db.courseAssignmentMarkDao())
      }


  public val _CommentsDao: CommentsDao by lazy  {
        CommentsDao_DoorWrapper(_db, _db.commentsDao())
      }


  public val _SiteDao: SiteDao by lazy  {
        SiteDao_DoorWrapper(_db, _db.siteDao())
      }


  public val _SiteTermsDao: SiteTermsDao by lazy  {
        SiteTermsDao_DoorWrapper(_db, _db.siteTermsDao())
      }


  public val _PersonParentJoinDao: PersonParentJoinDao by lazy  {
        PersonParentJoinDao_DoorWrapper(_db, _db.personParentJoinDao())
      }


  public val _ScopedGrantDao: ScopedGrantDao by lazy  {
        ScopedGrantDao_DoorWrapper(_db, _db.scopedGrantDao())
      }


  public val _ErrorReportDao: ErrorReportDao by lazy  {
        ErrorReportDao_DoorWrapper(_db, _db.errorReportDao())
      }


  public val _PersonAuth2Dao: PersonAuth2Dao by lazy  {
        PersonAuth2Dao_DoorWrapper(_db, _db.personAuth2Dao())
      }


  public val _UserSessionDao: UserSessionDao by lazy  {
        UserSessionDao_DoorWrapper(_db, _db.userSessionDao())
      }


  public val _CoursePictureDao: CoursePictureDao by lazy  {
        CoursePictureDao_DoorWrapper(_db, _db.coursePictureDao())
      }


  public val _ContentEntryPictureDao: ContentEntryPictureDao by lazy  {
        ContentEntryPictureDao_DoorWrapper(_db, _db.contentEntryPictureDao())
      }


  public val _MessageDao: MessageDao by lazy  {
        MessageDao_DoorWrapper(_db, _db.messageDao())
      }


  public val _PeerReviewerAllocationDao: PeerReviewerAllocationDao by lazy  {
        PeerReviewerAllocationDao_DoorWrapper(_db, _db.peerReviewerAllocationDao())
      }


  public val _DiscussionPostDao: DiscussionPostDao by lazy  {
        DiscussionPostDao_DoorWrapper(_db, _db.discussionPostDao())
      }


  public val _ContentEntryVersionDao: ContentEntryVersionDao by lazy  {
        ContentEntryVersionDao_DoorWrapper(_db, _db.contentEntryVersionDao())
      }


  public val _OfflineItemDao: OfflineItemDao by lazy  {
        OfflineItemDao_DoorWrapper(_db, _db.offlineItemDao())
      }


  public val _EnrolmentRequestDao: EnrolmentRequestDao by lazy  {
        EnrolmentRequestDao_DoorWrapper(_db, _db.enrolmentRequestDao())
      }


  public val _CoursePermissionDao: CoursePermissionDao by lazy  {
        CoursePermissionDao_DoorWrapper(_db, _db.coursePermissionDao())
      }


  public val _SystemPermissionDao: SystemPermissionDao by lazy  {
        SystemPermissionDao_DoorWrapper(_db, _db.systemPermissionDao())
      }


  public val _CourseBlockPictureDao: CourseBlockPictureDao by lazy  {
        CourseBlockPictureDao_DoorWrapper(_db, _db.courseBlockPictureDao())
      }


  public val _ContentEntryPicture2Dao: ContentEntryPicture2Dao by lazy  {
        ContentEntryPicture2Dao_DoorWrapper(_db, _db.contentEntryPicture2Dao())
      }


  public val _StudentResultDao: StudentResultDao by lazy  {
        StudentResultDao_DoorWrapper(_db, _db.studentResultDao())
      }


  public val _VerbLangMapEntryDao: VerbLangMapEntryDao by lazy  {
        VerbLangMapEntryDao_DoorWrapper(_db, _db.verbLangMapEntryDao())
      }


  public val _GroupMemberActorJoinDao: GroupMemberActorJoinDao by lazy  {
        GroupMemberActorJoinDao_DoorWrapper(_db, _db.groupMemberActorJoinDao())
      }


  public val _ActivityLangMapEntryDao: ActivityLangMapEntryDao by lazy  {
        ActivityLangMapEntryDao_DoorWrapper(_db, _db.activityLangMapEntryDao())
      }


  public val _ActivityInteractionDao: ActivityInteractionDao by lazy  {
        ActivityInteractionDao_DoorWrapper(_db, _db.activityInteractionDao())
      }


  public val _ActivityExtensionDao: ActivityExtensionDao by lazy  {
        ActivityExtensionDao_DoorWrapper(_db, _db.activityExtensionDao())
      }


  public val _StatementContextActivityJoinDao: StatementContextActivityJoinDao by lazy  {
        StatementContextActivityJoinDao_DoorWrapper(_db, _db.statementContextActivityJoinDao())
      }


  public val _XapiSessionEntityDao: XapiSessionEntityDao by lazy  {
        XapiSessionEntityDao_DoorWrapper(_db, _db.xapiSessionEntityDao())
      }


  public val _StatementEntityJsonDao: StatementEntityJsonDao by lazy  {
        StatementEntityJsonDao_DoorWrapper(_db, _db.statementEntityJsonDao())
      }


  public val _StateEntityDao: StateEntityDao by lazy  {
        StateEntityDao_DoorWrapper(_db, _db.stateEntityDao())
      }


  public val _StateDeleteCommandDao: StateDeleteCommandDao by lazy  {
        StateDeleteCommandDao_DoorWrapper(_db, _db.stateDeleteCommandDao())
      }


  override val realDatabase: RoomDatabase
    get() = _db

  override val nodeEventManager: NodeEventManagerCommon<UmAppDatabase> = NodeEventManagerJs(_db,
      messageCallback, logger, dbName)


  override val invalidationTracker: InvalidationTracker
    get() = _db.invalidationTracker

  override fun createAllTables(): List<String> = _db.createAllTables()

  override fun personDao(): PersonDao = _PersonDao

  override fun clazzDao(): ClazzDao = _ClazzDao

  override fun courseBlockDao(): CourseBlockDao = _CourseBlockDao

  override fun courseTerminologyDao(): CourseTerminologyDao = _CourseTerminologyDao

  override fun courseGroupSetDao(): CourseGroupSetDao = _CourseGroupSetDao

  override fun courseGroupMemberDao(): CourseGroupMemberDao = _CourseGroupMemberDao

  override fun clazzEnrolmentDao(): ClazzEnrolmentDao = _ClazzEnrolmentDao

  override fun leavingReasonDao(): LeavingReasonDao = _LeavingReasonDao

  override fun contentEntryDao(): ContentEntryDao = _ContentEntryDao

  override fun contentEntryContentCategoryJoinDao(): ContentEntryContentCategoryJoinDao =
      _ContentEntryContentCategoryJoinDao

  override fun contentEntryParentChildJoinDao(): ContentEntryParentChildJoinDao =
      _ContentEntryParentChildJoinDao

  override fun contentEntryRelatedEntryJoinDao(): ContentEntryRelatedEntryJoinDao =
      _ContentEntryRelatedEntryJoinDao

  override fun contentCategorySchemaDao(): ContentCategorySchemaDao = _ContentCategorySchemaDao

  override fun contentCategoryDao(): ContentCategoryDao = _ContentCategoryDao

  override fun languageDao(): LanguageDao = _LanguageDao

  override fun languageVariantDao(): LanguageVariantDao = _LanguageVariantDao

  override fun personAuthDao(): PersonAuthDao = _db.personAuthDao()

  override fun personGroupDao(): PersonGroupDao = _PersonGroupDao

  override fun personGroupMemberDao(): PersonGroupMemberDao = _PersonGroupMemberDao

  override fun personPictureDao(): PersonPictureDao = _PersonPictureDao

  override fun verbDao(): VerbDao = _VerbDao

  override fun activityEntityDao(): ActivityEntityDao = _ActivityEntityDao

  override fun reportDao(): ReportDao = _ReportDao

  override fun statementDao(): StatementDao = _StatementDao

  override fun actorDao(): ActorDao = _ActorDao

  override fun clazzLogAttendanceRecordDao(): ClazzLogAttendanceRecordDao =
      _ClazzLogAttendanceRecordDao

  override fun clazzLogDao(): ClazzLogDao = _ClazzLogDao

  override fun scheduleDao(): ScheduleDao = _ScheduleDao

  override fun holidayCalendarDao(): HolidayCalendarDao = _HolidayCalendarDao

  override fun holidayDao(): HolidayDao = _HolidayDao

  override fun clazzAssignmentDao(): ClazzAssignmentDao = _ClazzAssignmentDao

  override fun courseAssignmentSubmissionDao(): CourseAssignmentSubmissionDao =
      _CourseAssignmentSubmissionDao

  override fun courseAssignmentSubmissionFileDao(): CourseAssignmentSubmissionFileDao =
      _CourseAssignmentSubmissionFileDao

  override fun courseAssignmentMarkDao(): CourseAssignmentMarkDao = _CourseAssignmentMarkDao

  override fun commentsDao(): CommentsDao = _CommentsDao

  override fun syncNodeDao(): SyncNodeDao = _db.syncNodeDao()

  override fun siteDao(): SiteDao = _SiteDao

  override fun siteTermsDao(): SiteTermsDao = _SiteTermsDao

  override fun personParentJoinDao(): PersonParentJoinDao = _PersonParentJoinDao

  override fun scopedGrantDao(): ScopedGrantDao = _ScopedGrantDao

  override fun errorReportDao(): ErrorReportDao = _ErrorReportDao

  override fun personAuth2Dao(): PersonAuth2Dao = _PersonAuth2Dao

  override fun userSessionDao(): UserSessionDao = _UserSessionDao

  override fun contentEntryImportJobDao(): ContentEntryImportJobDao = _db.contentEntryImportJobDao()

  override fun coursePictureDao(): CoursePictureDao = _CoursePictureDao

  override fun contentEntryPictureDao(): ContentEntryPictureDao = _ContentEntryPictureDao

  override fun messageDao(): MessageDao = _MessageDao

  override fun peerReviewerAllocationDao(): PeerReviewerAllocationDao = _PeerReviewerAllocationDao

  override fun discussionPostDao(): DiscussionPostDao = _DiscussionPostDao

  override fun externalAppPermissionDao(): ExternalAppPermissionDao = _db.externalAppPermissionDao()

  override fun contentEntryVersionDao(): ContentEntryVersionDao = _ContentEntryVersionDao

  override fun outgoingReplicationDao(): OutgoingReplicationDao = _db.outgoingReplicationDao()

  override fun transferJobDao(): TransferJobDao = _db.transferJobDao()

  override fun transferJobItemDao(): TransferJobItemDao = _db.transferJobItemDao()

  override fun cacheLockJoinDao(): CacheLockJoinDao = _db.cacheLockJoinDao()

  override fun offlineItemDao(): OfflineItemDao = _OfflineItemDao

  override fun deletedItemDao(): DeletedItemDao = _db.deletedItemDao()

  override fun enrolmentRequestDao(): EnrolmentRequestDao = _EnrolmentRequestDao

  override fun coursePermissionDao(): CoursePermissionDao = _CoursePermissionDao

  override fun systemPermissionDao(): SystemPermissionDao = _SystemPermissionDao

  override fun courseBlockPictureDao(): CourseBlockPictureDao = _CourseBlockPictureDao

  override fun contentEntryPicture2Dao(): ContentEntryPicture2Dao = _ContentEntryPicture2Dao

  override fun transferJobErrorDao(): TransferJobErrorDao = _db.transferJobErrorDao()

  override fun studentResultDao(): StudentResultDao = _StudentResultDao

  override fun verbLangMapEntryDao(): VerbLangMapEntryDao = _VerbLangMapEntryDao

  override fun groupMemberActorJoinDao(): GroupMemberActorJoinDao = _GroupMemberActorJoinDao

  override fun activityLangMapEntryDao(): ActivityLangMapEntryDao = _ActivityLangMapEntryDao

  override fun activityInteractionDao(): ActivityInteractionDao = _ActivityInteractionDao

  override fun activityExtensionDao(): ActivityExtensionDao = _ActivityExtensionDao

  override fun statementContextActivityJoinDao(): StatementContextActivityJoinDao =
      _StatementContextActivityJoinDao

  override fun xapiSessionEntityDao(): XapiSessionEntityDao = _XapiSessionEntityDao

  override fun statementEntityJsonDao(): StatementEntityJsonDao = _StatementEntityJsonDao

  override fun stateEntityDao(): StateEntityDao = _StateEntityDao

  override fun stateDeleteCommandDao(): StateDeleteCommandDao = _StateDeleteCommandDao

  override suspend fun clearAllTablesAsync() {
    _db.clearAllTablesAsync()
  }

  override fun clearAllTables() {
    _db.clearAllTables()
  }

  override fun runInTransaction(runnable: Runnable) {
    _db.runInTransaction(runnable)
  }

  @Suppress("UNCHECKED_CAST")
  override fun <T : Any> getDaoByClass(daoClass: KClass<T>): T = when(daoClass) {
    PersonDao::class -> personDao() as T
    ClazzDao::class -> clazzDao() as T
    CourseBlockDao::class -> courseBlockDao() as T
    CourseTerminologyDao::class -> courseTerminologyDao() as T
    CourseGroupSetDao::class -> courseGroupSetDao() as T
    CourseGroupMemberDao::class -> courseGroupMemberDao() as T
    ClazzEnrolmentDao::class -> clazzEnrolmentDao() as T
    LeavingReasonDao::class -> leavingReasonDao() as T
    ContentEntryDao::class -> contentEntryDao() as T
    ContentEntryContentCategoryJoinDao::class -> contentEntryContentCategoryJoinDao() as T
    ContentEntryParentChildJoinDao::class -> contentEntryParentChildJoinDao() as T
    ContentEntryRelatedEntryJoinDao::class -> contentEntryRelatedEntryJoinDao() as T
    ContentCategorySchemaDao::class -> contentCategorySchemaDao() as T
    ContentCategoryDao::class -> contentCategoryDao() as T
    LanguageDao::class -> languageDao() as T
    LanguageVariantDao::class -> languageVariantDao() as T
    PersonAuthDao::class -> personAuthDao() as T
    PersonGroupDao::class -> personGroupDao() as T
    PersonGroupMemberDao::class -> personGroupMemberDao() as T
    PersonPictureDao::class -> personPictureDao() as T
    VerbDao::class -> verbDao() as T
    ActivityEntityDao::class -> activityEntityDao() as T
    ReportDao::class -> reportDao() as T
    StatementDao::class -> statementDao() as T
    ActorDao::class -> actorDao() as T
    ClazzLogAttendanceRecordDao::class -> clazzLogAttendanceRecordDao() as T
    ClazzLogDao::class -> clazzLogDao() as T
    ScheduleDao::class -> scheduleDao() as T
    HolidayCalendarDao::class -> holidayCalendarDao() as T
    HolidayDao::class -> holidayDao() as T
    ClazzAssignmentDao::class -> clazzAssignmentDao() as T
    CourseAssignmentSubmissionDao::class -> courseAssignmentSubmissionDao() as T
    CourseAssignmentSubmissionFileDao::class -> courseAssignmentSubmissionFileDao() as T
    CourseAssignmentMarkDao::class -> courseAssignmentMarkDao() as T
    CommentsDao::class -> commentsDao() as T
    SyncNodeDao::class -> syncNodeDao() as T
    SiteDao::class -> siteDao() as T
    SiteTermsDao::class -> siteTermsDao() as T
    PersonParentJoinDao::class -> personParentJoinDao() as T
    ScopedGrantDao::class -> scopedGrantDao() as T
    ErrorReportDao::class -> errorReportDao() as T
    PersonAuth2Dao::class -> personAuth2Dao() as T
    UserSessionDao::class -> userSessionDao() as T
    ContentEntryImportJobDao::class -> contentEntryImportJobDao() as T
    CoursePictureDao::class -> coursePictureDao() as T
    ContentEntryPictureDao::class -> contentEntryPictureDao() as T
    MessageDao::class -> messageDao() as T
    PeerReviewerAllocationDao::class -> peerReviewerAllocationDao() as T
    DiscussionPostDao::class -> discussionPostDao() as T
    ExternalAppPermissionDao::class -> externalAppPermissionDao() as T
    ContentEntryVersionDao::class -> contentEntryVersionDao() as T
    OutgoingReplicationDao::class -> outgoingReplicationDao() as T
    TransferJobDao::class -> transferJobDao() as T
    TransferJobItemDao::class -> transferJobItemDao() as T
    CacheLockJoinDao::class -> cacheLockJoinDao() as T
    OfflineItemDao::class -> offlineItemDao() as T
    DeletedItemDao::class -> deletedItemDao() as T
    EnrolmentRequestDao::class -> enrolmentRequestDao() as T
    CoursePermissionDao::class -> coursePermissionDao() as T
    SystemPermissionDao::class -> systemPermissionDao() as T
    CourseBlockPictureDao::class -> courseBlockPictureDao() as T
    ContentEntryPicture2Dao::class -> contentEntryPicture2Dao() as T
    TransferJobErrorDao::class -> transferJobErrorDao() as T
    StudentResultDao::class -> studentResultDao() as T
    VerbLangMapEntryDao::class -> verbLangMapEntryDao() as T
    GroupMemberActorJoinDao::class -> groupMemberActorJoinDao() as T
    ActivityLangMapEntryDao::class -> activityLangMapEntryDao() as T
    ActivityInteractionDao::class -> activityInteractionDao() as T
    ActivityExtensionDao::class -> activityExtensionDao() as T
    StatementContextActivityJoinDao::class -> statementContextActivityJoinDao() as T
    XapiSessionEntityDao::class -> xapiSessionEntityDao() as T
    StatementEntityJsonDao::class -> statementEntityJsonDao() as T
    StateEntityDao::class -> stateEntityDao() as T
    StateDeleteCommandDao::class -> stateDeleteCommandDao() as T
    else -> throw IllegalArgumentException("No such DAO on this DB")
  }

  override fun close() {
    _db.close()
  }
}
