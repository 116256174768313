package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.PersonParentJoin
import kotlin.Unit

public actual fun PersonParentJoin.shallowCopy(block: PersonParentJoin.() -> Unit): PersonParentJoin
    = PersonParentJoin().also {
  it.ppjUid = this.ppjUid
  it.ppjPcsn = this.ppjPcsn
  it.ppjLcsn = this.ppjLcsn
  it.ppjLcb = this.ppjLcb
  it.ppjLct = this.ppjLct
  it.ppjParentPersonUid = this.ppjParentPersonUid
  it.ppjMinorPersonUid = this.ppjMinorPersonUid
  it.ppjRelationship = this.ppjRelationship
  it.ppjEmail = this.ppjEmail
  it.ppjPhone = this.ppjPhone
  it.ppjInactive = this.ppjInactive
  it.ppjStatus = this.ppjStatus
  it.ppjApprovalTiemstamp = this.ppjApprovalTiemstamp
  it.ppjApprovalIpAddr = this.ppjApprovalIpAddr
  block(it)
}
