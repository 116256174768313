package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.VerbEntity
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class VerbDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: VerbDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : VerbDao() {
  override suspend fun insertOrIgnoreAsync(entities: List<VerbEntity>) {
    _repo.withRepoChangeMonitorAsync("VerbEntity") {
      _dao.insertOrIgnoreAsync(entities)
    }
  }

  override suspend fun findByUid(uid: Long): VerbEntity? {
    val _result = _dao.findByUid(uid)
    return _result
  }
}
