package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.ReportFilter
import kotlin.Unit

public actual fun ReportFilter.shallowCopy(block: ReportFilter.() -> Unit): ReportFilter =
    ReportFilter().also {
  it.reportFilterUid = this.reportFilterUid
  it.reportFilterSeriesUid = this.reportFilterSeriesUid
  it.reportFilterField = this.reportFilterField
  it.reportFilterCondition = this.reportFilterCondition
  it.reportFilterValue = this.reportFilterValue
  it.reportFilterDropDownValue = this.reportFilterDropDownValue
  it.reportFilterValueBetweenX = this.reportFilterValueBetweenX
  it.reportFilterValueBetweenY = this.reportFilterValueBetweenY
  block(it)
}
