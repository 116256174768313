package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.HolidayCalendar
import kotlin.Unit

public actual fun HolidayCalendar.shallowCopy(block: HolidayCalendar.() -> Unit): HolidayCalendar =
    HolidayCalendar().also {
  it.umCalendarUid = this.umCalendarUid
  it.umCalendarName = this.umCalendarName
  it.umCalendarCategory = this.umCalendarCategory
  it.umCalendarActive = this.umCalendarActive
  it.umCalendarMasterChangeSeqNum = this.umCalendarMasterChangeSeqNum
  it.umCalendarLocalChangeSeqNum = this.umCalendarLocalChangeSeqNum
  it.umCalendarLastChangedBy = this.umCalendarLastChangedBy
  it.umCalendarLct = this.umCalendarLct
  block(it)
}
