package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentEntryPicture
import kotlin.Long
import kotlinx.coroutines.flow.Flow

public actual abstract class ContentEntryPictureDao : BaseDao<ContentEntryPicture> {
  public actual abstract suspend fun findByContentEntryUidAsync(entryUid: Long):
      ContentEntryPicture?

  public actual abstract fun findByContentEntryUidLive(entryUid: Long): Flow<ContentEntryPicture?>

  public actual abstract suspend fun updateAsync(ContentEntryPicture: ContentEntryPicture)
}
