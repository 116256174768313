package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Report
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ReportDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ReportDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ReportDao() {
  override fun getResults(query: DoorQuery): List<Report> {
    val _result = _dao.getResults(query)
    return _result
  }

  override fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): PagingSource<Int, Report> {
    val _result = _dao.findAllActiveReport(searchBit, personUid, sortOrder, isTemplate)
    return _result
  }

  override suspend fun findByUid(entityUid: Long): Report? {
    val _result = _dao.findByUid(entityUid)
    return _result
  }

  override suspend fun updateAsync(entity: Report) {
    _repo.withRepoChangeMonitorAsync("Report") {
      _dao.updateAsync(entity)
    }
  }

  override fun findByUidLive(uid: Long): Flow<Report?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  override fun findAllActiveReportLive(isTemplate: Boolean): Flow<List<Report>> {
    val _result = _dao.findAllActiveReportLive(isTemplate)
    return _result
  }

  override fun findAllActiveReportList(isTemplate: Boolean): List<Report> {
    val _result = _dao.findAllActiveReportList(isTemplate)
    return _result
  }

  override fun findByUidList(uidList: List<Long>): List<Long> {
    val _result = _dao.findByUidList(uidList)
    return _result
  }

  override suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("Report") {
      _dao.toggleVisibilityReportItems(toggleVisibility, selectedItem, updateTime)
    }
  }

  override fun replaceList(entityList: List<Report>) {
    _repo.withRepoChangeMonitor("Report") {
      _dao.replaceList(entityList)
    }
  }

  public override fun insert(entity: Report): Long {
    val _result = _repo.withRepoChangeMonitor("Report") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: Report): Long {
    val _result = _repo.withRepoChangeMonitorAsync("Report") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<Report>) {
    _repo.withRepoChangeMonitor("Report") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: Report) {
    _repo.withRepoChangeMonitor("Report") {
      _dao.update(entity)
    }
  }
}
