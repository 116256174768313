package com.ustadmobile.core.db.dao

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseBlockPicture
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class CourseBlockPictureDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseBlockPictureDao,
) : CourseBlockPictureDao() {
  override suspend fun upsertListAsync(entities: List<CourseBlockPicture>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.cbpLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entities)
  }

  override suspend fun updateUri(
    uid: Long,
    uri: String?,
    thumbnailUri: String?,
    time: Long,
  ) {
    _dao.updateUri(uid, uri, thumbnailUri, time)
  }
}
