package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.GroupMemberActorJoin
import kotlin.Long
import kotlin.collections.List

public class GroupMemberActorJoinDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: GroupMemberActorJoinDao,
) : GroupMemberActorJoinDao() {
  override suspend fun insertOrIgnoreListAsync(entities: List<GroupMemberActorJoin>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.gmajLastMod = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreListAsync(entities)
  }

  override suspend fun upsertListAsync(entities: List<GroupMemberActorJoin>) {
    val _generatedPks = mutableListOf<Long>()
    entities.iterator().forEach  {
      it.gmajLastMod = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(entities)
  }

  override suspend fun updateLastModifiedTimeIfNeededAsync(
    gmajGroupActorUid: Long,
    gmajMemberActorUid: Long,
    lastModTime: Long,
  ) {
    _dao.updateLastModifiedTimeIfNeededAsync(gmajGroupActorUid, gmajMemberActorUid, lastModTime)
  }
}
