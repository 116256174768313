package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ErrorReport
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class ErrorReportDao {
  public actual abstract suspend fun insertAsync(errorReport: ErrorReport): Long

  public actual abstract suspend fun findByUidAsync(errUid: Long): ErrorReport?

  public actual abstract suspend fun findByErrorCode(errCode: Int): List<ErrorReport>
}
