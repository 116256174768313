package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.composites.CommentsAndName
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class CommentsDao {
  public actual abstract suspend fun insertAsync(comments: Comments): Long

  public actual abstract suspend fun updateDeletedByCommentUid(
    uid: Long,
    deleted: Boolean,
    changeTime: Long,
  )

  public actual abstract fun findPrivateCommentsForUserByAssignmentUid(
    accountPersonUid: Long,
    assignmentUid: Long,
    includeDeleted: Boolean,
  ): PagingSource<Int, CommentsAndName>

  public actual abstract suspend
      fun findPrivateCommentsForUserByAssignmentUidPersons(accountPersonUid: Long,
      assignmentUid: Long): List<PersonAndPicture>

  public actual abstract fun findPrivateCommentsForSubmitterByAssignmentUid(
    submitterUid: Long,
    assignmentUid: Long,
    includeDeleted: Boolean,
  ): PagingSource<Int, CommentsAndName>

  public actual abstract
      fun findPrivateCommentsForSubmitterByAssignmentUidPersons(submitterUid: Long,
      assignmentUid: Long): List<PersonAndPicture>

  public actual abstract fun findCourseCommentsByAssignmentUid(assignmentUid: Long,
      includeDeleted: Boolean): PagingSource<Int, CommentsAndName>

  public actual abstract suspend fun findCourseCommentsByAssignmentUidPersons(assignmentUid: Long):
      List<Person>
}
