package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.VerbEntity
import kotlin.Long
import kotlin.collections.List

public actual abstract class VerbDao {
  public actual abstract suspend fun insertOrIgnoreAsync(entities: List<VerbEntity>)

  public actual abstract suspend fun findByUid(uid: Long): VerbEntity?
}
