package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class CourseAssignmentSubmissionDao : BaseDao<CourseAssignmentSubmission> {
  public actual abstract suspend fun insertListAsync(entityList: List<CourseAssignmentSubmission>)

  public actual abstract fun getAllSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): PagingSource<Int, CourseAssignmentSubmission>

  public actual abstract fun getAllSubmissionsForUser(accountPersonUid: Long, assignmentUid: Long):
      Flow<List<CourseAssignmentSubmission>>

  public actual abstract fun getAllSubmissionsFromSubmitterAsFlow(submitterUid: Long,
      assignmentUid: Long): Flow<List<CourseAssignmentSubmission>>

  public actual abstract suspend fun getLatestSubmissionForUserAsync(accountPersonUid: Long,
      assignmentUid: Long): CourseAssignmentSubmission?

  public actual abstract suspend fun doesUserHaveSubmissions(accountPersonUid: Long,
      assignmentUid: Long): Boolean

  public actual abstract suspend fun countFileSubmissionFromStudent(assignmentUid: Long,
      submitterUid: Long): Int

  public actual abstract suspend fun countSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): Int

  public actual abstract fun getStatusOfAssignmentForSubmitter(assignmentUid: Long,
      submitterUid: Long): Flow<Int>

  public actual abstract suspend fun findLastSubmissionFromStudent(submitterUid: Long,
      assignmentUid: Long): CourseAssignmentSubmission?

  public actual abstract fun checkNoSubmissionsMade(assignmentUid: Long): Flow<Boolean>

  public actual abstract suspend fun checkNoSubmissionsMadeAsync(assignmentUid: Long): Boolean

  public actual abstract fun checkNoSubmissionsMadeFlow(assignmentUid: Long): Flow<Boolean>

  public actual abstract fun findByUidAsFlow(submissionUid: Long): Flow<CourseAssignmentSubmission?>

  public actual abstract fun findByAssignmentUidAndAccountPersonUid(accountPersonUid: Long,
      assignmentUid: Long): Flow<List<CourseAssignmentSubmission>>
}
