package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.ActivityEntity
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ActivityEntityDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ActivityEntityDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ActivityEntityDao() {
  override suspend fun insertOrIgnoreAsync(entities: List<ActivityEntity>) {
    _repo.withRepoChangeMonitorAsync("ActivityEntity") {
      _dao.insertOrIgnoreAsync(entities)
    }
  }

  override suspend fun updateIfMoreInfoChanged(
    activityUid: Long,
    actMoreInfo: String?,
    actLct: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ActivityEntity") {
      _dao.updateIfMoreInfoChanged(activityUid, actMoreInfo, actLct)
    }
  }

  override suspend fun updateIfNotYetDefined(
    actUid: Long,
    actType: String?,
    actMoreInfo: String?,
    actInteractionType: Int,
    actCorrectResponsePatterns: String?,
  ) {
    _repo.withRepoChangeMonitorAsync("ActivityEntity") {
      _dao.updateIfNotYetDefined(actUid, actType, actMoreInfo, actInteractionType,
          actCorrectResponsePatterns)
    }
  }

  override suspend fun findByUidAsync(activityUid: Long): ActivityEntity? {
    val _result = _dao.findByUidAsync(activityUid)
    return _result
  }
}
