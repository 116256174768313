package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.StateDeleteCommand

public class StateDeleteCommandDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StateDeleteCommandDao,
) : StateDeleteCommandDao() {
  override suspend fun insertAsync(deleteCommand: StateDeleteCommand) {
    deleteCommand.sdcLastMod = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(deleteCommand)
  }
}
