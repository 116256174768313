package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.PersonAndClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzLogAttendanceRecordDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzLogAttendanceRecordDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzLogAttendanceRecordDao() {
  override suspend fun insertListAsync(entities: List<ClazzLogAttendanceRecord>) {
    _repo.withRepoChangeMonitorAsync("ClazzLogAttendanceRecord") {
      _dao.insertListAsync(entities)
    }
  }

  override fun findByUid(uid: Long): ClazzLogAttendanceRecord? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  override suspend fun updateListAsync(entities: List<ClazzLogAttendanceRecord>) {
    _repo.withRepoChangeMonitorAsync("ClazzLogAttendanceRecord") {
      _dao.updateListAsync(entities)
    }
  }

  override suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson> {
    val _result = _dao.findByClazzLogUid(clazzLogUid)
    return _result
  }

  override fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long,
  ) {
    _repo.withRepoChangeMonitor("ClazzLogAttendanceRecord") {
      _dao.updateRescheduledClazzLogUids(oldClazzLogUid, newClazzLogUid, changedTime)
    }
  }

  override suspend fun findByClazzAndTime(
    clazzUid: Long,
    clazzLogUid: Long,
    time: Long,
  ): List<PersonAndClazzLogAttendanceRecord> {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "ClazzLogAttendanceRecordDao/findByClazzAndTime") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzLogAttendanceRecordDao/findByClazzAndTime")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("clazzLogUid", _repo.config.json.encodeToString(Long.serializer(), clazzLogUid))
        parameter("time", _repo.config.json.encodeToString(Long.serializer(), time))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByClazzAndTime(clazzUid, clazzLogUid, time)
    return _result
  }

  override suspend fun upsertListAsync(entityList: List<ClazzLogAttendanceRecord>) {
    _repo.withRepoChangeMonitorAsync("ClazzLogAttendanceRecord") {
      _dao.upsertListAsync(entityList)
    }
  }

  public override fun insert(entity: ClazzLogAttendanceRecord): Long {
    val _result = _repo.withRepoChangeMonitor("ClazzLogAttendanceRecord") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzLogAttendanceRecord): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ClazzLogAttendanceRecord") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ClazzLogAttendanceRecord>) {
    _repo.withRepoChangeMonitor("ClazzLogAttendanceRecord") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: ClazzLogAttendanceRecord) {
    _repo.withRepoChangeMonitor("ClazzLogAttendanceRecord") {
      _dao.update(entity)
    }
  }
}
