package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentCategorySchema
import kotlin.String
import kotlin.collections.List

public actual abstract class ContentCategorySchemaDao : BaseDao<ContentCategorySchema> {
  public actual abstract fun publicContentCategorySchemas(): List<ContentCategorySchema>

  public actual abstract fun findBySchemaUrl(schemaUrl: String): ContentCategorySchema?
}
