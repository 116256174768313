package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.StatementEntityJson
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StatementEntityJsonDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StatementEntityJsonDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StatementEntityJsonDao() {
  override suspend fun insertOrIgnoreListAsync(entityList: List<StatementEntityJson>) {
    _repo.withRepoChangeMonitorAsync("StatementEntityJson") {
      _dao.insertOrIgnoreListAsync(entityList)
    }
  }

  override suspend fun getStatements(stmtJsonIdHi: Long, stmtJsonIdLo: Long):
      List<StatementEntityJson> {
    val _result = _dao.getStatements(stmtJsonIdHi, stmtJsonIdLo)
    return _result
  }
}
