package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.xapi.XapiSessionEntity
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class XapiSessionEntityDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: XapiSessionEntityDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : XapiSessionEntityDao() {
  override suspend fun insertAsync(xapiSessionEntity: XapiSessionEntity) {
    _repo.withRepoChangeMonitorAsync("XapiSessionEntity") {
      _dao.insertAsync(xapiSessionEntity)
    }
  }

  override suspend fun findByUidAsync(uid: Long): XapiSessionEntity? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override suspend fun updateLatestAsComplete(
    completed: Boolean,
    time: Long,
    xseUid: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("XapiSessionEntity") {
      _dao.updateLatestAsComplete(completed, time, xseUid)
    }
  }

  override suspend fun findMostRecentSessionByActorAndActivity(
    accountPersonUid: Long,
    actorUid: Long,
    xseRootActivityUid: Long,
    contentEntryVersionUid: Long,
    clazzUid: Long,
  ): XapiSessionEntity? {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "XapiSessionEntityDao/findMostRecentSessionByActorAndActivity") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "XapiSessionEntityDao/findMostRecentSessionByActorAndActivity")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("actorUid", _repo.config.json.encodeToString(Long.serializer(), actorUid))
        parameter("xseRootActivityUid", _repo.config.json.encodeToString(Long.serializer(),
            xseRootActivityUid))
        parameter("contentEntryVersionUid", _repo.config.json.encodeToString(Long.serializer(),
            contentEntryVersionUid))
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findMostRecentSessionByActorAndActivity(accountPersonUid, actorUid,
        xseRootActivityUid, contentEntryVersionUid, clazzUid)
    return _result
  }
}
