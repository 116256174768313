package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.ClazzLog
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ClazzLogDao : BaseDao<ClazzLog> {
  public actual abstract fun replace(entity: ClazzLog): Long

  public actual abstract fun findByUid(uid: Long): ClazzLog?

  public actual abstract suspend fun findByUidAsync(uid: Long): ClazzLog?

  public actual abstract fun findByUidLive(uid: Long): Flow<ClazzLog?>

  public actual abstract fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      PagingSource<Int, ClazzLog>

  public actual abstract suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int):
      List<ClazzLog>

  public actual abstract suspend fun findAllForClazzByClazzLogUid(clazzLogUid: Long,
      excludeStatus: Int): List<ClazzLog>

  public actual abstract suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog>

  public actual abstract fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int,
  ): List<ClazzLog>

  public actual abstract fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int,
  ): Flow<List<ClazzLog>>

  public actual abstract fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int):
      Flow<Boolean>

  public actual abstract fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long,
  )

  public actual abstract suspend fun updateAsync(clazzLog: ClazzLog)

  public actual abstract suspend fun findMostRecentClazzLogToEditUid(clazzUid: Long): Long

  public actual abstract suspend fun upsertListAsync(entityList: List<ClazzLog>)
}
