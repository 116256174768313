package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.CourseGroupSet
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class CourseGroupSetDao : BaseDao<CourseGroupSet> {
  public actual abstract suspend fun updateAsync(entity: CourseGroupSet): Int

  public actual abstract fun findAllCourseGroupSetForClazz(
    clazzUid: Long,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, CourseGroupSet>

  public actual abstract fun findAllCourseGroupSetForClazzList(clazzUid: Long): List<CourseGroupSet>

  public actual abstract suspend fun findAllCourseGroupSetForClazzListAsync(clazzUid: Long):
      List<CourseGroupSet>

  public actual abstract suspend fun findByUidAsync(uid: Long): CourseGroupSet?

  public actual abstract fun findByUidAsFlow(uid: Long): Flow<CourseGroupSet?>

  public actual abstract suspend fun upsertAsync(entity: CourseGroupSet)
}
