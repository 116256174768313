package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ScheduleDao : BaseDao<Schedule> {
  actual abstract override fun insert(entity: Schedule): Long

  public actual abstract suspend fun updateAsync(entity: Schedule): Int

  public actual abstract suspend fun upsertListAsync(entities: List<Schedule>)

  public actual abstract suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long,
  )

  public actual abstract fun findByUid(uid: Long): Schedule?

  public actual abstract suspend fun findByUidAsync(uid: Long): Schedule?

  public actual abstract fun findAllSchedulesByClazzUid(clazzUid: Long): PagingSource<Int, Schedule>

  public actual abstract fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule>

  public actual abstract fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long):
      Flow<List<Schedule>>

  public actual abstract suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule>
}
