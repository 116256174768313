package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.ActorUidEtagAndLastMod
import com.ustadmobile.lib.db.entities.xapi.ActorEntity
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ActorDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ActorDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ActorDao() {
  override suspend fun insertOrIgnoreListAsync(entities: List<ActorEntity>) {
    _repo.withRepoChangeMonitorAsync("ActorEntity") {
      _dao.insertOrIgnoreListAsync(entities)
    }
  }

  override suspend fun upsertListAsync(entities: List<ActorEntity>) {
    _repo.withRepoChangeMonitorAsync("ActorEntity") {
      _dao.upsertListAsync(entities)
    }
  }

  override suspend fun updateIfNameChanged(
    uid: Long,
    name: String?,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ActorEntity") {
      _dao.updateIfNameChanged(uid, name, updateTime)
    }
  }

  override suspend fun findByUidAsync(uid: Long): ActorEntity? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override suspend fun findByUidAndPersonUidAsync(actorUid: Long, accountPersonUid: Long):
      ActorEntity? {
    val _result = _dao.findByUidAndPersonUidAsync(actorUid, accountPersonUid)
    return _result
  }

  override suspend fun findUidAndEtagByListAsync(uidList: List<Long>):
      List<ActorUidEtagAndLastMod> {
    val _result = _dao.findUidAndEtagByListAsync(uidList)
    return _result
  }

  override suspend fun findGroupMembers(groupActorUid: Long): List<ActorEntity> {
    val _result = _dao.findGroupMembers(groupActorUid)
    return _result
  }
}
